import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import ReportsSkeleton from '@/components/SkeletonLoading/ReportsSkeleton.tsx'

export default function AdminLogsPage() {
  return (
    <Suspense fallback={<ReportsSkeleton />}>
      <Outlet />
    </Suspense>
  )
}
