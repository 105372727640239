import type { ContextData } from '@floating-ui/react'
import { createContext, HTMLProps, MutableRefObject, ReactNode } from 'react'

export type DropdownMdContextValue = {
  value: string[]
  listRef: MutableRefObject<Array<HTMLLIElement | null>>
  setOpen: (open: boolean) => void
  onChange: (value: string[]) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItemProps: (userProps?: HTMLProps<HTMLElement>) => any
  dataRef: ContextData
}

export interface DropdownMdContextProviderProps {
  value: DropdownMdContextValue
  children: ReactNode
}

export const DropdownMdContext = createContext<DropdownMdContextValue | null>(
  null,
)

export const DropdownMdProvider = ({
  value,
  children,
}: DropdownMdContextProviderProps) => {
  return (
    <DropdownMdContext.Provider value={value}>
      {children}
    </DropdownMdContext.Provider>
  )
}
