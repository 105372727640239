import { useEffect, useMemo } from 'react'

import { BASE_PATH, ERROR_NOT_FOUND_PATH } from '@/constants/constants.ts'
import { hasRoles } from '@/helpers/user.ts'
import useAuthContext from '@/hooks/useAuthProvider.ts'
import useLoggedWsNavigate from '@/hooks/useLoggedWsNavigate.ts'

export default function useCheckRole(roles: UserRole[]): [boolean, User] {
  const { user } = useAuthContext()
  const navigate = useLoggedWsNavigate()
  const canView = useMemo(() => {
    return (user && hasRoles(user, roles)) || false
  }, [user, roles])

  useEffect(() => {
    if (!canView) {
      navigate(`${BASE_PATH}/${ERROR_NOT_FOUND_PATH}`, {
        replace: true,
      })
    }
  }, [canView, navigate])

  return [canView, user!]
}
