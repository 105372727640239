import { KEY_STAGE_OPTIONS, Timeline } from '@edclass/fe-common'
import { getRandomInt } from '@edclass/fe-common'
import { ulid } from 'ulid'

import { TimetableSheetValue } from '@/components/TimetableSheet/TimetableSheetContext.ts'
import {
  DUMMY_AVATAR,
  LOCALE,
  SUBJECT_TAILWIND_COLOR_CLASSNAME,
} from '@/constants/constants.ts'
import { TimetableSubject } from '@/helpers/types.ts'

export enum ALERT_COLUMN_TYPE {
  OPEN = 'open',
  CLOSE = 'close',
  CURRENT = 'current',
  NEW_NO_CAMERA = 'new_no_camera',
  CAMERA_OFF_SF = 'camera_off_sf',
  CLOSED_CAMERA = 'closed_camera',
}

export enum ALERT_CARD_TYPE {
  ALERT = 'Alert',
  QUESTIONNAIRE = 'Questionnaire',
  EDSUPPORT_LEARNING_ALERT = 'EDSupport Learning Alert',
  MARKING_ALERT = 'Marking Alert',
  EDSUPPORT_ALERT = 'EDSupport Alert',
}

export const columnTypeColor = {
  [ALERT_COLUMN_TYPE.OPEN]: 'ed-blue',
  [ALERT_COLUMN_TYPE.CLOSE]: 'ed-lime',
  [ALERT_COLUMN_TYPE.CURRENT]: 'ed-blue',
  [ALERT_COLUMN_TYPE.NEW_NO_CAMERA]: 'ed-brown',
  [ALERT_COLUMN_TYPE.CAMERA_OFF_SF]: 'ed-orange',
  [ALERT_COLUMN_TYPE.CLOSED_CAMERA]: 'ed-lime',
}

export const alertCardTypeColor = {
  [ALERT_CARD_TYPE.ALERT]: 'ed-red',
  [ALERT_CARD_TYPE.EDSUPPORT_LEARNING_ALERT]: 'ed-pink',
  [ALERT_CARD_TYPE.QUESTIONNAIRE]: 'ed-purple',
  [ALERT_CARD_TYPE.MARKING_ALERT]: 'ed-red2',
  [ALERT_CARD_TYPE.EDSUPPORT_ALERT]: 'ed-orange',
}

export interface DummyAlertItem {
  id: number
  src: string
  srcs: string[]
  columnType: ALERT_COLUMN_TYPE
  alertType: ALERT_CARD_TYPE
  alts: string[]
  info: {
    name: string
    school: string
    status: string
    what3Words: string[]
    address: string
    schoolMPOC: string
  }
  date: Date
}

export const DUMMY_ALERT_SHORT_ITEMS: DummyAlertItem[] = Array.from(
  Array(20).keys(),
).map((i) => {
  const now = new Date()
  const randomTypeId = (max: number) => Math.floor(Math.random() * max) + 1
  const colRandomId = randomTypeId(Object.keys(columnTypeColor).length)
  const cardRandomId = randomTypeId(Object.keys(alertCardTypeColor).length)
  return {
    id: i + 1,
    src: DUMMY_AVATAR,
    srcs: [DUMMY_AVATAR, DUMMY_AVATAR],
    columnType: Object.keys(columnTypeColor)[
      colRandomId - 1
    ] as ALERT_COLUMN_TYPE,
    alertType: Object.keys(alertCardTypeColor)[
      cardRandomId - 1
    ] as ALERT_CARD_TYPE,
    alts: ['Alt1', 'Alt2'],
    info: {
      name: `First Last Name ${i}`,
      school: 'Note',
      schoolMPOC: 'Geoff Johnson',
      status: 'Awaiting',
      what3Words: ['Pretty', 'Needed', 'Chill'],
      address: 'Aston House, Campbell Way, Dinnington, Sheffield S25 3QD',
    },
    date: new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      getRandomInt(0, 23),
      getRandomInt(0, 59),
      getRandomInt(0, 59),
    ),
  }
})

export const currentAlertInfo = {
  'Name Surname': `Time: ${new Date().toLocaleTimeString(LOCALE)}`,
  School: 'Note',
  Status: 'Awaiting',
  'What 3 Words': ['Pretty', 'Needed', 'Chill'],
  Address: `Aston House, Campbell Way, Dinnington, Sheffield S25 3QD`,
}

const SUBJECTS = Object.keys(SUBJECT_TAILWIND_COLOR_CLASSNAME) as Array<
  keyof typeof SUBJECT_TAILWIND_COLOR_CLASSNAME
>

export const genDummyTimetableItems = (key: string) => {
  return Object.entries(Timeline).map((_, index) => {
    return {
      id: ulid(),
      title: `Item ${index + 1} Title ${key}`,
      subject: SUBJECTS[Math.abs(SUBJECTS.length - index - 1)] ?? '',
      kidStage: KEY_STAGE_OPTIONS[getRandomInt(0, KEY_STAGE_OPTIONS.length)],
      /*isLeave:
        key === 'Wednesday' &&
        ((index > 3 && index < 8) || (index >= 12 && index < 14)),*/
    }
  })
}

export const genDummyTimetableOverviewItems = (key: string) => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
  return Object.entries(Timeline).map((_, index) => {
    const randDay = days[getRandomInt(0, days.length - 1)]
    return {
      id: ulid(),
      title: `Item ${index + 1} Title ${key}`,
      subject: SUBJECTS[getRandomInt(-4, SUBJECTS.length)] ?? '',
      kidStage: KEY_STAGE_OPTIONS[getRandomInt(0, KEY_STAGE_OPTIONS.length)],
      isLeave:
        randDay === key &&
        ((index > 3 && index < 8) || (index >= 12 && index < 14)),
    }
  })
}

export const DUMMY_TIMETABLE_CATEGORIES_OVERVIEW: TimetableSheetValue<TimetableSubject> =
  {
    Monday: genDummyTimetableOverviewItems('Monday'),
    Tuesday: genDummyTimetableOverviewItems('Tuesday'),
    Wednesday: genDummyTimetableOverviewItems('Wednesday'),
    Thursday: genDummyTimetableOverviewItems('Thursday'),
    Friday: genDummyTimetableOverviewItems('Friday'),
  }

export const openCardModalButtons = [
  'Student Profile',
  'Student Alert History',
  'Student Chat History',
]

export const openCardModalIcons = ['chat_bubble', 'duo', 'call']

export interface VideoCam {
  src: string
  fullName: string
}

export const teacherVideoCam: VideoCam = {
  src: DUMMY_AVATAR,
  fullName: 'Paige Bell',
}

export const studentVideoCam: VideoCam = {
  src: DUMMY_AVATAR,
  fullName: 'Lara Croft',
}

export const alertTitles = ['New Alerts', 'Current Alerts', 'Closed Alerts']
export const alertTitlesNoCamera = [
  'New no Camera Alerts',
  'Current Alerts',
  'Camera of SF alert',
  'Closed Camera issues',
]
