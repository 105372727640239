import { useCallback } from 'react'
import {
  NavigateFunction,
  NavigateOptions,
  To,
  useNavigate,
} from 'react-router-dom'

import useAppContext from '@/hooks/useAppProvider.ts'

export default function useLoggedWsNavigate(): NavigateFunction {
  const navigate = useNavigate()
  const { sendEventMessage } = useAppContext()
  return useCallback(
    (to: To | number, options: NavigateOptions = {}) => {
      if (typeof to === 'number') {
        sendEventMessage({
          kind: 'navigation',
          uri:
            history.state.usr?.from ||
            document?.referrer ||
            to.toString() ||
            'back',
        })
        navigate(to)
      } else {
        sendEventMessage({
          kind: 'navigation',
          uri: to.toString(),
        })
        navigate(to, options)
      }
    },
    [navigate, sendEventMessage],
  )
}
