import { useEffect, useState } from 'react'

export default function useMountStatus(ms = 500) {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), ms)
    return () => clearTimeout(timeout)
  }, [ms])

  return isMounted
}
