import {
  UploadAction,
  UploadActionType,
  UploadState,
  UploadStatus,
} from '@/providers/UploadProvider/types.ts'

export default function uploadReducer(
  state: UploadState,
  action: UploadAction,
) {
  switch (action.type) {
    case UploadActionType.Add: {
      const files = { ...state.files }
      if (action.item) {
        files[action.item.id] = action.item
        if (action.controller) {
          files[action.item.id].controller = action.controller
        }
      }
      return {
        files,
      }
    }
    case UploadActionType.Uploading: {
      const files = { ...state.files }
      files[action.itemId!] = {
        ...files[action.itemId!],
        status: UploadStatus.Uploading,
      }
      return {
        files,
      }
    }
    case UploadActionType.Uploaded: {
      const files = { ...state.files }
      files[action.itemId!] = {
        ...files[action.itemId!],
        status: UploadStatus.Uploaded,
      }
      return {
        files,
      }
    }
    case UploadActionType.Abort: {
      const files = { ...state.files }
      files[action.itemId!] = {
        ...files[action.itemId!],
        status: UploadStatus.Aborted,
      }
      delete files[action.itemId!].controller
      return {
        files,
      }
    }
    case UploadActionType.Error: {
      const files = { ...state.files }
      files[action.itemId!] = {
        ...files[action.itemId!],
        error: action.error || '',
        status: UploadStatus.Error,
      }
      return {
        files,
      }
    }
    case UploadActionType.Reset: {
      return {
        files: {},
      }
    }
    default:
      return state
  }
}
