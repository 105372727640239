import { lazy } from 'react'

import { StudentChildrenPath } from '@/constants/constants.ts'
import { PathKind } from '@/helpers/path.ts'
import { roomLoader } from '@/loader/roomLoader.ts'
import { sidebarHiddenPaths } from '@/services/app.ts'

// eslint-disable-next-line react-refresh/only-export-components
const Page = lazy(() => import('@/pages/StudentPage/index.tsx'))
// eslint-disable-next-line react-refresh/only-export-components
const Dashboard = lazy(
  () => import('@/pages/StudentPage/StudentDashboardPage/index.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Independent = lazy(
  () => import('@/pages/StudentPage/StudentIndependentPage/index.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Pathway = lazy(
  () => import('@/pages/StudentPage/StudentPathwayPage/index.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Detail = lazy(
  () => import('@/pages/StudentPage/StudentPathwayDetailPage/index.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Game = lazy(() => import('@/pages/StudentPage/StudentGamePage/index.tsx'))
// eslint-disable-next-line react-refresh/only-export-components
const Timetable = lazy(
  () => import('@/pages/StudentPage/StudentTimetablePage/index.tsx'),
)

export default {
  path: PathKind.Student,
  element: <Page />,
  children: [
    {
      index: true,
      element: <Dashboard />,
    },
    {
      path: StudentChildrenPath.Independent,
      element: <Independent />,
    },
    {
      path: StudentChildrenPath.Pathway,
      element: <Pathway />,
    },
    {
      path: `${StudentChildrenPath.Pathway}/:id`,
      element: <Detail />,
    },
    {
      path: StudentChildrenPath.Game,
      element: <Game />,
    },
    {
      path: StudentChildrenPath.Timetable,
      element: <Timetable />,
    },
    {
      path: StudentChildrenPath.TimetabledLesson,
      loader: roomLoader,
      lazy: async () => {
        const { default: Component } = await import(
          '@/pages/StudentPage/StudentTimetabledLessonPage/index.tsx'
        )
        return { Component }
      },
    },
  ],
}

sidebarHiddenPaths.add(
  `/${PathKind.Student}/${StudentChildrenPath.TimetabledLesson}`,
)
