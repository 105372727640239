import './TimetableSheetItem.css'

import { DraggableSyntheticListeners } from '@dnd-kit/core'
import { Transform } from '@dnd-kit/utilities'
import clsx from 'clsx'
import { CSSProperties, useEffect } from 'react'

import {
  useTimetableSheetContext,
  WithUniqueId,
} from '@/components/TimetableSheet/TimetableSheetContext.ts'

export interface TimetableSheetItemProps<T extends WithUniqueId> {
  setNodeRef?: (node: HTMLLIElement) => void
  className?: string
  item: T
  containerId: string
  dragOverlay?: boolean
  color?: string
  disabled?: boolean
  dragging?: boolean
  height?: number
  index: number
  fadeIn?: boolean
  transform?: Transform | null
  listeners?: DraggableSyntheticListeners
  sorting?: boolean
  style?: CSSProperties
  transition?: string | null
  wrapperStyle?: CSSProperties
}

export default function TimetableSheetItem<T extends WithUniqueId>({
  setNodeRef,
  transform,
  dragging,
  disabled,
  dragOverlay,
  wrapperStyle,
  style,
  transition,
  color,
  containerId,
  className,
  listeners,
  item,
  fadeIn,
  index,
  ...props
}: TimetableSheetItemProps<T>) {
  const { itemClassName, renderItem, renderActions } =
    useTimetableSheetContext<T>()
  const child = renderItem(item, index)

  useEffect(() => {
    if (!dragOverlay) {
      return
    }
    document.body.style.cursor = 'grabbing'
    return () => {
      document.body.style.cursor = ''
    }
  }, [dragOverlay])

  return (
    <li
      ref={setNodeRef}
      className={clsx(
        'tts-item-wrap',
        className,
        typeof itemClassName === 'function'
          ? itemClassName(item)
          : itemClassName,
        dragOverlay && 'drag-overlay',
        fadeIn && 'fade-in',
      )}
      data-container-id={containerId}
      style={
        {
          ...wrapperStyle,
          transition: [transition, wrapperStyle?.transition]
            .filter(Boolean)
            .join(', '),
          '--translate-x': transform
            ? `${Math.round(transform.x)}px`
            : undefined,
          '--translate-y': transform
            ? `${Math.round(transform.y)}px`
            : undefined,
          '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
          '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
          //'--index': index,
          '--color': color,
        } as CSSProperties
      }
    >
      <div
        className={clsx(
          'tts-item',
          dragging && 'dragging',
          dragOverlay && 'drag-overlay',
          disabled && 'disabled',
          color && color,
        )}
        style={style}
        data-testid="draggable-item"
        {...props}
        tabIndex={0}
      >
        <div className="tts-item-content" {...listeners}>
          {child}
        </div>
        <div className="tts-item-actions">
          {renderActions?.(item, index)}
          {/*<ReorderHandle {...listeners} className="fill-current" />*/}
        </div>
      </div>
    </li>
  )
}
