import { EventEndpointRs } from '@edclass/fe-common'
import PQueue from 'p-queue'

import { Http } from '@/helpers/http.ts'
import { BaseService } from '@/services/base.ts'
import { Params } from '@/services/params.ts'

const BASE_URL: string = import.meta.env.VITE_EVENT_API_URL
export const API_VERSION = 'v1'

type Sort = 'asc' | 'desc'
type EventPagePayload = {
  page?: number
  limit?: number
}

type EventSortPayload<T extends EventAuditObject | EventActivityObject> =
  EventPagePayload & {
    sort?: Sort
    sortBy?: DeepKeys<T> | '_id' | 'event.logTime' | 'event.kind'
  }

export type EventPayload<T extends EventAuditObject | EventActivityObject> =
  T extends EventAuditObject
    ? {
        filters?: MongoRootFilterOperators<EventAuditItem<T>> | null
      } & EventSortPayload<T>
    : {
        filters?: MongoFilterOperators<EventActivityItem<T>>
      } & EventSortPayload<T>

/*
export type EventPayload<T extends Record<string, unknown>> =
  EventSortPayload<T> & {
    filters?: LogicalExpressionNoId<T> | null
  }*/

export const DEFAULT_EVENT_PAGE_PAYLOAD: EventPagePayload = {
  page: 1,
  limit: 20,
}

/*
interface CommonData {
  userId: string
  schoolId?: string
}*/

export class EventService extends BaseService {
  private static instance: EventService
  private readonly _queue?: PQueue
  protected _client: Http

  constructor(queue?: PQueue) {
    super()
    if (queue) {
      this._queue = queue
    }
    this._client = new Http({
      baseUrl: `${BASE_URL}/api`,
    })
  }

  public static getInstance(queue?: PQueue) {
    if (!EventService.instance) {
      EventService.instance = new EventService(queue)
    }
    return EventService.instance
  }

  protected parseEndpoint<S extends EventAuditObject>(
    endpoint: EventEndpointRs,
    { sort, sortBy, page, limit }: EventSortPayload<S>,
  ) {
    const params = new URLSearchParams()
    if (sort) {
      params.set('sort', `${sort}`)
    }
    if (sortBy) {
      params.set('sortBy', `${String(sortBy)}`)
    }
    if (page) {
      params.set('page', page.toString())
    }
    if (limit) {
      params.set('limit', limit.toString())
    }

    const pStr = params.toString()

    return `${endpoint}${pStr ? `?${pStr}` : ''}`
  }

  protected listFilter<
    Response,
    T extends EventAuditObject | EventActivityObject,
  >(ep: EventEndpointRs, payload?: EventPayload<T>, log?: boolean) {
    const { filters, ...commonPayload } = payload || {}
    if (log) {
      console.log('PARAMS', filters)
      return this.send<Response>(
        this._client.put(
          this.parseEndpoint(ep, commonPayload),
          filters ? { filters } : undefined,
        ),
      )
    }

    return this.send<Response>(
      this._client.get(this.parseEndpoint(ep, commonPayload)),
    )
  }

  async log<T>(p: Promise<T>) {
    return this._queue ? this._queue.add(() => p) : p
  }

  async listSubjectEvent(payload?: EventPayload<EventAuditSubject>) {
    return this.listFilter<
      PaginatedEventAuditItem<EventAuditSubject>,
      EventAuditSubject
    >(EventEndpointRs.SubjectAudit, payload, true)
  }

  async listTopicEvent(payload?: EventPayload<EventAuditTopic>) {
    return this.listFilter<
      PaginatedEventAuditItem<EventAuditTopic>,
      EventAuditTopic
    >(EventEndpointRs.TopicAudit, payload, true)
  }

  async listUserEvent(payload?: EventPayload<EventAuditUser>) {
    return this.listFilter<
      PaginatedEventAuditItem<EventAuditUser>,
      EventAuditUser
    >(EventEndpointRs.UserAudit, payload, true)
  }

  async listLessonEvent(payload?: EventPayload<EventAuditLesson>) {
    return this.listFilter<
      PaginatedEventAuditItem<EventAuditLesson>,
      EventAuditLesson
    >(EventEndpointRs.LessonAudit, payload, true)
  }

  async listLessonRevisionEvent(
    payload?: EventPayload<EventAuditLessonRevision>,
  ) {
    return this.listFilter<
      PaginatedEventAuditItem<EventAuditLessonRevision>,
      EventAuditLessonRevision
    >(EventEndpointRs.LessonRevisionAudit, payload, true)
  }

  async listLessonRevisionLog(
    payload?: EventPayload<EventAuditLessonRevision>,
  ) {
    return this.listFilter<
      PaginatedEventAuditItem<EventAuditLessonRevision>,
      EventAuditLessonRevision
    >(EventEndpointRs.LessonRevisionLog, payload, true)
  }

  async listLessonSlideEvent(payload?: EventPayload<EventAuditLessonSlide>) {
    return this.listFilter<
      PaginatedEventAuditItem<EventAuditLessonSlide>,
      EventAuditLessonSlide
    >(EventEndpointRs.LessonSlideAudit, payload, true)
  }

  async listLessonSlideLog(payload?: EventPayload<EventAuditLessonSlide>) {
    return this.listFilter<
      PaginatedEventAuditItem<EventAuditLessonSlide>,
      EventAuditLessonSlide
    >(EventEndpointRs.LessonSlideLog, payload, true)
  }

  async listPathwayEvent(payload?: EventPayload<EventAuditPathway>) {
    return this.listFilter<
      PaginatedEventAuditItem<EventAuditPathway>,
      EventAuditPathway
    >(EventEndpointRs.PathwayAudit, payload, true)
  }

  async listPathwayRevisionEvent(
    payload?: EventPayload<EventAuditPathwayRevision>,
  ) {
    return this.listFilter<
      PaginatedEventAuditItem<EventAuditPathwayRevision>,
      EventAuditPathwayRevision
    >(EventEndpointRs.PathwayRevisionAudit, payload, true)
  }

  async listPathwayRevisionLog(
    payload?: EventPayload<EventAuditPathwayRevision>,
  ) {
    return this.listFilter<
      PaginatedEventAuditItem<EventAuditPathwayRevision>,
      EventAuditPathwayRevision
    >(EventEndpointRs.PathwayRevisionLog, payload, true)
  }

  async listNavLog(payload?: EventPayload<EventActivityNavigation>) {
    return this.listFilter<
      PaginatedEventActivityItem<EventActivityNavigation>,
      EventActivityNavigation
    >(EventEndpointRs.NavigationAudit, payload, true)
  }

  /*
  // ===================================================================
  // ALERT
  // ===================================================================
  async logAlert(body: EventAlertReqBody) {
    return this.log(
      this.send<EventKindAlert>(this._client.post(EventEndpoint.Alerts, body)),
    )
  }
  async listAlertLog(payload?: EventPayload<EventKindAlert>) {
    return this.listFilter<ListEventKindAlert, EventKindAlert>(
      EventEndpoint.Alerts,
      payload,
    )
  }
  async listAlertStats(payload?: EventPayload<EventWithCountAndDateStat>) {
    return this.listFilter<EventKindAlertStats, EventWithCountAndDateStat>(
      EventEndpoint.AlertsStats,
      payload,
    )
  }

  // ===================================================================
  // ALERT AUDIT
  // ===================================================================
  async logAlertAudit(body: EventAlertAuditReqBody) {
    return this.log(
      this.send<EventKindAlertAudit>(
        this._client.post(EventEndpoint.AlertAudits, body),
      ),
    )
  }
  async listAlertAuditLog(payload?: EventPayload<EventKindAlertAudit>) {
    return this.listFilter<ListEventKindAlertAudit, EventKindAlertAudit>(
      EventEndpoint.AlertAudits,
      payload,
    )
  }

  // ===================================================================
  // ASSESSMENT AUDIT
  // ===================================================================
  async logAssessmentAudit(body: EventAssessmentAuditReqBody) {
    return this.log(
      this.send<EventKindAssessmentAudit>(
        this._client.post(EventEndpoint.AssessmentAudits, body),
      ),
    )
  }
  async listAssessmentAuditLog(
    payload?: EventPayload<EventKindAssessmentAudit>,
  ) {
    return this.listFilter<
      ListEventKindAssessmentAudit,
      EventKindAssessmentAudit
    >(EventEndpoint.AssessmentAudits, payload)
  }

  // ===================================================================
  // DIAGNOSTIC AUDIT
  // ===================================================================
  async logDiagnosticAudit(body: EventDiagnosticAuditReqBody) {
    return this.log(
      this.send<EventKindDiagnosticAudit>(
        this._client.post(EventEndpoint.DiagnosticAudits, body),
      ),
    )
  }
  async listDiagnosticAuditLog(
    payload?: EventPayload<EventKindDiagnosticAudit>,
  ) {
    return this.listFilter<
      ListEventKindDiagnosticAudit,
      EventKindDiagnosticAudit
    >(EventEndpoint.DiagnosticAudits, payload)
  }

  // ===================================================================
  // EDSUPPORT
  // ===================================================================
  async logEdSupport(body: EventEdSupportReqBody) {
    return this.log(
      this.send<EventKindEdSupport>(
        this._client.post(EventEndpoint.EdSupport, body),
      ),
    )
  }
  async listEdSupportLog(payload?: EventPayload<EventKindEdSupport>) {
    return this.listFilter<ListEventKindEdSupport, EventKindEdSupport>(
      EventEndpoint.EdSupport,
      payload,
    )
  }
  async listEdSupportStats(payload?: EventPayload<EventKindEdSupportStat>) {
    return this.listFilter<EventKindEdSupportStats, EventKindEdSupportStat>(
      EventEndpoint.EdSupportStats,
      payload,
    )
  }

  // ===================================================================
  // EMAIL
  // ===================================================================
  async logEmail(body: EventEmailReqBody) {
    return this.log(
      this.send<EventKindEmail>(this._client.post(EventEndpoint.Email, body)),
    )
  }
  async listEmailLog(payload?: EventPayload<EventKindEmail>) {
    return this.listFilter<ListEventKindEmail, EventKindEmail>(
      EventEndpoint.Email,
      payload,
    )
  }
  async listEmailStats(payload?: EventPayload<EventWithCountAndDateStat>) {
    return this.listFilter<EventKindEmailStats, EventWithCountAndDateStat>(
      EventEndpoint.EmailStats,
      payload,
    )
  }

  // ===================================================================
  // FAILED LOGIN
  // ===================================================================
  async logFailedLogin(body: EventFailedLoginReqBody) {
    return this.log(
      this.send<EventKindFailedLogin>(
        this._client.post(EventEndpoint.FailedLogins, body),
      ),
    )
  }
  async listFailedLoginLog(payload?: EventPayload<EventKindFailedLogin>) {
    return this.listFilter<ListEventKindFailedLogin, EventKindFailedLogin>(
      EventEndpoint.FailedLogins,
      payload,
    )
  }
  async listFailedLoginStats(
    payload?: EventPayload<EventWithCountAndDateStat>,
  ) {
    return this.listFilter<
      EventKindFailedLoginStats,
      EventWithCountAndDateStat
    >(EventEndpoint.FailedLoginsStats, payload)
  }

  // ===================================================================
  // LESSON AUDIT
  // ===================================================================
  async logLessonAudit(body: EventLessonAuditReqBody) {
    return this.log(
      this.send<EventKindLessonAudit>(
        this._client.post(EventEndpoint.LessonAudits, body),
      ),
    )
  }
  async listLessonAuditLog(payload?: EventPayload<EventKindLessonAudit>) {
    return this.listFilter<ListEventKindLessonAudit, EventKindLessonAudit>(
      EventEndpoint.LessonAudits,
      payload,
    )
  }

  // ===================================================================
  // LESSON REVISION AUDIT
  // ===================================================================
  async logLessonRevisionAudit(body: EventLessonRevisionAuditReqBody) {
    return this.log(
      this.send<EventKindLessonRevisionAudit>(
        this._client.post(EventEndpoint.LessonRevisionAudits, body),
      ),
    )
  }
  async listLessonRevisionAuditLog(
    payload?: EventPayload<EventKindLessonRevisionAudit>,
  ) {
    return this.listFilter<
      ListEventKindLessonRevisionAudit,
      EventKindLessonRevisionAudit
    >(EventEndpoint.LessonRevisionAudits, payload)
  }

  // ===================================================================
  // STUDY LESSON SLIDE
  // ===================================================================
  async logStudyLessonSlide(body: EventStudyLessonSlideReqBody) {
    return this.log(
      this.send<EventKindStudyLessonSlide>(
        this._client.post(EventEndpoint.StudyLessonSlides, body),
      ),
    )
  }
  async listStudyLessonSlideLog(
    payload?: EventPayload<EventKindStudyLessonSlide>,
  ) {
    return this.listFilter<
      ListEventKindStudyLessonSlide,
      EventKindStudyLessonSlide
    >(EventEndpoint.StudyLessonSlides, payload)
  }
  async listStudyLessonSlideStats(
    payload?: EventPayload<EventKindStudyLessonSlideStat>,
  ) {
    return this.listFilter<
      EventKindStudyLessonSlideStats,
      EventKindStudyLessonSlideStat
    >(EventEndpoint.StudyLessonSlidesStats, payload)
  }

  // ===================================================================
  // LESSON SLIDE AUDIT
  // ===================================================================
  async logLessonSlideAudit(body: EventLessonSlideAuditReqBody) {
    return this.log(
      this.send<EventKindLessonSlideAudit>(
        this._client.post(EventEndpoint.LessonSlideAudits, body),
      ),
    )
  }
  async listLessonSlideAuditLog(
    payload?: EventPayload<EventKindLessonSlideAudit>,
  ) {
    return this.listFilter<
      ListEventKindLessonSlideAudit,
      EventKindLessonSlideAudit
    >(EventEndpoint.LessonSlideAudits, payload)
  }

  // ===================================================================
  // LOGIN
  // ===================================================================
  async logLogin(body: EventLoginReqBody) {
    return this.log(
      this.send<EventKindLogin>(this._client.post(EventEndpoint.Logins, body)),
    )
  }
  async listLoginLog(payload?: EventPayload<EventKindLogin>) {
    return this.listFilter<ListEventKindLogin, EventKindLogin>(
      EventEndpoint.Logins,
      payload,
    )
  }
  async listLoginStats(payload?: EventPayload<EventWithCountAndDateStat>) {
    return this.listFilter<EventKindLoginStats, EventWithCountAndDateStat>(
      EventEndpoint.LoginsStats,
      payload,
    )
  }*/

  async login() {
    return this.send<{ ok: string }>(this._client.post('login'))
  }

  async logLogin(body: { userId: string; email: string }) {
    return this.log(
      this.send<{ ok: string }>(
        this._client.post('activity/logins', {
          ...body,
          loginDate: new Date(),
          event: {
            info: {},
          },
        }),
      ),
    )
  }
  // ===================================================================
  // NAVIGATION
  // ===================================================================
  async logNavigation(body: { uri: string }) {
    return this.log(
      this.send<{ ok: string }>(
        this._client.post('activity/navigations', body),
      ),
    )
  }

  async logWondeSchool(schoolId: string) {
    return this.log(
      this.send<WondeSchool>(
        this._client.get(`external/wonde/schools/${schoolId}`),
      ),
    )
  }

  /*async listNavigationLog(payload?: EventPayload<EventKindNavigation>) {
    return this.listFilter<ListEventKindNavigation, EventKindNavigation>(
      EventEndpoint.Navigations,
      payload,
    )
  }
  async listNavigationStats(payload?: EventPayload<EventKindNavigationStat>) {
    return this.listFilter<EventKindNavigationStats, EventKindNavigationStat>(
      EventEndpoint.NavigationsStats,
      payload,
    )
  }

  // ===================================================================
  // PATHWAY AUDIT
  // ===================================================================
  async logPathwayAudit(body: EventPathwayAuditReqBody) {
    return this.log(
      this.send<EventKindPathwayAudit>(
        this._client.post(EventEndpoint.PathwayAudits, body),
      ),
    )
  }
  async listPathwayAuditLog(payload?: EventPayload<EventKindPathwayAudit>) {
    return this.listFilter<ListEventKindPathwayAudit, EventKindPathwayAudit>(
      EventEndpoint.PathwayAudits,
      payload,
    )
  }

  // ===================================================================
  // QUESTIONNAIRE AUDIT
  // ===================================================================
  async logQuestionnaireAudit(body: EventQuestionnaireAuditReqBody) {
    return this.log(
      this.send<EventKindQuestionnaireAudit>(
        this._client.post(EventEndpoint.QuestionnaireAudits, body),
      ),
    )
  }
  async listQuestionnaireAuditLog(
    payload?: EventPayload<EventKindQuestionnaireAudit>,
  ) {
    return this.listFilter<
      ListEventKindQuestionnaireAudit,
      EventKindQuestionnaireAudit
    >(EventEndpoint.QuestionnaireAudits, payload)
  }

  // ===================================================================
  // SCHOOL STORAGE
  // ===================================================================
  async logSchoolStorage(body: EventSchoolStorageReqBody) {
    return this.log(
      this.send<EventKindSchoolStorage>(
        this._client.post(EventEndpoint.SchoolStorages, body),
      ),
    )
  }
  async listSchoolStorageLog(payload?: EventPayload<EventKindSchoolStorage>) {
    return this.listFilter<ListEventKindSchoolStorage, EventKindSchoolStorage>(
      EventEndpoint.SchoolStorages,
      payload,
    )
  }

  // ===================================================================
  // TOPIC AUDIT
  // ===================================================================
  async logTopicAudit(body: EventTopicAuditReqBody) {
    return this.log(
      this.send<EventKindTopicAudit>(
        this._client.post(EventEndpoint.TopicAudits, body),
      ),
    )
  }
  async listTopicAuditLog(payload?: EventPayload<EventKindTopicAudit>) {
    return this.listFilter<ListEventKindTopicAudit, EventKindTopicAudit>(
      EventEndpoint.TopicAudits,
      payload,
    )
  }

  // ===================================================================
  // SUBJECT AUDIT
  // ===================================================================
  async logSubjectAudit(body: EventSubjectAuditReqBody) {
    return this.log(
      this.send<EventKindSubjectAudit>(
        this._client.post(EventEndpoint.SubjectAudits, body),
      ),
    )
  }
  async listSubjectAuditLog(payload?: EventPayload<EventKindSubjectAudit>) {
    return this.listFilter<ListEventKindSubjectAudit, EventKindSubjectAudit>(
      EventEndpoint.SubjectAudits,
      payload,
    )
  }

  // ===================================================================
  // TIMETABLE AUDIT
  // ===================================================================
  async logTimetableAudit(body: EventTimetableAuditReqBody) {
    return this.log(
      this.send<EventKindTimetableAudit>(
        this._client.post(EventEndpoint.TimetableAudits, body),
      ),
    )
  }
  async listTimetableAuditLog(payload?: EventPayload<EventKindTimetableAudit>) {
    return this.listFilter<
      ListEventKindTimetableAudit,
      EventKindTimetableAudit
    >(EventEndpoint.TimetableAudits, payload)
  }

  // ===================================================================
  // USER AUDIT
  // ===================================================================
  async logUserAudit(body: EventUserAuditReqBody) {
    return this.log(
      this.send<EventKindUserAudit>(
        this._client.post(EventEndpoint.UserAudits, body),
      ),
    )
  }
  async listUserAuditLog(payload?: EventPayload<EventKindUserAudit>) {
    return this.listFilter<ListEventKindUserAudit, EventKindUserAudit>(
      EventEndpoint.UserAudits,
      payload,
    )
  }
  async listUserAuditStats(payload?: EventPayload<EventKindUserAuditStat>) {
    return this.listFilter<EventKindUserAuditStats, EventKindUserAuditStat>(
      EventEndpoint.UserAuditStat,
      payload,
    )
  }

  protected async send<T>(promise: Promise<AxiosResponse<T>>): Promise<T> {
    if (import.meta.env.VITE_ENABLE_EVENT) {
      return super.send(promise)
    } else {
      return Promise.reject('event is disabled')
    }
  }*/

  sendStudentAlert() {
    return this.send(this._client.post(`sg/alerts/students`))
  }

  sendAlert(body: Record<string, unknown>) {
    return this.send(this._client.post(`sg/alerts`, body))
  }

  listTopicAncestors(topicId: string) {
    return this.send<
      Pick<EventAuditItemTopic, 'object'> & {
        ancestors: EventAuditItemTopic[]
        subject: EventAuditItemSubject
      }
    >(this._client.get(`data/topics/${topicId}/ancestors`))
  }

  listTopicBySubjectIds(subjectIds: string[], params: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<Topic>>(
      this._client.post(`data/topics/by-subjects${paramStr}`, subjectIds),
    )
  }

  listTopicTreeBySubjectIds(subjectIds: string[], params: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<{
      data: EventAuditItemTopicTree[]
      count: Record<
        string,
        {
          id: string
          count: number
        }
      >
      page: PaginatedItems<unknown>['page']
    }>(this._client.post(`data/topics/by-subjects/tree${paramStr}`, subjectIds))
  }

  listTopicTreeById(topicId: string, params: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<{
      data: EventAuditItemTopicTree[]
      count: Record<
        string,
        {
          id: string
          count: number
        }
      >
      page: PaginatedItems<unknown>['page']
    }>(this._client.get(`data/topics/${topicId}/tree${paramStr}`))
  }

  listSubjectTree(params: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<PaginatedItems<EventAuditItemSubjectTree>>(
      this._client.get(`data/subjects/tree${paramStr}`),
    )
  }

  listSkillTagsTreeBySkillIds(skillIds: string[], params: Params) {
    const paramStr = params ? `?${params.toString()}` : ''
    return this.send<{
      data: EventAuditItemSkillTagTree[]
      count: Record<
        string,
        {
          id: string
          count: number
        }
      >
      page: PaginatedItems<unknown>['page']
    }>(this._client.post(`data/skill-tags/by-skills/tree${paramStr}`, skillIds))
  }

  listStudent() {
    return this.send<
      {
        student: Student
        room: IvsRoomSummary
      }[]
    >(this._client.get(`data/students`))
  }

  joinRoom(body: { roomId: string }) {
    return this.send<IvsJoinResponse>(this._client.post(`sg/rooms/join`, body))
  }

  getRoom(roomId: string) {
    return this.send<IvsJoinResponse>(this._client.get(`sg/rooms/${roomId}`))
  }

  createChatToken(roomId: string) {
    return this.send(this._client.get(`sg/rooms/${roomId}/chat/token`))
  }

  genImage(prompt: string, name: string) {
    return this._client
      .post(
        'external/openai/image',
        {
          prompt,
        },
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        // Convert the blob into a URL and set it as the src of the image
        return {
          url: URL.createObjectURL(response.data),
          buffer: new File([response.data], name, {
            type: response.data.type,
          }),
        }
      })
  }
}
