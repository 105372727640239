import { Sym, SymProps } from '@edclass/fe-ui'
import type { Placement } from '@floating-ui/react'
import {
  IconButtonProps,
  Menu,
  MenuHandler,
  MenuList,
} from '@material-tailwind/react'
import type { offsetType } from '@material-tailwind/react/types/generic'
import { ReactNode, useState } from 'react'

export default function MoreDropdown({
  icon = 'more_vert',
  offset,
  children,
  placement,
  btnProps,
  menuClassName,
  iconProps,
}: {
  icon?: string
  children: ReactNode
  offset?: offsetType
  placement?: Placement
  btnProps?: Omit<IconButtonProps, 'children' | 'onClick' | 'ref'>
  iconProps?: Partial<SymProps>
  menuClassName?: string
}) {
  const [open, setOpen] = useState(false)
  return (
    <Menu offset={offset} handler={setOpen} open={open} placement={placement}>
      <MenuHandler>
        <button
          role="button"
          aria-label="Open menu"
          {...btnProps}
          onClick={() => setOpen(true)}
        >
          <Sym {...iconProps}>{icon}</Sym>
        </button>
      </MenuHandler>
      <MenuList data-testid="menu" className={menuClassName}>
        {children}
      </MenuList>
    </Menu>
  )
  /*
  return (
    <Popover open={open} offset={offset} arrow={arrow} handler={setOpen}>
      <PopoverHandler onClick={() => setOpen(true)}>
        <Sym>{icon}</Sym>
      </PopoverHandler>
      <PopoverContent>{children}</PopoverContent>
    </Popover>
  )*/
  /*return (
    <div className="ed-mdd">
      <IconButton
        
        onClick={() => {
          setOpen(true)
        }}
      >
        <Sym>{icon}</Sym>
      </IconButton>
    </div>
  )*/
}
