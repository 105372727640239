import selectOutlined from '@material-tailwind/react/theme/components/select/selectOutlined'
import selectStandard from '@material-tailwind/react/theme/components/select/selectStandard'
import selectStatic from '@material-tailwind/react/theme/components/select/selectStatic'

export const selectWithSearch = {
  defaultProps: {
    variant: 'outlined',
    color: 'blue',
    size: 'md',
    label: '',
    error: false,
    success: false,
    arrow: undefined,
    value: undefined,
    onChange: undefined,
    selected: undefined,
    offset: 5,
    dismiss: {},
    animate: {
      unmount: {},
      mount: {},
    },
    autoHeight: false,
    lockScroll: false,
    labelProps: {},
    menuProps: {},
    className: 'text-white',
    disabled: false,
    containerProps: undefined,
  },
  valid: {
    variants: ['standard', 'outlined', 'static'],
    sizes: ['md', 'lg'],
    colors: [
      'blue-gray',
      'gray',
      'brown',
      'deep-orange',
      'orange',
      'amber',
      'yellow',
      'lime',
      'light-green',
      'green',
      'teal',
      'cyan',
      'light-blue',
      'blue',
      'indigo',
      'deep-purple',
      'purple',
      'pink',
      'red',
    ],
  },
  styles: {
    base: {
      container: {
        position: 'relative',
        width: 'w-full',
        minWidth: 'min-w-[200px]',
        minHeight: '!min-h-11 h-auto',
      },
      select: {
        peer: 'peer',
        width: 'w-full',
        height: 'h-full min-h-11',
        bg: 'bg-transparent',
        color: 'text-blue-gray-700',
        fontFamily: 'font-sans',
        fontWeight: 'font-normal',
        textAlign: 'text-left',
        outline: 'outline outline-0 focus:outline-0',
        disabled:
          'disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed',
        transition: 'transition-all',
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      remove: {
        initial: {
          display: 'grid',
          position: 'absolute',
          placeItems: 'place-items-center',
          top: 'top-2/4',
          right: 'right-8',
          border: 'border-e border-blue-gray-400',
          pt: 'pt-px',
          width: 'w-8',
          height: 'h-5',
          color: 'text-blue-gray-400 hover:text-blue-gray-400/50',
          transform: 'rotate-0 -translate-y-2/4',
          transition: 'transition-all',
        },
      },
      arrow: {
        initial: {
          display: 'grid',
          placeItems: 'place-items-center',
          position: 'absolute',
          top: 'top-2/4',
          right: 'right-2',
          pt: 'pt-px',
          width: 'w-5',
          height: 'h-5',
          color: 'text-blue-gray-400',
          transform: 'rotate-0 -translate-y-2/4',
          transition: 'transition-all',
        },
        active: {
          transform: 'rotate-180',
          mt: 'mt-px',
        },
      },
      label: {
        display: 'flex',
        width: 'w-full',
        height: 'h-full',
        userSelect: 'select-none',
        pointerEvents: 'pointer-events-none',
        position: 'absolute',
        left: 'left-0',
        fontWeight: 'font-normal',
        transition: 'transition-all',
      },
      menu: {
        width: 'w-full',
        maxHeight: 'max-h-96',
        bg: 'bg-white',
        p: 'p-3',
        border: 'border border-blue-gray-50',
        borderRadius: 'rounded-md',
        boxShadow: 'shadow-lg shadow-blue-gray-500/10',
        fontFamily: 'font-sans',
        fontSize: 'text-sm',
        fontWeight: 'font-normal',
        color: 'text-blue-gray-500',
        overflow: 'overflow-auto',
        outline: 'focus:outline-none',
      },
      option: {
        initial: {
          pt: 'pt-[9px]',
          pb: 'pb-2',
          px: 'px-3',
          borderRadius: 'rounded-md',
          lightHeight: 'leading-tight',
          cursor: 'cursor-pointer',
          userSelect: 'select-none',
          background: 'hover:bg-blue-gray-50 focus:bg-blue-gray-50',
          opacity: 'hover:bg-opacity-80 focus:bg-opacity-80',
          color: 'hover:text-blue-gray-900 focus:text-blue-gray-900',
          outline: 'outline outline-0',
          transition: 'transition-all',
        },
        active: {
          bg: 'bg-blue-gray-50 bg-opacity-80',
          color: 'text-blue-gray-900',
        },
        disabled: {
          opacity: 'opacity-50',
          cursor: 'cursor-not-allowed',
          userSelect: 'select-none',
          pointerEvents: 'pointer-events-none',
        },
      },
    },
    variants: {
      outlined: {
        ...selectOutlined,
        sizes: {
          ...selectOutlined.sizes,
          xl: {
            ...selectOutlined.sizes.lg,
            container: {
              height: '!min-h-[3.25rem]',
            },
            select: {
              fontSize: 'text-sm',
              px: 'px-3',
              py: 'py-3',
              borderRadius: 'rounded-[7px]',
            },
            label: {
              initial: {},
              states: {
                close: {
                  lineHeight: 'leading-[4.1]',
                },
                open: {
                  lineHeight: 'leading-tight',
                },
                withValue: {
                  lineHeight: 'leading-tight',
                },
              },
            },
          },
        },
      },
      standard: selectStandard,
      static: selectStatic,
    },
  },
}
