import { Sym } from '@edclass/fe-ui'
import { OffsetOptions, Placement } from '@floating-ui/react'
import { Button, Spinner } from '@material-tailwind/react'
import clsx from 'clsx'
import { capitalize } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { Popover, PopoverContent, PopoverHandler } from '@/components/Popover'

const icons = import('./icons.ts')

export function IconPickerBody({
  value,
  onChange,
}: {
  value: string
  onChange: (next: string) => void
}) {
  const [iconList, setIconList] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<false | string>(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (iconList.length < 1) {
      icons
        .then((ic) => {
          setIconList(ic.default)
        })
        .catch(() => {
          setError('Failed to load icon')
        })
        .finally(() => setLoading(false))
    }
  }, [iconList])

  const filteredIcon = useMemo(() => {
    return search.length > 0
      ? iconList.filter((icon) => {
          return (
            icon.includes(search.toLowerCase()) ||
            search.toLowerCase().includes(icon)
          )
        })
      : iconList
  }, [iconList, search])

  return (
    <div className="text-white flex-shrink-0 bg-ed-bg border border-white/20">
      <div className="flex items-center border-b border-white/20">
        <Sym className="ps-2 opacity-60">search</Sym>
        <input
          onChange={(e) => {
            setSearch(e.target.value)
          }}
          className="bg-transparent w-full focus:outline-none p-2"
        />
      </div>
      <div>
        {loading ? (
          <Spinner />
        ) : error ? (
          <div>{error}</div>
        ) : (
          <div className="grid grid-cols-8 gap-2 min-w-[calc(32px*8+(0.5rem*7)+1rem)] max-h-[calc(32px*8+(0.5rem*7))] overflow-y-auto p-2">
            {filteredIcon.map((i) => {
              const label = capitalize(i.replaceAll('_', ' '))
              return (
                <button
                  onClick={() => {
                    onChange(i)
                  }}
                  title={label}
                  aria-label={label}
                  key={i}
                  className={clsx(
                    'transition-all cc rounded-md cursor-pointer hover:bg-blue-500 w-9 h-9',
                    value === i && 'bg-blue-600 && hover:bg-blue-600',
                  )}
                >
                  <Sym>{i}</Sym>
                </button>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export type IconPickerProps = {
  value: string
  onChange: (next: string) => void
  size?: 'md' | 'lg'
  offset?: OffsetOptions
  placement?: Placement
}

export default function IconPicker({
  value,
  onChange,
  size = 'lg',
  offset,
  placement = 'bottom',
}: IconPickerProps) {
  const [open, setOpen] = useState(false)
  const [icon, setIcon] = useState(value)
  const height = size === 'md' ? 'h-10' : 'h-11'
  return (
    <Popover offset={offset} placement={placement} open={open}>
      <PopoverHandler>
        <button
          className={clsx(
            'cc border border-blue-gray-200 rounded-md hover:bg-blue-500 hover:border-blue-500 transition-all',
            icon ? 'w-11' : 'w-auto min-w-20',
            height,
          )}
          onClick={() => setOpen(!open)}
        >
          {icon ? <Sym>{icon}</Sym> : <div>Pick Icon</div>}
        </button>
      </PopoverHandler>
      <PopoverContent className="z-[10] p-0 border-0">
        <IconPickerBody
          value={icon}
          onChange={(v) => {
            setIcon(v)
          }}
        />
        <div className="flex items-center justify-between text-body p-2 gap-4 border-b bg-ed-bg border-x border-white/20 w-full">
          <Button
            onClick={() => {
              onChange('')
              setIcon('')
              setOpen(false)
            }}
            color="blue-gray"
            size="sm"
          >
            Clear
          </Button>
          <Button
            onClick={() => {
              onChange(icon)
              setOpen(false)
            }}
            size="sm"
          >
            Done
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
