import selectOutlined from '@material-tailwind/react/theme/components/select/selectOutlined'
import selectStandard from '@material-tailwind/react/theme/components/select/selectStandard'
import selectStatic from '@material-tailwind/react/theme/components/select/selectStatic'

import { autoComplete } from '@/components/AutoCompleteInput/theme.ts'
import { dropdown } from '@/components/DropdownMd/theme.ts'
import { multiSelect } from '@/components/MultiSelect/theme.ts'
import { selectWithSearch } from '@/components/SelectWithSearch/theme.ts'

export const ED_THEME = {
  multiSelect,
  selectWithSearch,
  autoComplete,
  dropdown,
  accordion: {
    styles: {
      base: {
        header: {
          initial: {
            py: 'py-2 px-4',
            fontSize: 'font-main',
            color: 'text-white/80',
            hover: 'hover:text-white',
          },
          active: {
            color: 'text-white',
          },
        },
      },
    },
  },
  button: {
    defaultProps: {
      color: 'blue',
      ripple: false,
    },
  },
  iconButton: {
    defaultProps: {
      color: 'blue',
      ripple: false,
    },
  },
  select: {
    defaultProps: {
      color: 'blue',
    },
    styles: {
      base: {
        select: {
          color: 'text-white',
        },
      },
      variants: {
        outlined: {
          ...selectOutlined,
          sizes: {
            ...selectOutlined.sizes,
            xl: {
              ...selectOutlined.sizes.lg,
              container: {
                height: '!min-h-[3.25rem]',
              },
            },
          },
        },
        standard: selectStandard,
        static: selectStatic,
      },
    },
  },
  input: {
    defaultProps: {
      color: 'blue',
    },
    styles: {
      base: {
        container: {
          minWidth: 'min-w-0',
        },
        input: {
          color: 'text-white',
          disabled:
            'disabled:bg-blue-gray-900 disabled:border-0 disabled:cursor-not-allowed disabled:text-blue-gray-500',
        },
      },
    },
  },
  tooltip: {
    styles: {
      base: {
        zIndex: 'z-[10000]',
        fontSize: 'text-xs',
        borderRadius: 'rounded-md',
      },
    },
  },
  textarea: {
    defaultProps: {
      color: 'blue',
    },
    styles: {
      base: {
        textarea: {
          color: 'text-white',
        },
      },
    },
  },
  switch: {
    defaultProps: {
      color: 'blue',
      ripple: false,
    },
    styles: {
      base: {
        label: {
          color: 'text-white',
        },
      },
    },
  },
  checkbox: {
    defaultProps: {
      color: 'blue',
      ripple: false,
    },
  },
  dialog: {
    styles: {
      base: {
        container: {
          bg: 'bg-ed-bg',
          color: 'text-white',
        },
      },
    },
  },
  dialogHeader: {
    styles: {
      base: {
        fontSize: 'text-main',
      },
    },
  },
  dialogBody: {
    styles: {
      base: {
        initial: {
          color: 'text-white',
          fontSize: 'text-main',
        },
        divider: {
          borderTopColor: 'border-t-ed-blue/25',
          borderBottomColor: 'border-b-ed-blue/25',
        },
      },
    },
  },
  dialogFooter: {
    styles: {
      base: {
        initial: {
          color: 'text-white',
          fontSize: 'text-main',
        },
      },
    },
  },
  tab: {
    styles: {
      base: {
        tab: {
          initial: {
            fontSize: 'text-[12px] text-blue-gray-900',
            fontWeight: 'font-semi',
          },
        },
      },
    },
  },
  tabsHeader: {
    styles: {
      base: {
        uppercase: 'uppercase',
        fontBold: 'font-bold',
        bgOpacity: 'bg-opacity-80',
      },
    },
  },
  menu: {
    styles: {
      base: {
        menu: {
          bg: 'bg-ed-dark-blue',
          border: 'border border-blue-800',
          fontSize: 'text-[14px]',
          color: 'text-white',
        },
        item: {
          initial: {
            bg: 'hover:bg-ed-blue focus:bg-ed-blue active:bg-ed-blue',
            color: 'hover:text-white focus:text-white active:text-white',
          },
        },
      },
    },
  },
}
