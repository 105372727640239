import './index.css'

import { hasFlag } from '@edclass/fe-common'
import { Tab, Tabs, TabsBody, TabsHeader } from '@material-tailwind/react'
import { useCallback, useState } from 'react'

import FileManagerAIPanel from '@/components/FileManager/FileManagerAIPanel.tsx'
import { FileManagerContext } from '@/components/FileManager/FileManagerContext.tsx'
import FileManagerModal from '@/components/FileManager/FileManagerModal.tsx'
import FileManagerUploaderPanel from '@/components/FileManager/FileManagerUploaderPanel.tsx'
import { tabToFlag } from '@/components/FileManager/helpers.ts'
import { useFm } from '@/components/FileManager/hooks.ts'
import {
  FILE_MANAGER_TABS,
  FileManagerProps,
  FileManagerTab,
  FileManagerTabFlag,
} from '@/components/FileManager/types.ts'
import SearchProvider from '@/providers/SearchProvider.tsx'

import FileManagerBrowserPanel from './FileManagerBrowserPanel.tsx'

function FileManagerPanel({ tab }: { tab: FileManagerTab }) {
  switch (tab) {
    case FileManagerTab.Browser:
      return <FileManagerBrowserPanel />
    case FileManagerTab.Uploader:
      return <FileManagerUploaderPanel />
    case FileManagerTab.AI:
      return <FileManagerAIPanel />
  }
}

function FileManagerBody() {
  const { activeTab, setActiveTab, tabFlags, isUploading } = useFm()

  return (
    <div className="ed-fm-body">
      <div className="">
        <Tabs value={activeTab}>
          <TabsHeader>
            {FILE_MANAGER_TABS.map((tab) => {
              if (hasFlag(tabFlags, tabToFlag(tab))) {
                return (
                  <Tab
                    key={tab}
                    value={tab}
                    onClick={(e) => {
                      if (isUploading) {
                        e.preventDefault()
                        e.stopPropagation()
                        return
                      }
                      setActiveTab(tab)
                    }}
                  >
                    {tab}
                  </Tab>
                )
              }
              return null
            })}
          </TabsHeader>
          <TabsBody>
            {FILE_MANAGER_TABS.map((tab) => {
              if (hasFlag(tabFlags, tabToFlag(tab)) && activeTab === tab) {
                return <FileManagerPanel key={tab} tab={tab} />
              }
              return null
            })}
          </TabsBody>
        </Tabs>
      </div>
    </div>
  )
}

export default function FileManager({
  id,
  displayColumn = 4,
  multiple = false,
  onChange,
  onUpload,
  modal,
  accept,
  tabFlags = FileManagerTabFlag.All,
  aiPrompt,
  onPromptChange,
  aiImageName,
  contentType,
}: FileManagerProps) {
  const [activeTab, setActiveTab] = useState(FileManagerTab.Uploader)
  const [selected, setSelected] = useState<FsItem[]>([])
  const [files, setFiles] = useState<File[]>([])
  const [isUploading, setIsUploading] = useState(false)

  const isSelected = useCallback(
    (item: FsItem) => {
      if (selected.length < 1) {
        return false
      }

      return selected.find((sel) => sel.key === item.key) !== undefined
    },
    [selected],
  )

  const toggleSelected = useCallback(
    (item: FsItem) => {
      const sel = selected.findIndex((s) => {
        return s.key === item.key
      })

      if (sel !== -1) {
        // item exists
        if (multiple) {
          setSelected(
            selected.filter((s) => {
              return s.key !== item.key
            }),
          )
        } else {
          // not multiple remove all items
          setSelected([])
        }
      } else {
        setSelected(multiple ? [...selected, item] : [item])
      }
    },
    [selected, multiple],
  )

  const removeFile = useCallback(
    (f: File) => {
      setFiles(files.filter((p) => p.name !== f.name))
    },
    [files],
  )

  const [aiImage, setAiImage] = useState<{
    url: string
    buffer: File | null
  } | null>(null)

  return (
    <SearchProvider>
      <FileManagerContext.Provider
        value={{
          id,
          onChange,
          activeTab,
          setActiveTab,
          displayColumn,
          selected,
          setSelected,
          toggleSelected,
          multiple,
          isSelected,
          files,
          setFiles,
          removeFile,
          onUpload,
          isUploading,
          setIsUploading,
          modal,
          accept,
          tabFlags,
          onPromptChange,
          aiPrompt,
          aiImage,
          setAiImage,
          aiImageName,
          contentType,
        }}
      >
        {modal ? (
          <FileManagerModal open={modal.open} handler={modal.handler}>
            <FileManagerBody />
          </FileManagerModal>
        ) : (
          <FileManagerBody />
        )}
      </FileManagerContext.Provider>
    </SearchProvider>
  )
}
