import { DndContext } from '@dnd-kit/core'
import { createContext, ReactNode } from 'react'

import useDraggableCoordinate from '@/hooks/useDraggableCoordinate.ts'
interface DraggableDialogValue {
  x: number
  y: number
  id: string
  setCoordinates: SetState<{
    x: number
    y: number
  }>
  fullScreen: boolean
  handler?: SetState<unknown>
}

export const DraggableDialogContext = createContext<DraggableDialogValue>({
  id: 'draggable',
  x: 0,
  y: 0,
  setCoordinates: () => null,
  fullScreen: false,
  handler: () => null,
})
DraggableDialogContext.displayName = 'DraggableDialogContext'

export default function DraggableDialogProvider({
  id = 'draggable',
  children,
  initialCoordinate,
  fullScreen = false,
  onDragEnd,
  handler,
}: {
  id?: string
  initialCoordinate?: {
    x: number
    y: number
  }
  fullScreen?: boolean
  children: ReactNode
  onDragEnd?: (x: number, y: number) => void
  handler?: SetState<unknown>
}) {
  const { x, y, setCoordinates, sensors } = useDraggableCoordinate(
    initialCoordinate || {
      x: 0,
      y: 0,
    },
  )

  return (
    <DraggableDialogContext.Provider
      value={{
        id,
        x,
        y,
        setCoordinates,
        fullScreen,
        handler,
      }}
    >
      <DndContext
        sensors={sensors}
        onDragEnd={({ delta }) => {
          setCoordinates(({ x, y }) => {
            return {
              x: x + delta.x,
              y: y + delta.y,
            }
          })
          onDragEnd?.(x + delta.x, y + delta.y)
        }}
      >
        {children}
      </DndContext>
    </DraggableDialogContext.Provider>
  )
}
