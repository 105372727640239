const newAlerts = [
  {
    id: 1,
    name: 'Test User 1',
    time: '2024-02-21 00:00:00',
    type: 'new_alerts',
    kind: 'alert',
  },
  {
    id: 2,
    name: 'Test User 2',
    time: '2024-02-21 00:00:00',
    type: 'new_alerts',
    kind: 'alert',
  },
  {
    id: 3,
    name: 'Test User 3',
    time: '2024-02-21 00:00:00',
    type: 'new_alerts',
    kind: 'alert',
  },
]

const currentAlerts = [
  {
    id: 4,
    name: 'Test User 4',
    time: '2024-02-21 00:00:00',
    type: 'current_alerts',
    kind: 'alert',
  },
]

const closedAlerts = [
  {
    id: 5,
    name: 'Test User 5',
    time: '2024-02-21 00:00:00',
    type: 'closed_alerts',
    kind: 'alert',
  },
]

export const DUMMY_ALERTS = {
  new_alerts: newAlerts,
  current_alerts: currentAlerts,
  closed_alerts: closedAlerts,
}
