import { Sym } from '@edclass/fe-ui'
import clsx from 'clsx'

interface StackedAvatarProps {
  id?: string
  src?: string | null
  alt?: string
  size?: string
  noBorder?: boolean
  color?: string
  className?: string
  notFoundClassName?: string
  fullSize?: boolean
  imgClassName?: string
}
export default function StackedAvatar({
  id,
  src,
  alt,
  size,
  noBorder,
  color,
  className,
  notFoundClassName,
  fullSize,
  imgClassName,
}: StackedAvatarProps) {
  return (
    <div
      style={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        '--size': size || '40px',
      }}
      className={clsx(
        'w-[var(--size)] h-[var(--size)]',
        'rounded-full overflow-hidden',
        !noBorder && 'border-4 border-ed-dark-blue-2',
        className,
      )}
      id={id}
    >
      {src ? (
        <img className={imgClassName} alt={alt} src={src} />
      ) : (
        <div
          className={clsx(
            'grid place-items-center w-[var(--size)] h-[var(--size)]',
            notFoundClassName,
          )}
        >
          <Sym
            filled
            className={clsx(
              noBorder
                ? fullSize
                  ? '!text-[calc(var(--size))]'
                  : '!text-[calc(var(--size)-8px)]'
                : '!text-[calc(var(--size)-8px)] w-[var(--size)] h-[var(--size)]',
              color ? color : 'text-ed-dark-blue-2',
            )}
          >
            face
          </Sym>
        </div>
      )}
    </div>
  )
}
