import clsx from 'clsx'

import EdColorPicker from '@/components/ColorPicker/EdColorPicker.tsx'
import DropdownMd from '@/components/DropdownMd'

export default function EdColorPickerDropdown({
  value,
  onChange,
  className,
  showValue = false,
  label,
  light,
}: {
  value?: string
  onChange: (next: string) => void
  className?: string
  showValue?: boolean
  label?: string
  light?: boolean
}) {
  return (
    <DropdownMd
      withValue={true}
      label={label ?? 'Pick a color'}
      className={clsx(light ? 'text-ed-bg' : 'text-white')}
      containerProps={{
        className: '!w-auto',
      }}
      menuProps={{
        className: 'p-4 !h-auto !max-h-[initial] !w-[auto] max-w-[280px]',
      }}
      toggle={
        <div className={clsx('w-full flex-c-2', className)}>
          <div
            style={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              '--ed-cp-value': value ?? '#ffffffff',
            }}
            className="w-5 h-5 flex-shrink-0 border border-blue-gray-200 rounded-md bg-[var(--ed-cp-value)]"
          />
          {showValue ? (
            <span className="uppercase">{value ?? '#ffffffff'}</span>
          ) : null}
        </div>
      }
    >
      <EdColorPicker
        value={(value as string) ?? '#ffffffff'}
        onChange={onChange}
      />
    </DropdownMd>
  )
}
