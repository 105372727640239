import {
  autoUpdate,
  flip,
  offset as fuiOffset,
  OffsetOptions,
  size as fuiSize,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import {
  animate,
  menuProps,
} from '@material-tailwind/react/types/components/select'
import {
  dismissType,
  NewAnimatePresenceProps,
} from '@material-tailwind/react/types/generic'
import merge from 'deepmerge'
import { AnimatePresence } from 'framer-motion'
import { FC, useMemo, useState } from 'react'

import { DEFAULT_FLOATING_ANIMATION } from '@/constants/constants.ts'

export default function useFloatingDropdown<T>({
  offset,
  dismiss,
  menuRole = 'listbox',
  contextValueProps,
  menuProps,
  animate,
  menuClasses,
  ddClasses,
  disabled,
  restProps,
  name,
}: {
  menuRole?: AriaRole
  dismiss?: dismissType
  offset?: OffsetOptions
  contextValueProps?: Partial<T>
  menuProps?: menuProps
  animate?: animate
  menuClasses?: string
  ddClasses?: string
  disabled?: boolean
  restProps?: Record<string, unknown>
  name?: string
}) {
  const [open, setOpen] = useState(false)
  const [controlledScrolling, setControlledScrolling] = useState(false)

  const { x, y, strategy, refs, context } = useFloating({
    placement: 'bottom-start',
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      fuiOffset(offset),
      flip({ padding: 10 }),
      fuiSize({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        apply({ rects, elements }: any) {
          Object.assign(elements?.floating?.style, {
            width: `${rects?.reference?.width}px`,
            zIndex: 99,
          })
        },
        padding: 20,
      }),
    ],
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context, {
        keyboardHandlers: false,
      }),
      useRole(context, { role: menuRole }),
      useDismiss(context, { ...dismiss }),
    ],
  )

  const contextValue = useMemo(
    () =>
      ({
        setOpen,
        getItemProps,
        dataRef: context.dataRef,
        ...contextValueProps,
      }) as T,
    [getItemProps, context.dataRef, contextValueProps],
  )

  const appliedAnimation = merge(DEFAULT_FLOATING_ANIMATION, animate ?? {})

  // 6. create an instance of AnimatePresence because of the types issue with the children
  const NewAnimatePresence: FC<NewAnimatePresenceProps> = AnimatePresence

  const floatingProps = getFloatingProps({
    ...menuProps,
    ref: refs.setFloating,
    role: menuRole,
    className: menuClasses,
    style: {
      position: strategy,
      top: y ?? 0,
      left: x ?? 0,
      overflow: 'auto',
    },
    onPointerEnter(e) {
      const onPointerEnter = menuProps?.onPointerEnter
      if (typeof onPointerEnter === 'function') {
        onPointerEnter(e)
        setControlledScrolling(false)
      }
      setControlledScrolling(false)
    },
    onPointerMove(e) {
      const onPointerMove = menuProps?.onPointerMove
      if (typeof onPointerMove === 'function') {
        onPointerMove(e)
        setControlledScrolling(false)
      }
      setControlledScrolling(false)
    },
    onKeyDown(e) {
      const onKeyDown = menuProps?.onKeyDown
      if (typeof onKeyDown === 'function') {
        onKeyDown(e)
        setControlledScrolling(true)
      }
      setControlledScrolling(true)
    },
  })

  const refProps = getReferenceProps({
    ...restProps,
    ref: refs.setReference,
    className: ddClasses,
    disabled: disabled,
    name: name,
  })

  return {
    open,
    setOpen,
    x,
    y,
    strategy,
    refs,
    context,
    contextValue,
    floatingProps,
    NewAnimatePresence,
    appliedAnimation,
    controlledScrolling,
    refProps,
  }
}
