import { lazy } from 'react'

import {
  AdminCrudPath,
  AdminLessonChildrenPath,
} from '@/constants/constants.ts'

// eslint-disable-next-line react-refresh/only-export-components
const Page = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminElementBuilderPage/AdminDndBuilderPage/index.tsx'
    ),
)

// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminElementBuilderPage/AdminDndBuilderPage/AdminDndBuilderPageIndex.tsx'
    ),
)

// eslint-disable-next-line react-refresh/only-export-components
const Create = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminElementBuilderPage/AdminDndBuilderPage/AdminDndBuilderPageCreate.tsx'
    ),
)

// eslint-disable-next-line react-refresh/only-export-components
const Edit = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminElementBuilderPage/AdminDndBuilderPage/AdminDndBuilderPageEdit.tsx'
    ),
)

// eslint-disable-next-line react-refresh/only-export-components
const Archive = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminElementBuilderPage/AdminDndBuilderPage/AdminDndBuilderPageArchive.tsx'
    ),
)

export default {
  path: AdminLessonChildrenPath.DND,
  element: <Page />,
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: AdminCrudPath.CREATE,
      element: <Create />,
    },
    {
      path: `:did/${AdminCrudPath.EDIT}`,
      element: <Edit />,
    },
    {
      path: `archives`,
      element: <Archive />,
    },
  ],
}
