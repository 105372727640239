import { useContext } from 'react'

import { SelectWithSearchContext } from '@/components/SelectWithSearch/SelectWithSearchContext.tsx'

export function useSelectWithSearch() {
  const context = useContext(SelectWithSearchContext)

  if (context === null) {
    throw new Error(
      'useSelectWithSearch() must be used within a Select. It happens when you use SelectOption component outside the SelectWithSearch component.',
    )
  }

  return context
}
