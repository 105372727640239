import deepEqual from 'fast-deep-equal'
import { useCallback, useState } from 'react'

import { usePrevious } from '@/hooks/usePrevious.ts'

export default function useParamsWithPrev<T>(initialParams?: T): {
  params: T
  setParams: SetState<T>
  previousParams?: T
  areParamsEqual: () => boolean
} {
  const [params, setParams] = useState<T>(initialParams || ({} as T))
  const previousParams = usePrevious(params)
  const areParamsEqual = useCallback(() => {
    return deepEqual(params, previousParams)
  }, [params, previousParams])
  return {
    params,
    setParams,
    previousParams,
    areParamsEqual,
  }
}
