import { createContext, Provider, ReactNode, useCallback } from 'react'

export interface InputRepeaterContextValue<
  T extends ValueWithId = ValueWithId,
> {
  id: string
  value: T[]
  updateValue: (v: T, index: number) => void
  removeValue: (index: number) => void
  addValue: (v: T) => void
  label: Omit<Record<keyof T, string>, 'id'>
  onChange: (value: T[]) => void
  fieldKeys: Array<keyof T>
  grid?: number
}

export type InputRepeaterProviderProps<T extends ValueWithId> = Omit<
  InputRepeaterContextValue<T>,
  'updateValue' | 'removeValue' | 'addValue' | 'value'
>

export const InputRepeaterContext =
  createContext<InputRepeaterContextValue | null>(null)

export function InputRepeaterProvider<T extends ValueWithId = ValueWithId>({
  id,
  children,
  initialValue: value,
  label,
  onChange,
  fieldKeys,
  grid,
}: {
  id: string
  children?: ReactNode
  initialValue: T[]
  onChange: (value: T[]) => void
} & InputRepeaterProviderProps<T>) {
  const updateValue = useCallback(
    (v: T, i: number) => {
      const copy = [...value]
      copy[i] = v
      onChange(copy)
    },
    [value, onChange],
  )

  const removeValue = useCallback(
    (i: number) => {
      const copy = [...value]
      copy.splice(i, 1)
      onChange(copy)
    },
    [value, onChange],
  )

  const addValue = useCallback(
    (v: T) => {
      const copy = [...value]
      copy.push(v)
      onChange(copy)
    },
    [value, onChange],
  )

  const Provider = InputRepeaterContext.Provider as unknown as Provider<
    InputRepeaterContextValue<T>
  >

  return (
    <Provider
      value={{
        id,
        value,
        updateValue,
        removeValue,
        addValue,
        label,
        onChange,
        fieldKeys,
        grid,
      }}
    >
      {children}
    </Provider>
  )
}
