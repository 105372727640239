import { useCallback } from 'react'
import {
  NavigateFunction,
  NavigateOptions,
  To,
  useNavigate,
} from 'react-router-dom'

import useGlobalContext from '@/hooks/useGlobalContext.ts'

export default function useLoggedNavigate(): NavigateFunction {
  const navigate = useNavigate()
  const { logger } = useGlobalContext()
  return useCallback(
    (to: To | number, options: NavigateOptions = {}) => {
      navigate(to as To, options)
      if (typeof to === 'number') {
        logger.current?.logNavigation({
          uri:
            history.state.usr?.from ||
            document?.referrer ||
            to.toString() ||
            '',
        })
      } else {
        logger.current?.logNavigation({
          uri: to.toString(),
        })
        navigate(to, options)
      }
    },
    [navigate, logger],
  )
}
