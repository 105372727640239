import { TeacherChildrenPath } from '@/constants/constants.ts'
import { PathKind } from '@/helpers/path.ts'
import { roomLoader } from '@/loader/roomLoader.ts'
import TeacherPage from '@/pages/TeacherPage/index.tsx'
import TeacherAlertsPage from '@/pages/TeacherPage/TeacherAlertsPage'
import TeacherAttendancePage from '@/pages/TeacherPage/TeacherAttendancePage'
import TeacherBehaviourPage from '@/pages/TeacherPage/TeacherBehaviourPage'
import teacherEdTeachRoutes from '@/pages/TeacherPage/TeacherEDTeachPage/route'
import TeacherHomeVisitsPage from '@/pages/TeacherPage/TeacherHomeVisitsPage'
import TeacherInspectionReportPage from '@/pages/TeacherPage/TeacherInspectionReportPage'
import TeacherLearningPathwaysPage from '@/pages/TeacherPage/TeacherLearningPathwaysPage'
import TeacherNotificationPage from '@/pages/TeacherPage/TeacherNotificationPage'
import TeacherPermissionsPage from '@/pages/TeacherPage/TeacherPermissionsPage'
import TeacherReportPage from '@/pages/TeacherPage/TeacherReportPage'
import TeacherReviewsPage from '@/pages/TeacherPage/TeacherReviewsPage'
import TeacherRewardsPage from '@/pages/TeacherPage/TeacherRewardsPage'
import TeacherSafeGuardingPage from '@/pages/TeacherPage/TeacherSafeGuardingPage'
import TeacherSchoolSetupPage from '@/pages/TeacherPage/TeacherSchoolSetupPage'
import TeacherSeatAllocationPage from '@/pages/TeacherPage/TeacherSeatAllocationPage'
import TeacherStaffPage from '@/pages/TeacherPage/TeacherStaffPage'
import TeacherStudentProfilePage from '@/pages/TeacherPage/TeacherStudentProfilePage'
import TeacherStudentsLivePage from '@/pages/TeacherPage/TeacherStudentsLivePage'
import TeacherSubjectsPage from '@/pages/TeacherPage/TeacherSubjectsPage'
import TeacherSubscriptionPage from '@/pages/TeacherPage/TeacherSubscriptionPage'
import TeacherTimetablePage from '@/pages/TeacherPage/TeacherTimetablePage'
import TeacherWeeklyOverviewPage from '@/pages/TeacherPage/TeacherWeeklyOverviewPage'
export default {
  path: PathKind.Teacher,
  loader: roomLoader,
  element: <TeacherPage />,
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: Component } = await import(
          '@/pages/TeacherPage/TeacherDashboardPage.tsx'
        )
        return { Component }
      },
    },
    {
      path: TeacherChildrenPath.TIMETABLE,
      element: <TeacherTimetablePage />,
    },
    {
      path: TeacherChildrenPath.REPORTS,
      element: <TeacherReportPage />,
    },
    {
      path: TeacherChildrenPath.SEAT_ALLOCATION,
      element: <TeacherSeatAllocationPage />,
    },
    {
      path: TeacherChildrenPath.SAFE_GUARDING,
      element: <TeacherSafeGuardingPage />,
    },
    {
      path: TeacherChildrenPath.ALERTS,
      element: <TeacherAlertsPage />,
    },
    {
      path: TeacherChildrenPath.HOME_VISITS,
      element: <TeacherHomeVisitsPage />,
    },
    {
      path: TeacherChildrenPath.STUDENT_PROFILE,
      element: <TeacherStudentProfilePage />,
    },
    {
      path: TeacherChildrenPath.LEARNING_PATHWAYS,
      element: <TeacherLearningPathwaysPage />,
    },
    {
      path: TeacherChildrenPath.ATTENDANCE,
      element: <TeacherAttendancePage />,
    },
    {
      path: TeacherChildrenPath.BEHAVIOUR,
      element: <TeacherBehaviourPage />,
    },
    {
      path: TeacherChildrenPath.SUBJECTS,
      element: <TeacherSubjectsPage />,
    },
    {
      path: TeacherChildrenPath.REVIEWS,
      element: <TeacherReviewsPage />,
    },
    {
      path: TeacherChildrenPath.INSPECTION_REPORT,
      element: <TeacherInspectionReportPage />,
    },
    {
      path: TeacherChildrenPath.PERMISSIONS,
      element: <TeacherPermissionsPage />,
    },
    {
      path: TeacherChildrenPath.SCHOOL_SETUP,
      element: <TeacherSchoolSetupPage />,
    },
    {
      path: TeacherChildrenPath.STAFF,
      element: <TeacherStaffPage />,
    },
    {
      path: TeacherChildrenPath.STUDENTS_LIVE,
      element: <TeacherStudentsLivePage />,
    },
    {
      path: TeacherChildrenPath.NOTIFICATION,
      element: <TeacherNotificationPage />,
    },
    {
      path: TeacherChildrenPath.REWARDS,
      element: <TeacherRewardsPage />,
    },
    {
      path: TeacherChildrenPath.SUBSCRIPTION,
      element: <TeacherSubscriptionPage />,
    },
    {
      path: TeacherChildrenPath.WEEKLY_OVERVIEW,
      element: <TeacherWeeklyOverviewPage />,
    },
    teacherEdTeachRoutes,
  ],
}
