import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export default function ReportsSkeleton() {
  return (
    <SkeletonTheme baseColor="#202442" highlightColor="#292e58">
      <div className="grid grid-cols-4 gap-8 w-full">
        {Array.from(Array(16).keys()).map((i) => {
          return (
            <Skeleton
              key={i}
              borderRadius={8}
              //width="150px"
              height="120px"
              //containerClassName="w-full flex min-w-0 h-full"
            />
          )
        })}
      </div>
    </SkeletonTheme>
  )
}
