import { Sym } from '@edclass/fe-ui'

import { TimetableSubjectItemProps } from '@/components/TimetableSheet/TimetableSubjectItem.tsx'

export default function TimetableSubjectAction({
  item,
}: TimetableSubjectItemProps) {
  return item.isLeave ? null : (
    <button
      onClick={() => {
        alert('edit clicked')
      }}
    >
      {!item.subject ? (
        <Sym className="!text-[20px]">add</Sym>
      ) : (
        <Sym className="!text-[20px]">edit_square</Sym>
      )}
    </button>
  )
}
