import { Spinner } from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ulid } from 'ulid'

import AdminCurriculumForm, {
  CurriculumFormValue,
} from '@/pages/AdminPage/AdminCurriculumsPage/AdminCurriculumForm'
import { DUMMY_CURRICULUMS } from '@/pages/AdminPage/AdminCurriculumsPage/dummy.ts'

export default function AdminCurriculumsCRUDPage() {
  const { id } = useParams<{
    id: string
  }>()

  const [loading, setLoading] = useState(false)
  const [initialValue, setInitialValue] = useState<
    CurriculumFormValue | undefined
  >()

  useEffect(() => {
    if (id) {
      setLoading(true)
      const cb = async () => {
        try {
          const val = DUMMY_CURRICULUMS.find((s) => s.id.toString() === id)
          if (val) {
            setInitialValue({
              id: val.id,
              name: val.name,
              years: val.years.map((y) => {
                return {
                  id: ulid(),
                  value: y,
                }
              }),
            })
          }
        } catch (e) {
          console.error(e)
        } finally {
          setLoading(false)
        }
      }

      cb().catch(console.error)
    }
  }, [id])

  if (!id) {
    return <AdminCurriculumForm />
  }

  return !initialValue || loading ? (
    <Spinner />
  ) : (
    <AdminCurriculumForm initialValue={initialValue} />
  )
}
