import { useContext } from 'react'

import { InputRepeaterContext } from '@/components/InputRepeater/InputRepeaterProvider.tsx'

export default function useInputRepeater() {
  const context = useContext(InputRepeaterContext)

  if (context === null) {
    throw new Error(
      'useInputRepeater() must be used within a InputRepeaterProvider.',
    )
  }

  return context
}
