import './AlertsDashboard.css'

import Container from '@/components/AlertsDashboard/Container'

import type { Alerts } from './types'

export function AlertsDashboard({ items }: { items: Alerts }) {
  return (
    <div className="alerts-dashboard">
      <Container items={items} />
    </div>
  )
}
