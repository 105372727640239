import './index.css'

import { Sym } from '@edclass/fe-ui'
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
} from '@material-tailwind/react'
import clsx from 'clsx'
import { CSSProperties, MouseEvent, useEffect, useState } from 'react'

import { useUpload } from '@/providers/UploadProvider/hooks.ts'
import { FileItem, UploadStatus } from '@/providers/UploadProvider/types.ts'

interface UploadPanelProps {
  className?: string
  style?: CSSProperties
}

function UploadPanelItem({ file }: { file: FileItem }) {
  const [showCancel, setShowCancel] = useState(false)

  const finishedUpload = [
    UploadStatus.Uploaded,
    UploadStatus.Aborted,
    UploadStatus.Error,
  ].includes(file.status)

  const handleToggleCancelBtn = () => {
    if (file.status === UploadStatus.Waiting) {
      setShowCancel((prev) => !prev)
    }
  }

  const CancelButton = () => (
    <button
      title="Cancel Upload"
      id={`${file.id}-abort`}
      data-id="abort"
      onClick={() => {
        file.controller?.abort()
      }}
    >
      <Sym className="text-red-500">cancel</Sym>
    </button>
  )

  return (
    <div
      className="ed-upload-panel-file"
      onMouseEnter={handleToggleCancelBtn}
      onMouseLeave={handleToggleCancelBtn}
    >
      <div className="truncate w-full h-full flex items-center gap-3">
        <div className={clsx('truncate', !finishedUpload && 'opacity-40')}>
          {file.file.name}
        </div>

        {file.status === UploadStatus.Aborted && (
          <div className="text-[10px] text-light">{file.status.toString()}</div>
        )}
      </div>

      <div className="flex-shrink-0 h-full" title={file.status.toString()}>
        {file.status === UploadStatus.Waiting && (
          <div className="border-2 w-5 h-5 rounded-full">
            {showCancel && <CancelButton />}
          </div>
        )}

        {file.status === UploadStatus.Uploading && (
          <div className="flex-c-2">
            <CancelButton />
            <Spinner />
          </div>
        )}

        {file.status === UploadStatus.Uploaded && (
          <Sym className="text-blue-500">check_circle</Sym>
        )}

        {file.status === UploadStatus.Error && (
          <Sym className="text-red-500">cancel</Sym>
        )}

        {file.status === UploadStatus.Aborted && (
          <Sym className="text-amber-500">block</Sym>
        )}
      </div>
    </div>
  )
}
export default function UploadPanel({ className, style }: UploadPanelProps) {
  const { files, handleReset, status, timeEstimation } = useUpload()
  const entries = Object.entries(files)
  const autoShow = entries.length > 0

  const [forcedClose, setForcedClose] = useState(false)
  const [showWarningCloseModal, setShowWarningCloseModal] = useState(false)
  const [open, setOpen] = useState(true)

  const uploadIncomplete = entries.some(
    ([, file]) =>
      file.status === UploadStatus.Waiting ||
      file.status === UploadStatus.Uploading,
  )

  const handleForceClose = () => {
    handleReset()
    setForcedClose(true)
    setShowWarningCloseModal(false)
  }

  const handleCloseUploadBar = (e: MouseEvent) => {
    e.stopPropagation()

    if (uploadIncomplete) {
      setShowWarningCloseModal(true)
    } else {
      handleForceClose()
    }
  }

  const handleCloseWarningModal = () => {
    setShowWarningCloseModal(false)
  }

  useEffect(() => {
    if (uploadIncomplete) {
      setForcedClose(false)
    }
  }, [uploadIncomplete])

  if (!autoShow || forcedClose) return null

  return (
    <>
      <div
        id="ed-upload-panel"
        className={clsx('ed-upload-panel', className)}
        style={style}
      >
        <div
          onClick={() => setOpen(!open)}
          className="ed-upload-panel-header items-center"
        >
          <div className="w-full">Upload</div>
          <div className="flex-shrink-0 flex-c-2">
            <span
              role="button"
              onClick={() => setOpen(!open)}
              className="ed-upload-panel-header-button"
              title="Hide Upload Panel"
            >
              {open ? (
                <Sym>keyboard_arrow_down</Sym>
              ) : (
                <Sym>keyboard_arrow_down</Sym>
              )}
            </span>

            <span
              role="button"
              onClick={handleCloseUploadBar}
              className="ed-upload-panel-header-button"
              title="Close Upload Panel"
            >
              <Sym>close</Sym>
            </span>
          </div>
        </div>

        {open && (
          <div className="ed-upload-panel-body">
            {entries.length < 1 ? (
              <div className="p-4">No Upload Queue</div>
            ) : (
              <>
                {status === 'started' && (
                  <div className="px-4 py-2 text-xs bg-slate-200 dark:bg-slate-800 flex items-center justify-between">
                    <div className="text-light">{timeEstimation}</div>

                    <span
                      role="button"
                      className="font-medium"
                      onClick={handleCloseUploadBar}
                    >
                      Cancel
                    </span>
                  </div>
                )}

                {entries.map(([id, file]) => {
                  return <UploadPanelItem key={id} file={file} />
                })}
              </>
            )}
          </div>
        )}
      </div>

      {showWarningCloseModal && (
        <Dialog open={true} handler={handleCloseWarningModal}>
          <DialogHeader onClick={handleCloseWarningModal}>
            Cancel Upload
          </DialogHeader>
          <DialogBody className="modal-sm">
            <div className="py-2">
              Your upload is not complete. Would you like to cancel the upload?
            </div>
          </DialogBody>
          <DialogFooter className="flex justify-between">
            <Button onClick={handleForceClose}>Cancel Upload</Button>
            <Button onClick={handleCloseWarningModal}>Continue Upload</Button>
          </DialogFooter>
        </Dialog>
      )}
    </>
  )
}
