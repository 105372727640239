import { useEffect, useState } from 'react'

import { TimetableSheet } from '@/components/TimetableSheet'
import TimetableSubjectAction from '@/components/TimetableSheet/TimetableSubjectAction.tsx'
import TimetableSubjectItem from '@/components/TimetableSheet/TimetableSubjectItem.tsx'
import { EMPTY_TIMELINE_CATEGORIES } from '@/constants/constants.ts'
import { timetableClassName } from '@/helpers/timetable.ts'
import useAppContext from '@/hooks/useAppProvider.ts'
import useMountStatus from '@/hooks/useMountStatus.ts'

export default function TeacherTimetablePage() {
  const { setTitle } = useAppContext()
  const mounted = useMountStatus(50)

  useEffect(() => {
    if (mounted) {
      setTitle('Teacher Timetable')
    }
  }, [setTitle, mounted])

  const [items, setItems] = useState(EMPTY_TIMELINE_CATEGORIES)

  return (
    <div id="teacher-timetable">
      <TimetableSheet
        id="tt-2"
        onChange={(items) => {
          setItems(items)
        }}
        //isDisabled={(item) => !item.subject}
        itemClassName={timetableClassName}
        items={items}
        renderItem={(item) => <TimetableSubjectItem item={item} />}
        renderActions={(item) => <TimetableSubjectAction item={item} />}
      />
    </div>
  )
}
