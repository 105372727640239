import { useContext } from 'react'

import { PopoverContext } from '@/components/Popover/PopoverContext.tsx'

export default function usePopover() {
  const context = useContext(PopoverContext)

  if (!context) {
    throw new Error(
      'usePopover() must be used within a Popover. It happens when you use PopoverHandler or PopoverContent components outside the Popover component.',
    )
  }

  return context
}
