import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { QueryKeys } from '@/constants/query.ts'
import useIsQueryLoading from '@/hooks/query/useIsQueryLoading.ts'
import { useSearchAndParams } from '@/hooks/useSearchAndParams.ts'
import { getFsService } from '@/services/fs.ts'
import { Params } from '@/services/params.ts'

export function splitFsParam(params: ReqParams) {
  const {
    lessons,
    topics,
    subjects,
    pathways,
    exBoards,
    questionItems,
    dndItems,
    puzzleItems,
    doodleItems,
    keywords,
    custom,
    filterOr,
    ...rest
  } = params as Record<string, string>

  const fsTag: FsTagReqBody = {
    lessons: lessons ? [lessons] : undefined,
    subjects: subjects ? [subjects] : undefined,
    topics: topics ? [topics] : undefined,
    pathways: pathways ? [pathways] : undefined,
    exBoards: exBoards ? [exBoards] : undefined,
    questionItems: questionItems ? [questionItems] : undefined,
    dndItems: dndItems ? [dndItems] : undefined,
    puzzleItems: puzzleItems ? [puzzleItems] : undefined,
    doodleItems: doodleItems ? [doodleItems] : undefined,
    keywords: keywords ? keywords.split(',').map((k) => k.trim()) : undefined,
    custom: custom ? custom.split(',').map((c) => c.trim()) : undefined,
  }

  return {
    tags: fsTag,
    or: Boolean(filterOr),
    rest: rest as ReqParams,
  }
}

export default function useFiles(initialParams?: ReqParams) {
  const { params, ...restParams } = useSearchAndParams(initialParams || {})
  const query = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [QueryKeys.LsFile, params],
    queryFn: async ({ queryKey: [, params] }) => {
      const { tags, or, rest } = splitFsParam(params as ReqParams)
      return getFsService().listFiles(Params.fromObject(rest), {
        tags,
        or,
      })
    },
    enabled: true,
  })

  const loading = useIsQueryLoading(query, restParams)

  return {
    ...query,
    ...restParams,
    params,
    loading,
  }
}
