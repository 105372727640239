import { useQuery } from '@tanstack/react-query'

import { QueryKeys } from '@/constants/query.ts'
import { getAuthService } from '@/services/auth.ts'

export default function useLoginCheck() {
  return useQuery({
    queryKey: [QueryKeys.LoginCheck],
    queryFn: async () => {
      return getAuthService().loginCheck()
    },
    // every 10 minute
    refetchInterval: 600 * 1000,
    refetchOnReconnect: true,
    refetchOnMount: true,
    //refetchOnWindowFocus: true,
  })
}
