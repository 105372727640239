import './Category.css'

import { SortableContext } from '@dnd-kit/sortable'

import AlertCard from '@/components/AlertsDashboard/AlertCard'

import type { Alert, AlertColumn } from './types'

type CategoryProps = {
  items: Alert[]
} & Pick<AlertColumn, 'label' | 'type'>

export default function Category({ label, type, items }: CategoryProps) {
  return (
    <div className="alerts-dashboard__category-item">
      <div className="alerts-dashboard__category-item__header max-h-[48px]">
        <h2>{label}</h2>
      </div>
      <div className="alerts-dashboard__category-item__body">
        <div className="alerts-dashboard__category-item__type">
          <h3>{type}</h3>
        </div>
        <div className="alerts-dashboard__category-item__list">
          {items && (
            <SortableContext items={items}>
              {items.map((alert) => (
                <AlertCard key={alert.id} alert={alert} />
              ))}
            </SortableContext>
          )}
        </div>
      </div>
    </div>
  )
}
