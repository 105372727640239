import { InputProps, SwitchProps } from '@material-tailwind/react'
import { ComponentPropsWithoutRef, createContext } from 'react'

import { EdColorPickerProps } from '@/components/ColorPicker/EdColorPicker.tsx'
import { IconPickerProps } from '@/components/IconPicker'
import { NumInputProps } from '@/components/Input/NumInput.tsx'
import { MultiSelectProps } from '@/components/MultiSelect'
import { SelectWithSearchProps } from '@/components/SelectWithSearch'

interface BaseRepeaterFieldProps<V extends BaseRepeaterValue, P> {
  field: keyof V
  type:
    | 'input'
    | 'number'
    | 'select'
    | 'icon'
    | 'color'
    | 'multiselect'
    | 'bool'
  props?: P
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parseValue?: (opt: any) => string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showCb?: (values: any) => boolean
}

interface SelectRepeaterFieldProps<V extends BaseRepeaterValue>
  extends BaseRepeaterFieldProps<
    V,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Omit<SelectWithSearchProps<any>, 'ref' | 'onChange'>
  > {
  type: 'select'
}

interface MultiSelectRepeaterFieldProps<V extends BaseRepeaterValue>
  extends BaseRepeaterFieldProps<
    V,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Omit<MultiSelectProps<any>, 'ref' | 'onChange'>
  > {
  type: 'multiselect'
}

interface InputRepeaterFieldProps<V extends BaseRepeaterValue>
  extends BaseRepeaterFieldProps<
    V,
    Omit<InputProps, 'ref' | 'onChange'> & {
      help?: string
      helpProps?: ComponentPropsWithoutRef<'div'>
    }
  > {
  type: 'input'
}

interface NumRepeaterFieldProps<V extends BaseRepeaterValue>
  extends BaseRepeaterFieldProps<
    V,
    Omit<NumInputProps, 'ref' | 'onChange'> & {
      help?: string
      helpProps?: ComponentPropsWithoutRef<'div'>
    }
  > {
  type: 'number'
}

interface BoolRepeaterFieldProps<V extends BaseRepeaterValue>
  extends BaseRepeaterFieldProps<V, Omit<SwitchProps, 'ref' | 'onChange'>> {
  type: 'bool'
}

interface IconRepeaterFieldProps<V extends BaseRepeaterValue>
  extends BaseRepeaterFieldProps<V, IconPickerProps> {
  type: 'icon'
}

interface ColorRepeaterFieldProps<V extends BaseRepeaterValue>
  extends BaseRepeaterFieldProps<V, EdColorPickerProps> {
  type: 'color'
}

export type RepeaterFieldProps<V extends BaseRepeaterValue> =
  | InputRepeaterFieldProps<V>
  | NumRepeaterFieldProps<V>
  | SelectRepeaterFieldProps<V>
  | IconRepeaterFieldProps<V>
  | ColorRepeaterFieldProps<V>
  | MultiSelectRepeaterFieldProps<V>
  | BoolRepeaterFieldProps<V>

export type BaseRepeaterValue = { [p: string]: unknown } & WithId

export interface RepeaterContextValue<V extends BaseRepeaterValue> {
  id: string
  value: V[]
  updateValue: (v: V) => void
  removeValue: (id: string) => void
  addValue: (v: V) => void
  onChange: (value: V[], index: number | null, add: boolean) => void
  fields: RepeaterFieldProps<V>[]
  grid?: number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RepeaterContext = createContext<RepeaterContextValue<any> | null>(
  null,
)

RepeaterContext.displayName = 'RepeaterContext'
