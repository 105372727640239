import { Input } from '@material-tailwind/react'
import { useCallback, useState } from 'react'
import { HexAlphaColorPicker } from 'react-colorful'
import tinycolor from 'tinycolor2'

import { BASIC_COLORS } from '@/constants/color.ts'

export type EdColorPickerProps = {
  value?: string
  onChange: (next: string) => void
}

export default function EdColorPicker({
  value = '#00000000',
  onChange,
}: EdColorPickerProps) {
  //const [selfColor, setSelfColor] = useState(`#${tinycolor(value).toHex8()}`)
  const [inputColor, setInputColor] = useState(value)

  const setColor = useCallback(
    (hex: string) => {
      setInputColor(hex)
      if (/^#[0-9A-Fa-f]{6,8}$/i.test(hex)) {
        const newColor = tinycolor(hex)
        onChange(`#${newColor.toHex8()}`)
      }
    },
    [onChange],
  )

  return (
    <div className="flex flex-col gap-4">
      <Input
        label="Hex"
        value={inputColor}
        onKeyDown={(e) => {
          if (!/[#0-9A-Fa-f]$/i.test(e.key)) {
            e.preventDefault()
            e.stopPropagation()
            return false
          }
        }}
        onChange={(e) => {
          setColor(e.target.value)
        }}
      />
      <HexAlphaColorPicker
        aria-label="hex-alpha-color-picker"
        className="ed-cp"
        color={value}
        onChange={(value) => {
          setColor(value)
        }}
        data-testid="hex-alpha-color-picker"
      />
      <div className="color-picker-basic-color">
        {BASIC_COLORS.map((basicColor) => (
          <button
            aria-label={basicColor}
            data-testid="color-picker-basic-color-button"
            className={
              basicColor.toLowerCase() === value.toLowerCase() ? ' active' : ''
            }
            key={basicColor}
            style={{ backgroundColor: basicColor }}
            onClick={() => {
              onChange(basicColor)
            }}
          />
        ))}
      </div>
    </div>
  )
}
