import './AlertCard.css'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Sym } from '@edclass/fe-ui'
import { Button } from '@material-tailwind/react'
import clsx from 'clsx'

import { Alert } from './types'

export interface AlertsCategoryItemProps {
  alert: Alert
  className?: string
}

export default function AlertCard({
  alert,
  className,
}: AlertsCategoryItemProps) {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: alert.id,
    data: {
      type: 'alert',
      alert,
    },
  })

  const dragStyle = {
    transition,
    transform: CSS.Transform.toString(transform),
  }

  function openAlertHandler() {
    //console.log('Open alert')
  }

  return (
    <div
      onClick={openAlertHandler}
      ref={setNodeRef}
      style={dragStyle}
      {...attributes}
      {...listeners}
      className={clsx(
        'alerts-category__item',
        'alerts-category__item--default',
        { 'alerts-category__item--dragging': isDragging },
        { '!opacity-100': !isDragging },
        { 'alerts-category__item--closed': alert.type === 'closed_alerts' },
        className,
      )}
    >
      <div className="alerts-category__item__icon">
        <img
          src="https://randomuser.me/api/portraits/women/56.jpg"
          alt="Avatar"
        />
      </div>
      <div className="alerts-category__item__name">
        <p className="text-white font-bold">{alert.name}</p>
        <p className="text-black text-sm font-bold">Time: 09:00.00</p>
      </div>
      <div className="alerts-category__item__cta">
        <Button placeholder="respond">
          <Sym className="!text-[32px]">chat</Sym>
        </Button>
      </div>
    </div>
  )
}
