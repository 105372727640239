import { ACCEPT_AUDIOS, ACCEPT_IMAGES, ACCEPT_VIDEOS } from '@edclass/fe-common'
import PQueue from 'p-queue'

export const checkUnsupportedFiles = (files: File[]) => {
  const unsupportedFiles: string[] = []

  Array.from(files).forEach((file) => {
    const fileType = file.type
    const isAccepted = [ACCEPT_AUDIOS, ACCEPT_IMAGES, ACCEPT_VIDEOS].some(
      (acceptedType) => {
        if (acceptedType.startsWith('.')) {
          // Check for file extension
          return file.name.toLowerCase().endsWith(acceptedType.toLowerCase())
        } else if (acceptedType.includes('/*')) {
          // Check for wildcard MIME type
          const wildcardMimeType = acceptedType.split('/')[0]
          return fileType.startsWith(wildcardMimeType)
        } else {
          // Check for exact MIME type
          return fileType === acceptedType
        }
      },
    )

    if (!isAccepted) {
      unsupportedFiles.push(file.name)
    }
  })

  return unsupportedFiles
}

export const uploadQueue = new PQueue({
  concurrency: 2,
  autoStart: true,
})
