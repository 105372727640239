import './InputRepeater.css'

import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import { Sym } from '@edclass/fe-ui'
import { IconButton, Input } from '@material-tailwind/react'
import clsx from 'clsx'
import { useCallback } from 'react'
import { ulid } from 'ulid'

import useInputRepeater from '@/components/InputRepeater/hooks.ts'
import {
  InputRepeaterProvider,
  InputRepeaterProviderProps,
} from '@/components/InputRepeater/InputRepeaterProvider.tsx'
import SortableItem from '@/components/SortableItem.tsx'
import useSortableProps from '@/hooks/useSortableProps.ts'

function InputRepeaterField({
  fieldId,
  listeners,
}: {
  fieldId: string
  listeners?: SyntheticListenerMap
}) {
  const { id, value, updateValue, removeValue, label, fieldKeys } =
    useInputRepeater()
  const index = value.findIndex((v) => v.id === fieldId)
  return (
    <div className="flex-c-2">
      <div data-testid="input-repeater-fieldset" className="flex-c-2 w-full">
        {fieldKeys.map((fk) => {
          return (
            <Input
              autoFocus
              data-testid="input-repeater-field"
              key={`${fk}-${index}`}
              id={`${id}-${index}-${fk}`}
              value={value[index]?.[fk]?.toString() ?? ''}
              label={`${label[fk]} ${index + 1}`}
              onInput={(e) => {
                e.stopPropagation()
                updateValue(
                  {
                    ...value[index],
                    [fk]: e.currentTarget.value.trim(),
                  },
                  index,
                )
              }}
            />
          )
        })}
      </div>
      <IconButton
        data-testid="input-repeater-delete"
        className="flex-shrink-0"
        color="red"
        onClick={() => {
          removeValue(index)
        }}
      >
        <Sym className="!text-[20px]">delete</Sym>
      </IconButton>
      <IconButton
        data-testid="input-repeater-sort"
        className="flex-shrink-0"
        color="white"
        {...listeners}
      >
        <Sym className="!text-[20px]">swap_vert</Sym>
      </IconButton>
    </div>
  )
}

function InputRepeaterInner() {
  const { value, addValue, onChange, grid } = useInputRepeater()
  const sortableProps = useSortableProps()
  const handleSort = useCallback(
    ({ over, active }: DragEndEvent) => {
      if (over && active.id !== over?.id) {
        const activeIndex = value.findIndex(({ id }) => id === active.id)
        const overIndex = value.findIndex(({ id }) => id === over.id)
        onChange(arrayMove(value, activeIndex, overIndex))
      }
      //setActive(null)
    },
    [value, onChange],
  )
  return (
    <div data-testid="input-repeater" className="flex flex-col gap-4">
      {value.length > 0 && (
        <div
          style={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            '--grid-cols': grid,
          }}
          className={clsx(
            grid && grid > 1
              ? 'input-repeater-grid grid gap-4'
              : 'flex flex-col gap-4',
          )}
        >
          <DndContext {...sortableProps} onDragEnd={handleSort}>
            <SortableContext items={value}>
              {value.map((v) => {
                return (
                  <SortableItem key={v.id} id={v.id} sortButton>
                    {(listeners) => (
                      <InputRepeaterField
                        fieldId={v.id}
                        listeners={listeners}
                      />
                    )}
                  </SortableItem>
                )
              })}
            </SortableContext>
          </DndContext>
        </div>
      )}
      <IconButton
        data-testid="input-repeater-add"
        onClick={() => {
          addValue({
            id: ulid(),
            //value: '',
          })
        }}
      >
        <Sym className="!text-[20px]">add</Sym>
      </IconButton>
    </div>
  )
}

type InputRepeaterProps<T extends ValueWithId> =
  InputRepeaterProviderProps<T> & {
    initialValue: T[]
  }

export default function InputRepeater<T extends ValueWithId>(
  props: InputRepeaterProps<T>,
) {
  return (
    <InputRepeaterProvider {...props}>
      <InputRepeaterInner />
    </InputRepeaterProvider>
  )
}
