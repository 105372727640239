import { MouseEvent, RefObject, useContext } from 'react'

import {
  MultiSelectContext,
  MultiSelectContextValue,
} from './MultiSelectContext.tsx'

export function useMultiSelect<T>() {
  const context = useContext(MultiSelectContext)

  if (context === null) {
    throw new Error(
      'useMultiSelect() must be used within a Select. It happens when you use SelectOption component outside the Select component.',
    )
  }

  return context as MultiSelectContextValue<T>
}

export function fixClick(
  refProps: Record<string, unknown>,
  inputRef: RefObject<HTMLInputElement>,
) {
  const oldClick = refProps.onClick
  refProps.onClick = (e: MouseEvent) => {
    inputRef.current?.focus()
    const tgt = e.target as HTMLDivElement
    if (
      (['svg', 'path'].includes(tgt.tagName.toLowerCase()) &&
        !tgt.closest('.option-chip, .clear')) ||
      !['svg', 'path'].includes(tgt.tagName)
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      oldClick?.(e)
    }
  }
}
