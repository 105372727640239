import { useQueryClient } from '@tanstack/react-query'
import { isNumber } from 'lodash'
import {
  ChangeEvent,
  ReactNode,
  startTransition,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { ulid } from 'ulid'

import UploadErrorDialog from '@/components/UploadErrorDialog'
import { AdminChildrenPath } from '@/constants/constants.ts'
import { QueryKeys } from '@/constants/query.ts'
import { PathKind } from '@/helpers/path.ts'
import { UploadContext } from '@/providers/UploadProvider/context.tsx'
import uploadReducer from '@/providers/UploadProvider/reducer.ts'
import {
  FileItem,
  UploadActionType,
  UploadProgressStatus,
  UploadState,
  UploadStatus,
} from '@/providers/UploadProvider/types.ts'
import {
  checkUnsupportedFiles,
  uploadQueue,
} from '@/providers/UploadProvider/utils.ts'
import { getFsService } from '@/services/fs.ts'

const initialValue: UploadState = {
  files: {},
}
export default function UploadProvider({ children }: { children?: ReactNode }) {
  //const { user } = useAuthContext()
  const queryClient = useQueryClient()
  const [state, dispatch] = useReducer(uploadReducer, initialValue)
  const [timeEstimation, setTimeEstimation] = useState('')
  const [status, setStatus] = useState<UploadProgressStatus>(
    UploadProgressStatus.Idle,
  )
  const [totalBytes, setTotalBytes] = useState(0)
  const [timeStarted, setTimeStarted] = useState(Date.now())
  const [uploadError, setUploadError] = useState<ReactNode>('')
  const [errorTitle, setErrorTitle] = useState('')

  const [uploadedBytesMap, setUploadedBytesMap] = useState<
    Record<string, number>
  >({})
  const uploadedBytes = Object.values(uploadedBytesMap).reduce(
    (acc, bytes) => acc + bytes,
    0,
  )

  const addTask = useCallback((f: FileItem) => {
    const controller = new AbortController()

    dispatch({
      type: UploadActionType.Add,
      item: f,
      itemId: f.id,
      controller,
    })

    const abortCb = () => {
      dispatch({
        type: UploadActionType.Abort,
        itemId: f.id,
      })
      controller.signal.removeEventListener('abort', abortCb)
    }

    controller.signal.addEventListener('abort', abortCb)

    return uploadQueue.add(() => {
      if (f.status === UploadStatus.Aborted || controller.signal.aborted) {
        return Promise.resolve()
      }

      dispatch({
        type: UploadActionType.Uploading,
        itemId: f.id,
      })

      const req = new Promise<unknown>((resolve, reject) => {
        const formData = new FormData()
        formData.append('files', f.file)

        getFsService()
          .upload(f.file, undefined, {
            signal: controller.signal,
            onUploadProgress: (e) => {
              setUploadedBytesMap((prev) => ({ ...prev, [f.id]: e.loaded }))
            },
          })
          .then((res) => {
            resolve(res)
          })
          .catch(reject)
      })

      return req
        .then(() => {
          dispatch({
            type: UploadActionType.Uploaded,
            itemId: f.id,
          })
        })
        .catch((e) => {
          if (e.message !== 'aborted') {
            // TODO: print error here
            dispatch({
              type: UploadActionType.Error,
              itemId: f.id,
              error: e,
            })
          }
        })
    })
  }, [])

  const queueUpload = useCallback(
    async (files: File[]) => {
      setStatus(UploadProgressStatus.Started)

      Promise.all(
        files.map((file) =>
          addTask({
            file,
            id: ulid(),
            error: null,
            status: UploadStatus.Waiting,
            src: window.URL.createObjectURL(file),
          }),
        ),
      )
        .then(() => {
          // revalidate storage query after successful upload
          queryClient
            .invalidateQueries({
              queryKey: [QueryKeys.LsStorage],
            })
            .catch(console.error)

          // revalidate storage stats query after successful upload
          // only on storage path
          if (
            window.location.pathname.endsWith(
              `${PathKind.Admin}/${AdminChildrenPath.STORAGE}`,
            )
          ) {
            queryClient
              .invalidateQueries({
                queryKey: [QueryKeys.StorageMetric],
              })
              .catch(console.error)
          }
        })
        .catch((e) => {
          console.log('queue error', e)
        })
        .finally(() => {
          setStatus(UploadProgressStatus.Finished)
        })
    },
    [queryClient, addTask],
  )

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length) {
        const arrFiles = Array.from(e.target.files)

        // Check if the file type is supported
        const unsupportedFiles = checkUnsupportedFiles(arrFiles)

        if (unsupportedFiles.length > 0) {
          const multiple = unsupportedFiles.length > 1
          setErrorTitle('Unsupported File Type')
          setUploadError(
            <div>
              <div>
                The following file{multiple ? 's' : ''}{' '}
                {multiple ? 'have' : 'has'} unsupported format
                {multiple ? 's' : ''}:{' '}
              </div>
              <ul className="list-disc pl-4 mt-1">
                {unsupportedFiles.map((file) => (
                  <li key={file} className="font-semibold">
                    {file}
                  </li>
                ))}
              </ul>
              <div className="mt-4">Please try uploading another file.</div>
            </div>,
          )
          return
        }

        startTransition(() => {
          setTimeEstimation('')
          setTimeStarted(Date.now())
          setTotalBytes(arrFiles.reduce((acc, file) => acc + file.size, 0))
        })

        queueUpload(arrFiles).catch(console.error)
      }
    },
    [queueUpload],
  )

  const handleReset = useCallback(() => {
    startTransition(() => {
      setTimeEstimation('')
      setTotalBytes(0)
      setUploadedBytesMap({})
      setStatus(UploadProgressStatus.Idle)
    })

    Object.values(state.files).forEach((f) => {
      if (f.controller) {
        f.controller.abort()
      }
    })
    dispatch({
      type: UploadActionType.Reset,
    })
  }, [state.files])

  const handleCloseErrorModal = useCallback(() => {
    setErrorTitle('')
    setUploadError('')
  }, [])

  useEffect(() => {
    if (status === UploadProgressStatus.Started) {
      const calculateTime = () => {
        const currentTime = Date.now()
        const timeElapsed = currentTime - timeStarted
        const uploadSpeed = uploadedBytes / timeElapsed // (ms)
        const remainingBytes = totalBytes - uploadedBytes
        const remainingTime = remainingBytes / uploadSpeed // (ms)

        if (uploadedBytes === 0) {
          return 'Starting upload...'
        }

        if (isNumber(remainingTime)) {
          if (remainingTime < 1000) {
            return 'Finishing upload...'
          }

          // Convert ms to human-readable format
          const seconds = Math.floor((remainingTime / 1000) % 60)
          const minutes = Math.floor((remainingTime / (1000 * 60)) % 60)
          const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24)

          let timeString = ''

          if (hours > 0) {
            timeString = `${hours} hour${hours > 1 ? 's' : ''} left...`
          } else if (minutes > 0) {
            timeString = `${minutes} minute${minutes > 1 ? 's' : ''} left...`
          } else if (seconds > 0) {
            timeString = 'Less than 1 minute left...'
          }

          return timeString
        }

        return ''
      }
      const timeRemaining = calculateTime()
      setTimeEstimation(timeRemaining)
    }
  }, [status, timeStarted, totalBytes, uploadedBytes])

  return (
    <UploadContext.Provider
      value={{
        ...state,
        dispatch,
        onChange,
        handleReset,
        status,
        timeEstimation,
        queueUpload,
      }}
    >
      {children}
      <UploadErrorDialog
        show={!!uploadError}
        error={uploadError}
        errorTitle={errorTitle}
        onClose={handleCloseErrorModal}
        onRetry={onChange}
      />
    </UploadContext.Provider>
  )
}
