import { ACCEPT_MEDIA } from '@edclass/fe-common'
import { Sym } from '@edclass/fe-ui'
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { ChangeEvent, ReactNode } from 'react'

interface UploadErrorDialogProps {
  show: boolean
  errorTitle?: string
  error?: ReactNode
  onClose: () => void
  onRetry: (e: ChangeEvent<HTMLInputElement>) => void
}

export default function UploadErrorDialog({
  show,
  errorTitle,
  error,
  onClose,
  onRetry,
}: UploadErrorDialogProps) {
  return (
    <Dialog open={show} handler={onClose}>
      <DialogHeader onClick={onClose}>
        {errorTitle ?? 'Upload Error'}
      </DialogHeader>
      <DialogBody className="modal-sm">
        <div className="py-2">
          {error ??
            'The selected file type is not allowed, please try with another file.'}
        </div>
      </DialogBody>
      <DialogFooter className="flex justify-between">
        <Button onClick={onClose}>Close</Button>
        <label className="cursor-pointer relative">
          <input
            className="sr-only"
            type="file"
            accept={ACCEPT_MEDIA}
            multiple
            onChange={(e) => {
              onRetry(e)
              // reset element ref to enable re-upload after upload error
              e.target.value = ''
            }}
          />
          <Button className="pointer-events-none">
            <Sym>repeat</Sym>
            <span>Retry Upload</span>
          </Button>
        </label>
      </DialogFooter>
    </Dialog>
  )
}
