import { useState } from 'react'

import SidebarMenu from '@/components/Sidebar/SidebarMenu.tsx'
import SymbolNav from '@/components/SymbolNav'
import { EDIcon, TEACHER_ROUTE } from '@/constants/constants.ts'
import { PathKind } from '@/helpers/path.ts'
import useAppContext from '@/hooks/useAppProvider.ts'

export default function TeacherSidebar() {
  const { sidebarCollapsed } = useAppContext()
  const [openedDd, setOpenedDd] = useState({})

  return (
    <>
      <SidebarMenu
        openedDd={openedDd}
        setOpenedDd={setOpenedDd}
        collapsed={sidebarCollapsed}
        className="teacher-nav"
        depth={0}
        before={
          <SymbolNav end pathKind={PathKind.Teacher} icon={EDIcon.Dashboard}>
            Dashboard
          </SymbolNav>
        }
        items={TEACHER_ROUTE}
      />
      {/*
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.REPORTS}`}
        icon={EDIcon.Stat}
      >
        Reports and Tracking
      </SymbolNav>
        <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.TIMETABLE}`}
        icon={EDIcon.Timeline}
      >
        Timetable
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.SAFE_GUARDING}`}
        icon={EDIcon.Safeguarding}
      >
        Safe Guarding
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.ALERTS}`}
        icon={EDIcon.Alert}
      >
        Alerts
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.HOME_VISITS}`}
        icon={EDIcon.HomeVisit}
      >
        Home Visit
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.STUDENT_PROFILE}`}
        icon={EDIcon.User}
      >
        Student Profile
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.LEARNING_PATHWAYS}`}
        icon={EDIcon.Pathway2}
      >
        Learning Pathways
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.ATTENDANCE}`}
        icon={EDIcon.Attendance}
      >
        Attendance
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.BEHAVIOUR}`}
        icon={EDIcon.Behavior}
      >
        Behaviour
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.SUBJECTS}`}
        icon={EDIcon.Subject}
      >
        Subjects
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.REVIEWS}`}
        icon={EDIcon.Review}
      >
        Reviews
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.INSPECTION_REPORT}`}
        icon={EDIcon.Score}
      >
        Inspection Report
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.PERMISSIONS}`}
        icon={EDIcon.Permission}
      >
        Permissions
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.SCHOOL_SETUP}`}
        icon={EDIcon.Setup}
      >
        School
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.STAFF}`}
        icon={EDIcon.Staff}
      >
        Staff
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.STUDENTS_LIVE}`}
        icon={EDIcon.StudentLive}
      >
        Students Live
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.NOTIFICATION}`}
        icon={EDIcon.Notification}
      >
        Notifications
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.REWARDS}`}
        icon={EDIcon.Reward}
      >
        Rewards
      </SymbolNav>
      <SymbolNav
        to={`${PathKind.Teacher}/${TeacherChildrenPath.SUBSCRIPTION}`}
        icon={EDIcon.Subscription}
      >
        Subscription
      </SymbolNav>
         */}
    </>
  )
}
