import { addFlag, hasFlag } from '@edclass/fe-common'
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  Typography,
} from '@material-tailwind/react'
import { useCallback, useState } from 'react'

import { getDisplayMedia } from '@/helpers/media.ts'
import { RecordMediaFlag } from '@/helpers/safeguarding.ts'

export default function EnableScreenCaptureModal({
  flags,
  onChange,
}: {
  // current saved flags
  flags: RecordMediaFlag
  onChange: (nextFlags: RecordMediaFlag) => void
}) {
  const [loading, setLoading] = useState(false)

  const handleOnClick = useCallback(() => {
    setLoading(true)
    getDisplayMedia().then((screenStream) => {
      // Get the video track from the stream
      const videoTrack = screenStream.getVideoTracks()[0]
      const settings = videoTrack.getSettings()

      console.log('video track settings', settings)
      // Display what kind of display surface was captured
      if (settings.displaySurface) {
        switch (settings.displaySurface) {
          case 'monitor':
            console.log('Entire screen (monitor) was selected.')
            onChange(addFlag(flags, RecordMediaFlag.Screen))
            break
          case 'window':
            console.log('Window was selected.')
            break
          case 'browser':
            console.log('Browser tab was selected.')
            break
          case 'application':
            console.log('An application surface was selected.')
            break
        }
      } else {
        onChange(addFlag(flags, RecordMediaFlag.Screen))
      }
      setLoading(false)
    })
  }, [flags, onChange])

  return !hasFlag(flags, RecordMediaFlag.Screen) ? (
    <Dialog
      size="sm"
      open
      handler={() => {
        //
      }}
    >
      <DialogHeader>Required Action</DialogHeader>
      <DialogBody divider>
        <div className="px-8 py-4 flex flex-col gap-8">
          <Typography className="text-center" variant="lead">
            In order to use the app we need access to your screen
          </Typography>
          <div className="flex justify-center">
            <Button loading={loading} onClick={handleOnClick}>
              Enable Screen Capture
            </Button>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  ) : null
}
