import { useSortable } from '@dnd-kit/sortable'

import {
  useTimetableSheetContext,
  WithUniqueId,
} from '@/components/TimetableSheet/TimetableSheetContext.ts'
import TimetableSheetItem from '@/components/TimetableSheet/TimetableSheetItem.tsx'
import useMountStatus from '@/hooks/useMountStatus.ts'

interface TimetableSheetSortableItemProps<T extends WithUniqueId> {
  containerId: string
  item: T
  disabled?: boolean
  index: number
}

export function TimetableSheetSortableItem<T extends WithUniqueId>({
  containerId,
  item,
  index,
}: TimetableSheetSortableItemProps<T>) {
  const {
    setNodeRef,
    //setActivatorNodeRef,
    listeners,
    isDragging,
    transform,
    transition,
  } = useSortable({
    id: item.id,
  })

  const { isDisabled } = useTimetableSheetContext()
  const mounted = useMountStatus()
  const mountedWhileDragging = isDragging && !mounted
  const disabled = isDisabled ? isDisabled(item, index) : false
  return (
    <TimetableSheetItem
      setNodeRef={disabled ? undefined : setNodeRef}
      disabled={disabled}
      index={index}
      item={item}
      dragging={isDragging}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      containerId={containerId}
    />
  )
}
