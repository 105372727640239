export enum UploadActionType {
  Add = 0,
  Error = 1,
  Uploading = 2,
  Uploaded = 3,
  Abort = 4,
  Reset = 5,
}

export enum UploadStatus {
  Waiting = 0,
  Uploading = 1,
  Uploaded = 2,
  Error = 3,
  Aborted = 4,
}

export enum UploadProgressStatus {
  Idle = 'idle',
  Started = 'started',
  Finished = 'finished',
}

export interface FileItem {
  file: File
  id: string
  src: string
  status: UploadStatus
  error: string | null
  controller?: AbortController
}

export interface UploadState {
  files: Record<string, FileItem>
}

export interface UploadAction {
  type: UploadActionType
  itemId?: string
  item?: FileItem
  error?: string
  controller?: AbortController
}
