import './Sidebar.css'

import { Sym } from '@edclass/fe-ui'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import Logo from '@/assets/logo.svg?react'
import { BASE_PATH } from '@/constants/constants.ts'
import useAppContext from '@/hooks/useAppProvider.ts'

interface BaseSidebarProps {
  className?: string
  navClassName?: string
  children?: ReactNode
}

export default function Sidebar({
  className,
  navClassName,
  children,
}: BaseSidebarProps) {
  const { sidebar, sidebarCollapsed, setSidebarCollapsed } = useAppContext()
  return (
    <div
      className={clsx('ed-sidebar', sidebarCollapsed && 'collapsed', className)}
    >
      <button
        className="ed-sidebar-collapsible"
        role="button"
        title="Collapse sidebar"
        aria-label="Collapse sidebar"
        onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
      >
        <Sym className="!text-[18px]">
          {sidebarCollapsed ? 'chevron_right' : 'chevron_left'}
        </Sym>
      </button>

      <div className="ed-sidebar-logo">
        <Link to={BASE_PATH ?? '/'} id="logo-container">
          <Logo width={sidebarCollapsed ? 60 : 95} />
        </Link>
      </div>
      <div className={clsx('ed-sidebar-nav', navClassName)}>
        <sidebar.Out />
        {children}
      </div>
    </div>
  )
}
