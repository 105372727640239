import {
  arrow,
  autoUpdate,
  flip,
  offset as fuiOffset,
  OffsetOptions,
  Placement,
  shift,
  useClick,
  useDismiss,
  UseDismissProps,
  useFloating,
  useId,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { useTheme } from '@material-tailwind/react'
import { animation } from '@material-tailwind/react/types/generic'
// utils
import merge from 'deepmerge'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { PopoverContent, PopoverContentProps } from './PopoverContent'
import { PopoverContextProvider } from './PopoverContext'
import { PopoverHandler, PopoverHandlerProps } from './PopoverHandler'
export interface PopoverProps {
  open?: boolean
  handler?: Dispatch<SetStateAction<boolean>>
  placement?: Placement
  offset?: OffsetOptions
  dismiss?: UseDismissProps
  animate?: animation
  children: ReactNode
  arrow?: boolean
}

const Popover = ({
  open,
  handler,
  placement,
  offset = 16,
  dismiss,
  animate,
  children,
  arrow: withArrow,
}: PopoverProps) => {
  // 1. init
  const { popover } = useTheme()
  const { defaultProps } = popover
  const [internalOpen, setInternalOpen] = useState(false)

  // 2. set default props
  open = open ?? internalOpen
  handler = handler ?? setInternalOpen
  placement = placement ?? defaultProps.placement
  offset = offset ?? defaultProps.offset
  dismiss = dismiss ?? defaultProps.dismiss
  animate = animate ?? defaultProps.animate

  // 4. set animation
  const animation = {
    unmount: {
      opacity: 0,
    },
    mount: {
      opacity: 1,
    },
  }
  const appliedAnimation = merge(animation, animate ?? {})

  const middleware = [fuiOffset(offset), flip(), shift()]

  const arrowRef = useRef<Element>(null)
  if (withArrow) {
    middleware.push(
      arrow({
        element: arrowRef ?? null,
      }),
    )
  }
  // 5. set @floating-ui
  const { x, y, strategy, middlewareData, refs, update, context } = useFloating(
    {
      open,
      onOpenChange: handler,
      middleware,
      placement,
    },
  )

  const id = useId()
  const labelId = `${id}-label`
  const descriptionId = `${id}-description`

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context, dismiss),
  ])

  useEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update)
    }
  }, [open, update, refs.reference, refs.floating])

  const contextValue = useMemo(
    () => ({
      open,
      strategy,
      x,
      y,
      context,
      refs,
      getReferenceProps,
      getFloatingProps,
      appliedAnimation,
      labelId,
      descriptionId,
      arrowRef: withArrow ? arrowRef : undefined,
      middlewareData,
    }),
    [
      open,
      strategy,
      x,
      y,
      context,
      refs,
      getFloatingProps,
      getReferenceProps,
      appliedAnimation,
      labelId,
      descriptionId,
      arrowRef,
      withArrow,
      middlewareData,
    ],
  )

  // 6. return
  return (
    <PopoverContextProvider value={contextValue}>
      {children}
    </PopoverContextProvider>
  )
}

export type { PopoverContentProps, PopoverHandlerProps }
export { Popover, PopoverContent, PopoverHandler }
