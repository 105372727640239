import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ReactNode } from 'react'
export default function SortableItem({
  id,
  children,
  sortButton = false,
}: {
  id: string
  children: (listeners?: SyntheticListenerMap) => ReactNode
  sortButton?: boolean
}) {
  const { attributes, listeners, setNodeRef, transition, transform } =
    useSortable({
      id,
    })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  let props = {}

  if (!sortButton) {
    props = { ...listeners }
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...props}>
      {children(sortButton ? listeners : undefined)}
    </div>
  )
}
