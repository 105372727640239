import { Sym } from '@edclass/fe-ui'
import { Spinner } from '@material-tailwind/react'
import clsx from 'clsx'
import toast, {
  DefaultToastOptions,
  Renderable,
  resolveValue,
  ToastOptions,
  ValueOrFunction,
} from 'react-hot-toast'

export function customToast(
  e: unknown,
  kind: 'success' | 'error' | 'loading' | 'none',
  options?: ToastOptions,
) {
  return toast.custom(
    <div
      style={{
        animation: `0.35s cubic-bezier(0.21, 1.02, 0.73, 1) ${options?.position && options?.position.includes('top') ? 'zoomInFromTop' : 'zoomInFromBottom'}`,
      }}
      className={clsx(
        'flex items-center gap-3 p-3 rounded-md max-w-[50%]',
        kind === 'success' && 'text-black bg-green-500',
        kind === 'error' && 'text-white bg-red-500',
        kind === 'loading' && 'text-black bg-amber-500',
        kind === 'none' && 'text-white bg-ed-dark-blue',
      )}
    >
      <div className="grid place-items-center">
        {kind === 'none' ? null : kind === 'loading' ? (
          <div className="w-6 h-6 grid place-items-center">
            <Spinner className="h-4 w-4" />
          </div>
        ) : (
          <Sym className="!text-[24px]">
            {kind === 'success' ? 'check' : 'close'}
          </Sym>
        )}
      </div>
      <div className="text-main">{`${e}`}</div>
    </div>,
    {
      duration: 3000,
      position: 'bottom-center',

      ...options,
    },
  )
}

export function noneToast(e: unknown, options?: ToastOptions) {
  return customToast(e, 'none', options)
}

/**
 * make a loading toast with spinner
 *
 * @param e
 * @param options
 */
export function loadingToast(e: unknown, options?: ToastOptions) {
  return customToast(e, 'loading', options)
}

/**
 * make a green success toast
 *
 * @param e
 * @param options
 */
export function successToast(e: unknown, options?: ToastOptions) {
  return customToast(e, 'success', options)
}

/**
 * make a red error toast
 *
 * @param e
 * @param options
 */
export function errorToast(e: unknown, options?: ToastOptions) {
  return customToast(e, 'error', options)
}

export type PromiseToastMessage<T> = {
  loading: Renderable
  success: ValueOrFunction<Renderable, T>
  error: ValueOrFunction<Renderable, unknown>
}
/**
 * make a promise based toast that will return
 * the result of the promise while changing
 * the toast message
 *
 * @param promise
 * @param messages
 * @param options
 */
export function promiseToast<T>(
  promise: Promise<T>,
  messages: PromiseToastMessage<T>,
  options?: DefaultToastOptions,
) {
  const id = loadingToast(messages.loading, {
    ...options,
    ...options?.loading,
  })

  promise
    .then((p) => {
      successToast(resolveValue(messages.success, p), {
        id,
        ...options,
        ...options?.success,
      })
      return p
    })
    .catch((e) => {
      errorToast(resolveValue(messages.error, e.message), {
        id,
        ...options,
        ...options?.error,
      })
    })

  return promise
}
