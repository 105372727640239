export function getUserDisplayName(
  user?: User | SimpleUser | MoreSimpleUser,
): string {
  return `${user?.firstName ? user.firstName : ''}${user?.middleName ? ` ${user.middleName}` : ''}${user?.lastName ? ` ${user.lastName}` : ''}`
}

/**
 * checking user role
 *
 * @param user
 * @param role
 */
export function hasRole(user: User, role: UserRole): boolean {
  return user.roles.includes(role)
}

/**
 * checking multiple roles
 *
 * @param user
 * @param roles
 */
export function hasRoles(user: User, roles: UserRole[]): boolean {
  for (const r of user.roles) {
    if (roles.includes(r)) {
      return true
    }
  }
  return false
}
