import { useContext } from 'react'

import useContextOrThrow from '@/hooks/useContextOrThrow.ts'
import { SearchContext } from '@/providers/SearchProvider.tsx'

export default function useSearch() {
  return useContextOrThrow(SearchContext)
}

export function useSearchWithNoErr() {
  const context = useContext(SearchContext)

  return (
    context || {
      search: '',
      setSearch: () => null,
      debouncedSearch: '',
      debounced: {} as never,
    }
  )
}
