import { lazy } from 'react'

import { AdminChildrenPath, AdminCrudPath } from '@/constants/constants'

// eslint-disable-next-line react-refresh/only-export-components
const Page = lazy(() => import('@/pages/AdminPage/AdminExBoardsPage/index'))
// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(
  () => import('@/pages/AdminPage/AdminExBoardsPage/AdminExBoardsIndexPage'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Create = lazy(
  () => import('@/pages/AdminPage/AdminExBoardsPage/AdminExBoardsCreatePage'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Edit = lazy(
  () => import('@/pages/AdminPage/AdminExBoardsPage/AdminExBoardsEditPage'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Archive = lazy(
  () => import('@/pages/AdminPage/AdminExBoardsPage/AdminExBoardsArchivePage'),
)

export default {
  path: AdminChildrenPath.EX_BOARD,
  element: <Page />,
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: AdminCrudPath.CREATE,
      element: <Create />,
    },
    {
      path: `:id/${AdminCrudPath.EDIT}`,
      element: <Edit />,
    },
    {
      path: 'archives',
      element: <Archive />,
    },
  ],
}
