import { KEY_STAGES } from '@edclass/fe-common'

import { ED_BG, ED_BLUE } from '@/constants/color.ts'

export const POPOVER_TAILWIND_COLOR_CLASSNAME: Record<PopupType, string> = {
  question: '!bg-blue-500',
  //'elearn': '!bg-green-600',
  game: '!bg-deep-purple-400',
  puzzle: '!bg-teal-600',
  doodle: '!bg-purple-500',
  dnd: '!bg-indigo-500',
  text: '!bg-ed-beige',
}

export const MCQ_CHOICE_KIND_OPTIONS: {
  label: string
  icon: string
  kind: QuestionChoiceKind
  reverse?: boolean
}[] = [
  {
    label: 'Word',
    kind: 'word',
    icon: 'list',
  },
  {
    label: 'Picture -> Word',
    kind: 'picture-word',
    icon: 'view_sidebar',
  },
  {
    label: 'Word -> Picture',
    kind: 'word-picture',
    icon: 'view_sidebar',
    reverse: true,
  },
  {
    label: 'Video -> Word',
    kind: 'video-word',
    icon: 'smart_display',
  },
  {
    label: 'Abstract -> Word',
    kind: 'abstract-word',
    icon: 'subject',
  },
]

export const ESSAY_KIND_OPTIONS: {
  label: string
  icon: string
  kind: QuestionEssayKind
  reverse?: boolean
}[] = [
  {
    label: 'Free Text',
    kind: 'ft',
    icon: 'keyboard',
  },
  {
    label: 'Fill Blank',
    kind: 'fill-blank',
    icon: 'text_select_end',
  },
  {
    label: 'Fill Drag and Drop',
    kind: 'fill-dnd',
    icon: 'trackpad_input',
    reverse: true,
  },
]

export const MCQ_POSITION_OPTIONS: Array<{
  value: QuestionPosition
  icon: string
}> = [
  {
    value: 'top',
    icon: 'vertical_align_top',
  },
  {
    value: 'right',
    icon: 'keyboard_tab',
  },
  {
    value: 'bottom',
    icon: 'vertical_align_bottom',
  },
  {
    value: 'left',
    icon: 'keyboard_tab_rtl',
  },
]

export const MCQ_OPTION_STYLES: Array<{
  value: QuestionChoiceStyle
  label: string
  icon: string
}> = [
  {
    value: 'radio',
    label: 'Radio',
    icon: 'radio_button_checked',
  },
  {
    value: 'check',
    label: 'Checkbox',
    icon: 'check_box',
  },
  {
    value: 'button',
    label: 'Button',
    icon: 'buttons_alt',
  },
]

export const DND_CHOICE_KIND_OPTIONS: {
  label: string
  icon: string
  kind: DndKind
  reverse?: boolean
}[] = [
  {
    label: 'Text',
    kind: 'text',
    icon: 'text_fields',
  },
  {
    label: 'Spelling',
    kind: 'spelling',
    icon: 'spellcheck',
  },
  {
    label: 'Icons',
    kind: 'icons',
    icon: 'image',
  },
  {
    label: 'Number',
    kind: 'number',
    icon: 'tag',
  },
  {
    label: 'Abstract',
    kind: 'abstract',
    icon: 'description',
  },
]

export const PUZZLE_KIND_OPTIONS: {
  label: string
  icon: string
  kind: PuzzleKind
  reverse?: boolean
}[] = [
  {
    label: 'Word',
    kind: 'word-search',
    icon: 'manage_search',
  },
  {
    label: 'Matching',
    kind: 'matching',
    icon: 'join_inner',
  },
  {
    label: 'Crossword',
    kind: 'crossword',
    icon: 'crossword',
  },
  {
    label: 'Swapping',
    kind: 'sorting',
    icon: 'cards',
  },
]

export const DOODLE_KIND_OPTIONS: {
  label: string
  icon: string
  kind: DoodleKind
  reverse?: boolean
}[] = [
  {
    label: 'Blank',
    kind: 'blank',
    icon: 'square',
  },
  {
    label: 'Grid',
    kind: 'grid',
    icon: 'grid_on',
  },
  {
    label: 'Lined',
    kind: 'lined',
    icon: 'table_rows',
  },
]

export const DEFAULT_POPUP_WIDTH = 800
export const DEFAULT_POPUP_HEIGHT = 600

export const DEFAULT_MEDIA_TRACK_SETTINGS: LessonMediaTrackSettings = {
  coordinate: { x: 0, y: 0 },
  color: POPOVER_TAILWIND_COLOR_CLASSNAME.question,
  layout: {
    containerSize: 'fixed-size',
  },
  style: {
    css: {
      width: DEFAULT_POPUP_WIDTH,
      height: DEFAULT_POPUP_HEIGHT,
      backgroundColor: `${ED_BG}EC`,
      color: 'white',
      borderColor: ED_BLUE,
    },
    overlay: false,
    overlayBdOpacity: 0.6,
    overlayColor: '#000',
    overlayBdBlur: false,
    border: true,
  },
  wizardSteps: {
    init: 0,
  },
}

export const DEFAULT_MEDIA_TRACK: Omit<MediaTrack, 'id'> = {
  tags: [],
  type: 'question',
  seconds: 0,
  setting: DEFAULT_MEDIA_TRACK_SETTINGS,
  questions: [],
  doodle: undefined,
  questionKind: undefined,
  game: undefined,
  dndItems: [],
  puzzle: undefined,
  textItem: undefined,
}

export const DEFAULT_QUESTION_SETTINGS: QuestionSettings = {
  titleAlignment: 'start',
  layout: 'left',
  mediaLayout: 'right',
  mediaWidth: undefined,
  hideTitle: false,
  contentWidth: undefined,
  hideBody: false,
  choicesColumn: 2,
  choiceStyle: 'check',
  bodyDecoration: false,
  layoutTemplate: 'title_t-body_m-media_n-opt',
}

export const DEFAULT_ELEARN_TRACK: Omit<ElearnTrack, 'id'> = {
  tags: [],
  type: 'question',
  setting: DEFAULT_MEDIA_TRACK_SETTINGS,
  questions: [],
  questionKind: undefined,
  game: undefined,
  doodle: undefined,
  dndItems: [],
  puzzle: undefined,
  textItem: undefined,
}

export const DEFAULT_ELEARN_TRACK_SETTINGS: ElearnTrackSettings = {
  color: POPOVER_TAILWIND_COLOR_CLASSNAME.question,
  layout: {
    containerSize: 'fixed-size',
  },
  style: {
    css: {
      width: DEFAULT_POPUP_WIDTH,
      height: DEFAULT_POPUP_HEIGHT,
      backgroundColor: `${ED_BG}EC`,
      color: 'white',
      borderColor: ED_BLUE,
    },
    overlay: false,
    overlayBdOpacity: 0.6,
    overlayColor: '#000',
    overlayBdBlur: false,
    border: true,
  },
  wizardSteps: {
    init: 0,
  },
}

export const DEFAULT_ESSAY_FORM: Omit<
  EssayQuestionFormValue,
  'id' | 'directory'
> = {
  marking: 10,
  allowVerbal: false,
  kind: 'essay',
  essayKind: 'ft',
  body: '',
  title: '',
  setting: DEFAULT_QUESTION_SETTINGS,
  image: '',
  difficulty: 1,
  yearGroups: KEY_STAGES[0].years,
  modelAnswers: [],
  autoTrigger: false,
}

export const DEFAULT_MCQ_FORM: Omit<
  MultiChoicesQuestionFormValue,
  'id' | 'directory'
> = {
  marking: 10,
  allowVerbal: false,
  topic: undefined,
  choiceOptions: [],
  kind: 'mcq',
  choiceKind: 'word',
  body: '',
  title: '',
  setting: DEFAULT_QUESTION_SETTINGS,
  image: '',
  difficulty: 1,
  yearGroups: KEY_STAGES[0].years,
}

export const DEFAULT_DND_ITEM_FORM: Omit<DndItemFormValue, 'directory'> = {
  dndOptions: [],
  kind: 'text',
  body: '',
  title: '',
  image: undefined,
  video: undefined,
  audio: undefined,
  setting: DEFAULT_QUESTION_SETTINGS,
  slots: [],
  yearGroups: KEY_STAGES[0].years,
  difficulty: 1,
  draggableColumn: 4,
  droppableColumn: 4,
  marking: 10,
}
export const DEFAULT_PUZZLE_FORM: Omit<PuzzleItemFormValue, 'directory'> = {
  title: '',
  body: '',
  image: undefined,
  setting: {
    fields: [],
    matching: [['', '']],
    crossword: {
      across: {},
      down: {},
      counter: 1,
    },
    wordSearch: [],
    sorting: {},
    ...DEFAULT_QUESTION_SETTINGS,
  },
  kind: 'matching',
  yearGroups: KEY_STAGES[0].years,
  difficulty: 1,
  marking: 10,
}

export const DEFAULT_DOODLE_FORM: Omit<DoodleItemFormValue, 'directory'> = {
  title: '',
  body: '',
  image: undefined,
  setting: {
    ...DEFAULT_QUESTION_SETTINGS,
  },
  kind: 'blank',
  yearGroups: KEY_STAGES[0].years,
  difficulty: 1,
  marking: 10,
}
