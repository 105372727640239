import { useTheme } from '@material-tailwind/react'
import type {
  animate,
  arrow,
  className,
  color,
  containerProps,
  disabled,
  dismiss,
  error,
  label,
  labelProps,
  menuProps,
  name,
  offset,
  size,
  success,
  variant,
} from '@material-tailwind/react/types/components/select'
import findMatch from '@material-tailwind/react/utils/findMatch'
import objectsToString from '@material-tailwind/react/utils/objectsToString'
import clsx from 'clsx'
import merge from 'deepmerge'
import { useIsomorphicLayoutEffect } from 'framer-motion'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Arrow from '@/components/Arrow.tsx'
import useFloatingDropdown from '@/hooks/useFloatingDropdown.ts'

export type BaseDropdownProps = {
  variant?: variant
  color?: color
  size?: size | 'xl'
  label?: label
  error?: error
  success?: success
  arrow?: arrow
  offset?: offset
  dismiss?: dismiss
  animate?: animate
  labelProps?: labelProps
  menuProps?: menuProps
  className?: className
  disabled?: disabled
  name?: name
  containerProps?: containerProps
  required?: boolean
}
export default function useDropdownTheme<T extends object>(
  compKey: string,
  {
    variant,
    color,
    size,
    label,
    arrow,
    success,
    error,
    offset,
    dismiss,
    animate,
    labelProps,
    menuProps,
    className,
    disabled,
    name,
    containerProps,
    required,
  }: BaseDropdownProps,
  restProps: Record<string, unknown> | undefined,
  contextValueProps: Partial<T> | undefined,
  menuRole: AriaRole = 'listbox',
) {
  // 1. init
  const theme = useTheme()
  const compTheme = theme[compKey]
  const { defaultProps, valid, styles } = compTheme
  const { base, variants } = styles

  const [state, setState] = useState<string>('close')

  // 2. set default props
  variant = variant ?? defaultProps.variant
  color = color ?? defaultProps.color
  size = size ?? defaultProps.size
  label = label ?? defaultProps.label
  error = error ?? defaultProps.error
  success = success ?? defaultProps.success
  arrow = arrow ?? defaultProps.arrow
  offset = offset ?? defaultProps.offset
  dismiss = dismiss ?? defaultProps.dismiss
  animate = animate ?? defaultProps.animate
  labelProps = labelProps ?? defaultProps.labelProps
  menuProps = menuProps ?? defaultProps.menuProps
  containerProps =
    merge(containerProps ?? {}, defaultProps?.containerProps || {}) ??
    defaultProps.containerProps
  className = className ?? defaultProps.className

  const ddVariant = variants[findMatch(valid.variants, variant, 'outlined')]
  const ddSize = ddVariant.sizes[findMatch(valid.sizes, size, 'md')]
  const ddError = ddVariant.error.select
  const ddSuccess = ddVariant.success.select
  const ddColor =
    ddVariant.colors.select[findMatch(valid.colors, color, 'gray')]

  const stateClasses = ddVariant.states[state]
  const ddClasses = twMerge(
    clsx(
      objectsToString(base.select),
      objectsToString(ddVariant.base.select),
      objectsToString(stateClasses.select),
      objectsToString(ddSize.select),
      { [objectsToString(ddColor[state])]: !error && !success },
      { [objectsToString(ddError.initial)]: error },
      { [objectsToString(ddError.states[state])]: error },
      { [objectsToString(ddSuccess.initial)]: success },
      { [objectsToString(ddSuccess.states[state])]: success },
    ),
    className,
  )

  const menuClasses = twMerge(
    clsx(objectsToString(base.menu)),
    menuProps?.className ?? '',
  )

  const {
    open,
    setOpen,
    contextValue,
    context,
    refs,
    appliedAnimation,
    floatingProps,
    NewAnimatePresence,
    controlledScrolling,
    refProps,
  } = useFloatingDropdown({
    dismiss,
    contextValueProps,
    offset,
    animate,
    menuProps,
    menuRole,
    menuClasses,
    ddClasses,
    restProps,
    name,
    disabled,
  })

  const arrowClasses = clsx(objectsToString(base.arrow.initial), {
    [objectsToString(base.arrow.active)]: open,
  })

  const floatingRef = refs.floating

  useIsomorphicLayoutEffect(() => {
    const floating = floatingRef.current

    if (open && controlledScrolling && floating) {
      //const item =
      //selectedIndex != null ? listItemsRef.current[selectedIndex] : null
    }
  }, [open, controlledScrolling])

  const labelError = ddVariant.error.label
  const labelSuccess = ddVariant.success.label
  const labelColor =
    ddVariant.colors.label[findMatch(valid.colors, color, 'gray')]

  const containerClasses = clsx(
    objectsToString(base.container),
    objectsToString(ddSize.container),
    containerProps?.className,
  )

  const labelClasses = twMerge(
    clsx(
      objectsToString(base.label),
      objectsToString(ddVariant.base.label),
      objectsToString(stateClasses.label),
      objectsToString(ddSize.label.initial),
      objectsToString(ddSize.label.states[state]),
      { [objectsToString(labelColor[state])]: !error && !success },
      { [objectsToString(labelError.initial)]: error },
      { [objectsToString(labelError.states[state])]: error },
      { [objectsToString(labelSuccess.initial)]: success },
      { [objectsToString(labelSuccess.states[state])]: success },
    ),
    labelProps?.className ?? '',
  )
  const removeClasses = clsx(
    'clear',
    base?.remove ? objectsToString(base.remove.initial) : undefined,
  )

  const labelEl = (
    <label {...labelProps} className={labelClasses}>
      {label} {required ? <span className="text-red-500">*</span> : null}
    </label>
  )

  const arrowEl = <div className={arrowClasses}>{arrow ?? <Arrow />}</div>

  return {
    color,
    labelEl,
    arrowClasses,
    arrowEl,
    containerClasses,
    removeClasses,
    contextValue: contextValue as T,
    floatingProps,
    refProps,
    state,
    setState,
    open,
    setOpen,
    appliedAnimation,
    NewAnimatePresence,
    context,
  }
}
