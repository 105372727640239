import './SymbolNav.css'

import { Sym } from '@edclass/fe-ui'
import clsx from 'clsx'

import LoggedNavLink from '@/components/Link/LoggedNavLink.tsx'
import { getPath, PathKind } from '@/helpers/path.ts'

/**
 * SymbolNav component renders a navigation link with an icon and label.
 * It allows users to navigate between different sections of the application.
 */
interface SymbolNavProps {
  /** Destination URL of the navigation link. */
  to?: string
  /** Icon to be displayed in the navigation link. */
  icon: string
  /** Path type for the navigation link: admin, teacher, or student. */
  pathKind?: PathKind
  /** Content to be displayed alongside the icon in the navigation link. */
  children: string
  /** @see `NavLink` end props */
  end?: boolean
  /** Indicates if the path comparison should be case sensitive. */
  caseSensitive?: boolean
  /** Additional CSS class for the navigation link. */
  navClassName?: string
  /** Additional CSS class for the symbol icon. */
  symbolClassName?: string
  onDrillDown?: () => void
  drilled?: boolean
}

/**
 * SymbolNav is a React component that renders a navigation link with an icon and label.
 * It constructs the link based on the provided properties and uses NavLink for routing.
 */
export default function SymbolNav({
  to,
  children,
  icon,
  pathKind,
  end,
  caseSensitive,
  navClassName,
  symbolClassName,
  onDrillDown,
  drilled,
}: SymbolNavProps) {
  // Construct the link URL
  const link = pathKind ? getPath(pathKind, (to as string) || '') : to || ''
  //const link = `${BASE_PATH}${basePath ? `/${basePath}` : ''}${to ? `/${to}` : ''}`

  return (
    // Render NavLink for navigation with proper styling and attributes
    <div className={clsx('w-full flex-c-2 sb-link')}>
      <LoggedNavLink
        end={end}
        title={children}
        caseSensitive={caseSensitive}
        className={clsx('group sym-nav w-full', navClassName)}
        to={link}
      >
        {/* Render the symbol icon */}
        <Sym className={clsx('sym', symbolClassName)}>{icon}</Sym>
        {/* Render the label */}
        <div className="label">{children}</div>
      </LoggedNavLink>
      {onDrillDown && (
        <button onClick={() => onDrillDown()} className="flex-shrink-0 me-2">
          <Sym className="animate">
            {drilled ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
          </Sym>
        </button>
      )}
    </div>
  )
}
