import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { createContext } from 'react'

import { FileStorageView } from '@/providers/FileStorageProvider/types.ts'
import { SearchContextValue } from '@/providers/SearchProvider.tsx'

export interface FileStorageContextValue extends SearchContextValue {
  /**
   * The array of file presenters that are currently checked.
   **/
  checked: FsItem[]
  setChecked: SetState<FsItem[]>
  /**
   * select a file and added it to checking
   * @param fileId
   */
  handleCheckAdd: (file: FsItem) => void
  /**
   * remove file from a selection
   */
  handleCheckRemove: (file: FsItem) => void
  /**
   * handle toggle check file
   */
  handleCheckToggle: (file: FsItem | boolean) => void
  /**
   * paginated files item
   */
  data?: PaginatedItems<FsItem>
  /**
   * is pending
   */
  isPending: boolean
  /**
   * is fetching
   */
  isFetching: boolean
  loading: boolean
  error: Error | null
  /**
   * filter params
   */
  params: ReqParams
  /**
   * set filter params
   */
  setParams: SetState<ReqParams>
  refetch: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<PaginatedItems<FsItem>, Error>>
  /**
   * layout view
   */
  view: FileStorageView
  setView: SetState<FileStorageView>
}
export const FileStorageContext = createContext<FileStorageContextValue | null>(
  null,
)
