import { FloatingFocusManager, FloatingOverlay } from '@floating-ui/react'
import type {
  animate,
  arrow,
  className,
  color,
  containerProps,
  disabled,
  dismiss,
  label,
  labelProps,
  lockScroll,
  menuProps,
  name,
  offset,
  size,
  variant,
} from '@material-tailwind/react/types/components/select'
import clsx from 'clsx'
import { domAnimation, LazyMotion, m } from 'framer-motion'
import { forwardRef, ReactNode, useEffect } from 'react'
import { createPortal } from 'react-dom'

import {
  DropdownMdContextValue,
  DropdownMdProvider,
} from '@/components/DropdownMd/DropdownMd.tsx'
import useDropdownTheme from '@/hooks/useDropdownTheme.tsx'

type DropdownMdProps = {
  variant?: variant
  color?: color
  size?: size
  label?: label
  arrow?: arrow
  offset?: offset
  dismiss?: dismiss
  animate?: animate
  lockScroll?: lockScroll
  labelProps?: labelProps
  menuProps?: menuProps
  className?: className
  disabled?: disabled
  name?: name
  containerProps?: containerProps
  withValue?: boolean
  children?: ReactNode
  toggle?: ReactNode
}

const DropdownMd = forwardRef<HTMLDivElement, DropdownMdProps>(
  function DropdownMd(
    {
      variant,
      color,
      size,
      label,
      arrow,
      //offset,
      dismiss,
      animate,
      labelProps,
      menuProps,
      className,
      disabled,
      name,
      containerProps,
      withValue,
      lockScroll,
      children,
      toggle,
    },
    ref,
  ) {
    const {
      open,
      setState,
      floatingProps,
      refProps,
      labelEl,
      arrowEl,
      //removeClasses,
      contextValue,
      context,
      appliedAnimation,
      NewAnimatePresence,
      containerClasses,
    } = useDropdownTheme<DropdownMdContextValue>(
      'dropdown',
      {
        variant,
        color,
        size,
        label,
        arrow,
        dismiss,
        animate,
        labelProps,
        menuProps,
        containerProps,
        className,
        disabled,
        name,
      },
      undefined,
      undefined,
      'menu',
    )

    useEffect(() => {
      if (open) {
        setState('open')
      } else if (!open && withValue) {
        setState('withValue')
      } else {
        setState('close')
      }
    }, [open, withValue, setState])

    const menu = (
      <FloatingFocusManager context={context} modal={false}>
        <m.ul
          {...floatingProps}
          initial="unmount"
          exit="unmount"
          animate={open ? 'mount' : 'unmount'}
          variants={appliedAnimation}
        >
          {children}
        </m.ul>
      </FloatingFocusManager>
    )

    return (
      <DropdownMdProvider value={contextValue}>
        <div {...containerProps} ref={ref} className={containerClasses}>
          <div {...refProps}>
            <div
              className={clsx(
                'flex-c-2 flex-1 flex-wrap overflow-hidden',
                arrow ? 'me-12' : null,
              )}
            >
              <div className="container-style">
                {toggle}
                {/**
                 * input here
                 */}
              </div>
            </div>
            {/*withValue && (
              <button
                title="Clear"
                className={removeClasses}
                onClick={() => {
                  //
                }}
              >
                {<XCircleIcon width={16} height={16} />}
              </button>
            )*/}
            {arrowEl}
          </div>
          {labelEl}
          {createPortal(
            <LazyMotion features={domAnimation}>
              <NewAnimatePresence>
                {open && (
                  <>
                    {lockScroll ? (
                      <FloatingOverlay lockScroll>{menu}</FloatingOverlay>
                    ) : (
                      menu
                    )}
                  </>
                )}
              </NewAnimatePresence>
            </LazyMotion>,
            document.body,
          )}
        </div>
      </DropdownMdProvider>
    )
  },
)

export default DropdownMd
