import { useTheme } from '@material-tailwind/react'
import type {
  children,
  className,
  disabled,
  index,
} from '@material-tailwind/react/types/components/select'
import objectsToString from '@material-tailwind/react/utils/objectsToString'
import clsx from 'clsx'
import { ComponentProps, KeyboardEvent, MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'

import { useMultiSelect } from './hooks.ts'

export interface MultiSelectOptionProps<T>
  extends Omit<ComponentProps<'li'>, 'value'> {
  value: T
  keywords?: string
  index?: index
  disabled?: disabled
  className?: className
  children: children
}

export function MultiSelectOption<T>({
  value,
  index = 0,
  disabled = false,
  className = '',
  children,
  ...rest
}: MultiSelectOptionProps<T>) {
  const { select } = useTheme()
  const { styles } = select
  const { base } = styles

  const {
    value: selectedValues,
    listRef,
    setOpen,
    onChange,
    getItemProps,
    dataRef,
    setTypeAhead,
  } = useMultiSelect<T>()

  function handleSelect() {
    const idx = selectedValues.findIndex((s) => s === value)
    let copy: T[]

    if (idx !== -1) {
      copy = [...selectedValues]
      copy.splice(idx, 1)
    } else {
      copy = [...selectedValues, value]
    }

    onChange(copy)
    setOpen(false)
    setTypeAhead('')
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (
      event.key === 'Enter' ||
      (event.key === ' ' && !dataRef.current.typing)
    ) {
      event.preventDefault()
      handleSelect()
    }
  }

  // 3. set styles
  const optionBaseClasses = objectsToString(base.option.initial)
  const optionActiveClasses = objectsToString(base.option.active)
  const optionDisabledClasses = objectsToString(base.option.disabled)
  const classes = twMerge(
    clsx(optionBaseClasses, {
      [optionActiveClasses]: selectedValues.find((v) => {
        return v === value
      }),
      [optionDisabledClasses]: disabled,
    }),
    className ?? '',
  )

  // 4. return
  return (
    <li
      {...rest}
      role="option"
      ref={(node) => (listRef.current[index] = node)}
      className={classes}
      disabled={disabled}
      data-selected={selectedValues.find((v) => v === value)}
      {...getItemProps({
        onClick: (e: MouseEvent<HTMLLIElement>) => {
          const onClick = rest?.onClick
          if (typeof onClick === 'function') {
            onClick(e)
            handleSelect()
          } else {
            handleSelect()
          }
        },
        onKeyDown: (e: KeyboardEvent<HTMLLIElement>) => {
          const onKeyDown = rest?.onKeyDown

          if (typeof onKeyDown === 'function') {
            onKeyDown(e)
            handleKeyDown(e)
          }

          handleKeyDown(e)
        },
      })}
    >
      {children}
    </li>
  )
}

MultiSelectOption.displayName = 'MultiSelectOption'

export default MultiSelectOption
