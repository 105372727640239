import clsx from 'clsx'

import {
  SUBJECT_DARK_FG,
  SUBJECT_TAILWIND_COLOR_CLASSNAME,
} from '@/constants/constants.ts'
import { TimetableSubject } from '@/helpers/types.ts'

export function timetableClassName(item: TimetableSubject) {
  const isDarkFg = item.subject
    ? SUBJECT_DARK_FG.includes(item.subject) && !item.isLeave
    : false
  return clsx(
    item.subject &&
      !item.isLeave &&
      SUBJECT_TAILWIND_COLOR_CLASSNAME[item.subject],
    item.isLeave && 'leave',
    (item.isLeave || !item.subject) && 'bg-ed-bg',
    isDarkFg ? 'text-ed-bg' : 'text-white',
  )
}
