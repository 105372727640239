import './TimetableSheetContainer.css'

import { Sym } from '@edclass/fe-ui'
import clsx from 'clsx'
import { ReactNode } from 'react'

export interface TimetableSheetContainerProps {
  children: ReactNode
  className?: string
  label?: string
  onClick?: () => void
}

export default function TimetableSheetContainer({
  children,
  className,
  onClick,
  label,
  ...props
}: TimetableSheetContainerProps) {
  return (
    <div
      {...props}
      className={clsx('tts-container', className)}
      onClick={onClick}
      tabIndex={onClick ? 0 : undefined}
    >
      {label && (
        <div className="tts-container-header">
          <Sym className="!text-[20px]">today</Sym>
          <div>{label}</div>
        </div>
      )}
      <ul className="tts-container-items">{children}</ul>
    </div>
  )
}
