import { Sym } from '@edclass/fe-ui'
import { ReactNode, useMemo } from 'react'

import BackButton from '@/components/Buttons/BackButton.tsx'
import useAppContext from '@/hooks/useAppProvider.ts'
import {
  AdminLayoutContext,
  AdminLayoutContextValue,
} from '@/pages/AdminPage/AdminLayout/AdminLayoutContext.tsx'
import { useAdminLayout } from '@/pages/AdminPage/AdminLayout/hooks.ts'

function AdminLayoutHeader() {
  const { headerTitle, headerAction } = useAppContext()
  const { title, icon, onBack, header } = useAdminLayout()

  return (
    <>
      <headerTitle.In>
        {onBack ? (
          <BackButton onBack={onBack} />
        ) : typeof icon === 'string' ? (
          <Sym>{icon}</Sym>
        ) : (
          icon
        )}
        {title && <span>{title}</span>}
      </headerTitle.In>
      <headerAction.In>{header}</headerAction.In>
    </>
  )
}

export interface AdminLayoutProps extends AdminLayoutContextValue {
  children?: ReactNode
}

export default function AdminLayout({
  children,
  header,
  title,
  ...rest
}: AdminLayoutProps) {
  const withHeader = useMemo(() => {
    return header !== undefined || title
  }, [header, title])

  const inner = useMemo(() => {
    return (
      <>
        {withHeader && <AdminLayoutHeader />}
        {children}
      </>
    )
  }, [withHeader, children])

  return (
    <AdminLayoutContext.Provider
      value={{
        title,
        header,
        ...rest,
      }}
    >
      {inner}
    </AdminLayoutContext.Provider>
  )
}
