import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { ReactNode, useMemo } from 'react'

import { useFm } from '@/components/FileManager/hooks.ts'
import { FileManagerTab } from '@/components/FileManager/types.ts'

export default function FileManagerModal({
  open,
  handler,
  children,
}: {
  open: boolean
  handler: () => void
  children?: ReactNode
}) {
  const {
    aiImage,
    setAiImage,
    multiple,
    onUpload,
    activeTab,
    selected,
    files,
    isUploading,
    setIsUploading,
    setFiles,
    setSelected,
    onChange,
    modal,
  } = useFm()

  const btn = useMemo(() => {
    switch (activeTab) {
      case FileManagerTab.Browser:
        return (
          <Button
            disabled={selected.length < 1}
            onClick={() => {
              onChange(selected)
              setSelected([])
              setFiles([])
              modal?.handler()
            }}
          >
            Select
          </Button>
        )
      case FileManagerTab.Uploader:
        return (
          <Button
            loading={isUploading}
            disabled={files.length < 1 || isUploading}
            onClick={async () => {
              setIsUploading(true)
              const possiblePromise = await onUpload(files)
              setIsUploading(false)
              setFiles([])
              if (possiblePromise && Array.isArray(possiblePromise)) {
                onChange(
                  multiple
                    ? [...selected, ...possiblePromise]
                    : [possiblePromise[possiblePromise.length - 1]],
                )
              }

              modal?.handler()
            }}
          >
            Upload & Select
          </Button>
        )
      case FileManagerTab.AI:
        return (
          <Button
            loading={isUploading}
            disabled={!aiImage || isUploading}
            onClick={async () => {
              if (aiImage?.buffer) {
                setIsUploading(true)
                const possiblePromise = await onUpload([aiImage.buffer])
                setIsUploading(false)
                onChange(
                  multiple
                    ? [...selected, ...possiblePromise]
                    : [possiblePromise[possiblePromise.length - 1]],
                )
                setAiImage(null)
                modal?.handler()
              }
            }}
          >
            Upload & Select
          </Button>
        )
    }
  }, [
    activeTab,
    selected,
    isUploading,
    aiImage,
    files,
    onChange,
    setAiImage,
    setSelected,
    modal,
    multiple,
    onUpload,
    setFiles,
    setIsUploading,
  ])

  return (
    <Dialog
      size="lg"
      open={open}
      handler={() => {
        setFiles([])
        setSelected([])
        handler()
      }}
    >
      <DialogHeader>File Manager</DialogHeader>
      <DialogBody divider>{children}</DialogBody>
      <DialogFooter>{btn}</DialogFooter>
    </Dialog>
  )
}
