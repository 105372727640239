import type { ContextData } from '@floating-ui/react'
import type { children } from '@material-tailwind/react/types/components/select'
import {
  createContext,
  Dispatch,
  HTMLProps,
  MutableRefObject,
  RefObject,
  SetStateAction,
} from 'react'

export type SelectWithSearchContextValue<T> = {
  value: T
  listRef: MutableRefObject<Array<HTMLLIElement | null>>
  options: T[]
  inputRef: RefObject<HTMLInputElement>
  setOpen: (open: boolean) => void
  onChange: (value: T) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItemProps: (userProps?: HTMLProps<HTMLElement>) => any
  dataRef: ContextData
  typeAhead: string
  setTypeAhead: Dispatch<SetStateAction<string>>
}

export interface SelectWithSearchContextProviderProps<T> {
  value: SelectWithSearchContextValue<T>
  children: children
}

export const SelectWithSearchContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<SelectWithSearchContextValue<any> | null>(null)

export function SelectWithSearchContextProvider<T>({
  value,
  children,
}: SelectWithSearchContextProviderProps<T>) {
  return (
    <SelectWithSearchContext.Provider value={value}>
      {children}
    </SelectWithSearchContext.Provider>
  )
}
