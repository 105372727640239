import BoringAvatar from 'boring-avatars'
import clsx from 'clsx'
import { CSSProperties, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import Tooltip from '@/components/Tooltip'
import { getUserDisplayName } from '@/helpers/user.ts'

export default function Avatar({
  tip,
  user,
  size = 32,
  className,
  style,
  imgClassName,
}: {
  size?: number
  tip?: boolean
  user?: Partial<SimpleUser>
  className?: string
  imgClassName?: string
  style?: CSSProperties
}) {
  const name = useMemo(() => {
    return getUserDisplayName(user as SimpleUser)
  }, [user])

  const avatar = (
    <div className={clsx(twMerge('rounded-full', className))} style={style}>
      {user?.picture ? (
        <img
          src={user.picture}
          width={size}
          height={size}
          className={clsx(twMerge('rounded-[inherit]', imgClassName))}
        />
      ) : (
        <BoringAvatar variant="beam" size={size} name={name} />
      )}
    </div>
  )
  return tip ? <Tooltip content={name || 'N/A'}>{avatar}</Tooltip> : avatar
}
