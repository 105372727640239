import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

export default function AdminImportPage() {
  return (
    <Suspense fallback={<></>}>
      <Outlet />
    </Suspense>
  )
}
