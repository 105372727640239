import { useContext } from 'react'

import { FileManagerContext } from '@/components/FileManager/FileManagerContext.tsx'

export function useFm() {
  const context = useContext(FileManagerContext)
  if (context === null) {
    throw Error('must use within file manager context')
  }

  return context
}
