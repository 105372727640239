export const AUDIO_MIME: Record<string, string> = {
  aac: 'audio/aac',
  aiff: 'audio/aiff',
  alac: 'audio/alac',
  ape: 'audio/ape',
  au: 'audio/basic',
  flac: 'audio/flac',
  m4a: 'audio/mp4',
  m4b: 'audio/mp4',
  m4p: 'audio/mp4',
  mid: 'audio/midi',
  midi: 'audio/midi',
  mp3: 'audio/mpeg',
  mpga: 'audio/mpeg',
  oga: 'audio/ogg',
  ogg: 'audio/ogg',
  opus: 'audio/opus',
  ra: 'audio/x-realaudio',
  ram: 'audio/x-pn-realaudio',
  wav: 'audio/wav',
  weba: 'audio/webm',
  wma: 'audio/x-ms-wma',
  wv: 'audio/wavpack',
}

export const AUDIO_MIMES = Object.values(AUDIO_MIME)
export const AUDIO_EXTS = Object.keys(AUDIO_MIME)

export const VIDEO_MIME: Record<string, string> = {
  '3gp': 'video/3gpp',
  '3g2': 'video/3gpp2',
  avi: 'video/x-msvideo',
  flv: 'video/x-flv',
  h264: 'video/h264',
  m4v: 'video/x-m4v',
  mkv: 'video/x-matroska',
  mov: 'video/quicktime',
  mp4: 'video/mp4',
  mpg: 'video/mpeg',
  mpeg: 'video/mpeg',
  mpe: 'video/mpeg',
  mpv: 'video/mpv',
  mxf: 'application/mxf',
  ogv: 'video/ogg',
  qt: 'video/quicktime',
  rm: 'application/vnd.rn-realmedia',
  rmvb: 'application/vnd.rn-realmedia-vbr',
  svi: 'video/vnd.sealedmedia.softseal.mov',
  vob: 'video/x-ms-vob',
  webm: 'video/webm',
  wmv: 'video/x-ms-wmv',
  yuv: 'video/x-raw-yuv',
}
export const VIDEO_MIMES = Object.values(VIDEO_MIME)
export const VIDEO_EXTS = Object.keys(VIDEO_MIME)

export const IMAGE_MIME: Record<string, string> = {
  cgm: 'image/cgm',
  djvu: 'image/vnd.djvu',
  gif: 'image/gif',
  ico: 'image/vnd.microsoft.icon',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  jp2: 'image/jp2',
  jpx: 'image/jpx',
  png: 'image/png',
  svg: 'image/svg+xml',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  webp: 'image/webp',
  heic: 'image/heic',
  heif: 'image/heif',
  bmp: 'image/bmp',
  dib: 'image/bmp',
  ppm: 'image/x-portable-pixmap',
  pgm: 'image/x-portable-graymap',
  pbm: 'image/x-portable-bitmap',
  pnm: 'image/x-portable-anymap',
  hdr: 'image/vnd.radiance',
  exr: 'image/x-exr',
  avif: 'image/avif',
}

export const IMAGE_MIMES = Object.values(IMAGE_MIME)
export const IMAGE_EXTS = Object.keys(IMAGE_MIME)
