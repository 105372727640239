import { ChangeEvent, createContext } from 'react'

import {
  UploadAction,
  UploadProgressStatus,
  UploadState,
} from '@/providers/UploadProvider/types.ts'

/**
 Represents the context value of the upload feature, which includes the current state, functions to update the state, and additional metadata.
 */
interface UploadContextValue extends UploadState {
  /**
   Function that handles the onChange event of the input element used for file selection.
   */
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  /**
   The dispatch function used to update the state with actions.
   */
  dispatch: Dispatch<UploadAction>
  /**
   * handle reset
   */
  handleReset: () => void
  /**
   * Current task status
   */
  status: UploadProgressStatus
  /**
   * Time remaining (estimation) for users to complete upload
   */
  timeEstimation: string

  queueUpload: (files: File[]) => Promise<void>
}

export const UploadContext = createContext<UploadContextValue | null>(null)
