import { AdminChildrenPath } from '@/constants/constants.ts'
import AdminImportIndexPage from '@/pages/AdminPage/AdminImportPage/AdminImportIndexPage.tsx'
import AdminImportPage from '@/pages/AdminPage/AdminImportPage/index.tsx'

export default {
  path: AdminChildrenPath.IMPORT,
  element: <AdminImportPage />,
  children: [
    {
      index: true,
      element: <AdminImportIndexPage />,
    },
  ],
}
