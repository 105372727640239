import { lazy } from 'react'

import {
  AdminChildrenPath,
  AdminStorageChildrenPath,
} from '@/constants/constants'
import FileStorageProvider from '@/providers/FileStorageProvider'

// eslint-disable-next-line react-refresh/only-export-components
const Page = lazy(() => import('@/pages/AdminPage/AdminStoragePage/index.tsx'))
// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(
  () => import('@/pages/AdminPage/AdminStoragePage/AdminStorageIndexPage.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Image = lazy(
  () => import('@/pages/AdminPage/AdminStoragePage/AdminStorageImagePage.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Video = lazy(
  () => import('@/pages/AdminPage/AdminStoragePage/AdminStorageVideoPage.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Audio = lazy(
  () => import('@/pages/AdminPage/AdminStoragePage/AdminStorageAudioPage.tsx'),
)

// eslint-disable-next-line react-refresh/only-export-components
const Other = lazy(
  () => import('@/pages/AdminPage/AdminStoragePage/AdminStorageOtherPage.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Migration = lazy(
  () =>
    import('@/pages/AdminPage/AdminStoragePage/AdminStorageMigrationPage.tsx'),
)

export default {
  path: AdminChildrenPath.STORAGE,
  element: (
    <FileStorageProvider>
      <Page />
    </FileStorageProvider>
  ),
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: AdminStorageChildrenPath.IMAGE,
      element: <Image />,
    },
    {
      path: AdminStorageChildrenPath.VIDEO,
      element: <Video />,
    },
    {
      path: AdminStorageChildrenPath.AUDIO,
      element: <Audio />,
    },
    {
      path: AdminStorageChildrenPath.OTHER,
      element: <Other />,
    },
    {
      path: AdminStorageChildrenPath.MIGRATION,
      element: <Migration />,
    },
  ],
}
