import { defer, LoaderFunctionArgs, redirect } from 'react-router-dom'

import { EventService } from '@/services/event.ts'

export function roomLoader({ params, request }: LoaderFunctionArgs) {
  try {
    const createNewMeeting =
      request?.url?.endsWith('new') || request?.url?.endsWith('new/')

    return defer({
      joinResponse: createNewMeeting
        ? EventService.getInstance().joinRoom({ roomId: '' })
        : EventService.getInstance().joinRoom({ roomId: params.id! }),
    })
  } catch (error) {
    return redirect('/')
  }
}
