import { EDIcon } from '@/constants/constants.ts'
import AdminLayout from '@/pages/AdminPage/AdminLayout'

export default function AdminLogsIndexPage() {
  return (
    <AdminLayout title="Logs" icon={EDIcon.Log}>
      <div className="grid grid-cols-2 gap-4"></div>
    </AdminLayout>
  )
}
