import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { SKELETON_THEME } from '@/constants/constants.ts'

export default function AppSkeleton() {
  return (
    <SkeletonTheme {...SKELETON_THEME}>
      <div className="p-4 h-screen flex flex-col gap-8">
        <div className="flex gap-8 h-[80px] w-full justify-between">
          <Skeleton
            borderRadius={8}
            containerClassName="w-[20%] flex-shrink-0"
            height={'80px'}
          />
          <div className="flex items-center gap-4">
            <Skeleton
              borderRadius={8}
              containerClassName="w-[40px]"
              height={'40px'}
            />
            <Skeleton
              borderRadius={8}
              containerClassName="w-[40px]"
              height={'40px'}
            />
            <Skeleton
              borderRadius={8}
              containerClassName="w-[40px]"
              height={'40px'}
            />
            <Skeleton
              borderRadius={8}
              containerClassName="w-[40px]"
              height={'40px'}
            />
            <Skeleton
              borderRadius={8}
              containerClassName="w-[40px]"
              height={'40px'}
            />
          </div>
        </div>
        <div className="flex gap-8 h-full">
          <Skeleton
            borderRadius={8}
            containerClassName="w-[20%] h-full"
            height={'100%'}
          />
          <div className="w-full h-full flex flex-col gap-8">
            <Skeleton
              borderRadius={8}
              containerClassName="h-full"
              height="100%"
            />
            <Skeleton
              borderRadius={8}
              containerClassName="h-full"
              height="100%"
            />
            <Skeleton
              borderRadius={8}
              containerClassName="h-full"
              height="100%"
            />
            <Skeleton
              borderRadius={8}
              containerClassName="h-full"
              height="100%"
            />
            <Skeleton
              borderRadius={8}
              containerClassName="h-full"
              height="100%"
            />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  )
}
