import { Suspense } from 'react'
import { Await, Outlet, useLoaderData } from 'react-router-dom'

import EnableCameraModal from '@/components/Modal/EnableCameraModal.tsx'
import EnableScreenCaptureModal from '@/components/Modal/EnableScreenCaptureModal.tsx'
import useAppContext from '@/hooks/useAppProvider.ts'
import useCheckRole from '@/hooks/useCheckRole.ts'
import {
  TeacherPageProvider,
  useTeacherPage,
} from '@/pages/TeacherPage/TeacherPageProvider.tsx'
import TeacherSidebar from '@/pages/TeacherPage/TeacherSidebar.tsx'

function PageImpl() {
  const { recordingFlag, setRecordingFlag } = useTeacherPage()
  const { joinResponse } = useLoaderData() as {
    joinResponse: PromiseLike<IvsJoinResponse>
  }

  return (
    <Suspense fallback={<div>loading teacher elements...</div>}>
      <Await
        resolve={joinResponse}
        errorElement={<div>loading join error</div>}
      >
        <Outlet />
        <EnableScreenCaptureModal
          flags={recordingFlag}
          onChange={setRecordingFlag}
        />
        <EnableCameraModal flags={recordingFlag} onChange={setRecordingFlag} />
      </Await>
    </Suspense>
  )
}

export default function TeacherPage() {
  const [canView] = useCheckRole(['ROLE_EDCLASS_STAFF', 'ROLE_ROOT'])
  const { sidebar } = useAppContext()

  return canView ? (
    <TeacherPageProvider>
      <div className="p-4">
        <sidebar.In>
          <TeacherSidebar />
        </sidebar.In>
        <PageImpl />
      </div>
    </TeacherPageProvider>
  ) : null
}
