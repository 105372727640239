import { useMergeRefs } from '@floating-ui/react'
import { ComponentProps, forwardRef, ReactNode } from 'react'

import usePopover from '@/components/Popover/hooks.ts'

export interface PopoverHandlerProps extends ComponentProps<'div'> {
  children: ReactNode
}

export const PopoverHandler = forwardRef<HTMLDivElement, PopoverHandlerProps>(
  function PopoverHandler({ children, ...rest }, ref) {
    const { getReferenceProps, refs } = usePopover()
    const mergedRef = useMergeRefs([ref, refs.reference])

    return (
      <div
        data-testid="popover-handler"
        {...getReferenceProps({
          className: 'inline-flex',
          ...rest,
          ref: mergedRef,
        })}
      >
        {children}
      </div>
    )
  },
)
export default PopoverHandler
