import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import useAppContext from '@/hooks/useAppProvider.ts'
import useContextOrThrow from '@/hooks/useContextOrThrow.ts'

type NotificationContextValue = {
  notifications: NotificationMessage[]
  setNotifications: SetState<NotificationMessage[]>
  add: (next: NotificationMessage) => void
  update: (next: NotificationMessage) => void
  markRead: (next: NotificationMessage) => void
  markAllRead: () => void
  unreadNotifications: number
}

const Context = createContext<NotificationContextValue | null>(null)
Context.displayName = 'NotificationContext'

// eslint-disable-next-line react-refresh/only-export-components
export function useNotification() {
  return useContextOrThrow(Context)
}

export function NotificationProvider({ children }: { children: ReactNode }) {
  const { lastEventMessage } = useAppContext()
  const [notifications, setNotifications] = useState<NotificationMessage[]>([])

  const add = useCallback((next: NotificationMessage) => {
    setNotifications((prev) => {
      return [next, ...prev]
    })
  }, [])

  const update = useCallback(
    (next: NotificationMessage) => {
      const idx = notifications.findIndex((n) => n.id === next.id)
      if (idx !== -1) {
        setNotifications((prev) => {
          const copy = [...prev]
          copy[idx] = next
          return copy
        })
      }
    },
    [notifications],
  )

  const markRead = useCallback(
    (next: NotificationMessage) => {
      update({
        ...next,
        isRead: true,
      })
    },
    [update],
  )

  const markAllRead = useCallback(() => {
    setNotifications((prev) => {
      return prev.map((n) => {
        return {
          ...n,
          isRead: true,
        }
      })
    })
  }, [])

  const unreadNotifications = useMemo(() => {
    return notifications.filter((n) => n.isRead === false).length
  }, [notifications])

  const audioRef = useRef<HTMLAudioElement>(null)

  useEffect(() => {
    if (lastEventMessage) {
      const msg = lastEventMessage as Record<string, unknown>
      if (msg.type === 'notification') {
        add({
          ...msg,
          isRead: false,
        } as NotificationMessage)

        if (msg.kind === 'Alert') {
          audioRef.current?.play()
        }
      }
    }
  }, [lastEventMessage, add])

  return (
    <Context.Provider
      value={{
        notifications,
        setNotifications,
        add,
        update,
        markRead,
        markAllRead,
        unreadNotifications,
      }}
    >
      {children}
      <audio
        className="hidden"
        autoPlay={false}
        ref={audioRef}
        src="/alert.mp3"
      />
    </Context.Provider>
  )
}
