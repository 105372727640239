import { Button, Input, Typography } from '@material-tailwind/react'
import { useState } from 'react'

import InputRepeater from '@/components/InputRepeater'
export interface CurriculumFormValue {
  id?: number | string
  name: string
  years: ValueWithId[]
}

interface AdminCurriculumFormProps {
  initialValue?: CurriculumFormValue
}
export default function AdminCurriculumForm({
  initialValue,
}: AdminCurriculumFormProps) {
  const [value, setValue] = useState<CurriculumFormValue>(
    initialValue ?? {
      name: '',
      years: [],
    },
  )

  return (
    <div className="flex flex-col gap-8 py-4">
      <Input
        value={value.name}
        label="Name"
        id="name"
        name="name"
        onChange={(e) => {
          setValue({
            ...value,
            name: e.currentTarget.value.trim(),
          })
        }}
      />
      {
        <div className="flex flex-col gap-4">
          <Typography>Years</Typography>
          <InputRepeater
            id="years"
            initialValue={value.years}
            label={{
              value: 'Year',
            }}
            fieldKeys={['value']}
            onChange={(v) => {
              setValue({
                ...value,
                years: v,
              })
            }}
          />
        </div>
      }
      <div className="self-end">
        <Button
          onClick={() => {
            //
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  )
}
