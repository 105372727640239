import { Context, useContext } from 'react'

export default function useContextOrThrow<T>(Ctx: Context<T | null>): T {
  const ctx = useContext(Ctx)
  if (ctx === null) {
    const ctxName = Ctx.displayName
    throw Error(
      `${ctxName} context must be consumed inside a ${ctxName}.Provider`,
    )
  }
  return ctx
}
