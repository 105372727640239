import { Sym } from '@edclass/fe-ui'
import clsx from 'clsx'
import { ReactNode, useMemo, useState } from 'react'

import SymbolNav from '@/components/SymbolNav'
import { PathKind } from '@/helpers/path.ts'

interface SidebarMenuItem {
  path: string
  pathKind?: PathKind
  label: string
  icon: string
  children?: SidebarMenuItem[]
}
/*
function SidebarItem({
  className,
  icon,
  label,
}: {
  className?: string
  icon: string
  label: string
}) {
  return (
    <div className={clsx('menu-item', className)}>
      <Sym>{icon}</Sym>
      <span>{label}</span>
    </div>
  )
}*/

function SidebarLink({
  itemClassName,
  id,
  depth,
  parentPath,
  item,
  collapsed,
  search,
  openedDd,
  setOpenedDd,
}: {
  id?: string
  parentPath?: string
  depth: number
  item: SidebarMenuItem
  itemClassName?: string
  collapsed?: boolean
  search?: string
  openedDd: Record<string, boolean>
  setOpenedDd: SetState<Record<string, boolean>>
}) {
  const to = useMemo(() => {
    return parentPath ? `${parentPath}/${item.path}` : item.path
  }, [parentPath, item.path])

  const open = useMemo(() => {
    return openedDd[to] === true
  }, [openedDd, to])

  return (
    <div className="sb-item">
      <SymbolNav
        pathKind={item.pathKind}
        navClassName={clsx(itemClassName)}
        icon={item.icon}
        end={collapsed}
        to={to}
        drilled={open}
        onDrillDown={
          Array.isArray(item.children) && item.children.length > 0
            ? () => {
                setOpenedDd((prev) => {
                  return {
                    ...prev,
                    [to]: !prev[to],
                  }
                })
                //setOpen(!open)
              }
            : undefined
        }
      >
        {item.label}
      </SymbolNav>
      {open && item.children && Array.isArray(item.children) ? (
        <SidebarMenu
          openedDd={openedDd}
          setOpenedDd={setOpenedDd}
          search={search}
          className={clsx('sub-menu', `d-${depth}`)}
          depth={depth + 1}
          key={item.path}
          items={item.children}
          itemClassName="sub-menu-item"
          parentPath={`${parentPath ? `${parentPath}/` : ''}${item.path}`}
          id={id ? `${id}-${item.path}` : undefined}
        />
      ) : null}
    </div>
  )
}

interface SidebarMenuProps {
  before?: ReactNode
  id?: string
  parentPath?: string
  items: SidebarMenuItem[]
  className?: string
  itemClassName?: string
  depth: number
  collapsed?: boolean
  search?: string
  withSearch?: boolean
  openedDd: Record<string, boolean>
  setOpenedDd: SetState<Record<string, boolean>>
  ancestors?: string[]
}

function collectLabel(item: SidebarMenuItem, labels: string[]) {
  labels.push(item.label)
  if (Array.isArray(item.children) && item.children.length > 0) {
    item.children.forEach((c) => {
      collectLabel(c, labels)
    })
  }
}

function findItem(keyword: string, labels: string) {
  const k = keyword.toLowerCase()
  const l = labels.toLowerCase()
  return k.includes(l) || l.includes(k)
}

export default function SidebarMenu({
  before,
  id,
  parentPath,
  items,
  className,
  itemClassName,
  depth,
  collapsed,
  search: searchValue,
  withSearch,
  openedDd,
  setOpenedDd,
}: SidebarMenuProps) {
  const [search, setSearch] = useState('')

  const s = useMemo(() => {
    return searchValue !== undefined ? searchValue : search
  }, [searchValue, search])

  return (
    <nav role="menu" id={id} className={className}>
      {depth === 0 && withSearch && (
        <div className="focus-within:border-blue-500 px-2 h-10 flex-c-2 w-[calc(100%-1rem)] mb-4 border border-white/40 rounded-full">
          <Sym className="flex-shrink-0 !text-[20px] text-white/40">search</Sym>
          <input
            value={search}
            onChange={(e) => {
              setSearch(e.currentTarget.value)
            }}
            className={clsx(
              'h-full w-full bg-transparent',
              'focus:outline-0 focus-visible:outline-none rounded-full',
            )}
            type="text"
            placeholder={'Search Menu'}
          />
          {search && (
            <button
              className="text-white/80 hover:text-white/100"
              onClick={() => {
                setSearch('')
              }}
            >
              <Sym className="!text-[20px]">cancel</Sym>
            </button>
          )}
        </div>
      )}
      {before}
      {items.map((item) => {
        const labels: string[] = []
        if (depth === 0) {
          collectLabel(item, labels)
        }
        const labelStr = labels.join(' ')
        const link = (
          <SidebarLink
            openedDd={openedDd}
            setOpenedDd={setOpenedDd}
            search={search}
            collapsed={collapsed}
            itemClassName={itemClassName}
            key={item.path}
            id={id}
            parentPath={parentPath}
            depth={depth}
            item={item}
          />
        )

        return s ? findItem(s, labelStr) && link : link
        /*return s
          ? [
              item.label.toLowerCase().includes(s.toLowerCase()),
              s.toLowerCase().includes(item.label.toLowerCase()),
            ].some((s) => s) && link
          : link*/
      })}
    </nav>
  )
}
