import './Divider.css'

import clsx from 'clsx'
import { ComponentPropsWithoutRef, ReactNode, useMemo } from 'react'

interface DividerProps {
  className?: string
  children?: ReactNode
  prefix?: ReactNode
  prefixContainerProps?: ComponentPropsWithoutRef<'div'>
  suffix?: ReactNode
  suffixContainerProps?: ComponentPropsWithoutRef<'div'>
  align?: 'start' | 'mid' | 'end'
  lineProps?: ComponentPropsWithoutRef<'div'>
}
export default function Divider({
  className,
  children,
  prefix,
  prefixContainerProps,
  suffix,
  suffixContainerProps,
  align = 'end',
  lineProps,
}: DividerProps) {
  const dividerLine = useMemo(() => {
    return <div className="divider-line" {...lineProps} />
  }, [lineProps])

  return (
    <div
      data-testid="divider"
      className={clsx('divider', `divider-${align}`, className)}
    >
      {prefix && (
        <div
          data-testid="divider-prefix"
          className="divider-prefix"
          {...prefixContainerProps}
        >
          {prefix}
        </div>
      )}
      {['start', 'mid'].includes(align) && dividerLine}
      {children && (
        <div data-testid="divider-text" className="divider-text">
          {children}
        </div>
      )}
      {['end', 'mid'].includes(align) && dividerLine}
      {suffix && (
        <div
          data-testid="divider-suffix"
          className="divider-suffix"
          {...suffixContainerProps}
        >
          {suffix}
        </div>
      )}
    </div>
  )
}
