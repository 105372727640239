import { Typography } from '@material-tailwind/react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

import FileCard from '@/components/FileManager/FileCard.tsx'
import Pagination from '@/components/Pagination'
import { QueryKeys } from '@/constants/query.ts'

export default function FileList({
  className,
  items,
  disabled,
  size,
  onItemClick,
  active,
  setParams,
  params,
  queryKey,
}: {
  setParams: SetState<ReqParams>
  params: ReqParams
  disabled?: boolean
  className?: string
  items?: PaginatedItems<FsItem>
  size?: 'md' | 'sm'
  onItemClick?: (item: FsItem, e: React.MouseEvent) => void
  active?: FsItem[]
  queryKey: QueryKeys
}) {
  return (
    <div>
      {items && items?.data.length > 0 ? (
        <div className={clsx('grid', twMerge('grid-cols-4 gap-8', className))}>
          {items.data.map((item, i) => {
            return (
              <FileCard
                queryKey={queryKey}
                params={params}
                active={active?.find((a) => a.key === item.key) !== undefined}
                onClick={
                  onItemClick
                    ? (e) => {
                        onItemClick(item, e)
                      }
                    : undefined
                }
                size={size}
                disabled={disabled}
                key={i}
                item={item}
              />
            )
          })}
        </div>
      ) : (
        <div className="cc min-h-[100px]">
          <Typography variant="lead">No records found</Typography>
        </div>
      )}
      {items?.page && items?.page.totalPages > 0 ? (
        <Pagination
          className="justify-center mt-8"
          current={items.page.current}
          total={items.page.totalPages}
          onChange={(page) => {
            setParams((prev) => {
              return {
                ...prev,
                page: page,
              }
            })
          }}
        />
      ) : null}
    </div>
  )
}
