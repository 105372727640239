import {
  ExtendedRefs,
  FloatingContext,
  MiddlewareData,
  ReferenceType,
  Strategy,
} from '@floating-ui/react'
import type { animation } from '@material-tailwind/react/types/generic'
import { createContext, type HTMLProps, ReactNode, RefObject } from 'react'

type PopoverContextValue = {
  open?: boolean
  strategy: Strategy
  x?: number
  y?: number
  context: FloatingContext<ReferenceType>
  refs: ExtendedRefs<ReferenceType>
  getReferenceProps: (userProps?: HTMLProps<Element>) => Record<string, unknown>
  getFloatingProps: (
    userProps?: HTMLProps<HTMLElement>,
  ) => Record<string, unknown>
  appliedAnimation: animation
  labelId: string
  descriptionId: string
  arrowRef?: RefObject<Element | undefined>
  middlewareData?: MiddlewareData
}
export interface PopoverContextProviderProps {
  value: PopoverContextValue
  children: ReactNode
}

export const PopoverContext = createContext<PopoverContextValue | null>(null)

export const PopoverContextProvider = ({
  value,
  children,
}: PopoverContextProviderProps) => {
  return (
    <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>
  )
}
