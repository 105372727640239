import { lazy } from 'react'

import { AdminChildrenPath } from '@/constants/constants.ts'

// eslint-disable-next-line react-refresh/only-export-components
const Page = lazy(
  () => import('@/pages/AdminPage/AdminSeatQuestionsPage/index.tsx'),
)

export default {
  path: AdminChildrenPath.SEAT_QUESTIONS,
  element: <Page />,
}
