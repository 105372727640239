import { Input, InputProps } from '@material-tailwind/react'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef } from 'react'

const InputWithHelp = forwardRef<
  HTMLInputElement,
  Omit<InputProps, 'ref'> & {
    help?: string
    helpProps?: ComponentPropsWithoutRef<'div'>
  }
>(function InputWithHelp({ help, helpProps, ...props }, ref) {
  return (
    <div className="min-w-0 w-full">
      <Input ref={ref} {...props} />
      {help && (
        <div
          {...helpProps}
          className={clsx(
            'text-body mt-1',
            props.error ? 'text-red-500' : 'text-blue-gray-500',
            helpProps?.className,
          )}
        >
          {help}
        </div>
      )}
    </div>
  )
})

export default InputWithHelp
