import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { QueryKeys } from '@/constants/query.ts'
import { splitFsParam } from '@/hooks/query/useFiles.ts'
import useIsQueryLoading from '@/hooks/query/useIsQueryLoading.ts'
import { useSearchAndParams } from '@/hooks/useSearchAndParams.ts'
import { getFsService } from '@/services/fs.ts'
import { Params } from '@/services/params.ts'

export default function useVideos(initialParams?: ReqParams) {
  const { params, ...restParams } = useSearchAndParams(initialParams || {})
  const query = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [QueryKeys.LsVideo, params],
    queryFn: async ({ queryKey: [, params] }) => {
      const { tags, or, rest } = splitFsParam(params as ReqParams)
      return getFsService().listVideo(Params.fromObject(rest), {
        tags,
        or,
      })
    },
    enabled: true,
  })

  const loading = useIsQueryLoading(query, restParams)

  return {
    ...query,
    ...restParams,
    params,
    loading,
  }
}
