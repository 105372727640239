import { Sym } from '@edclass/fe-ui'
import { TabPanel } from '@material-tailwind/react'
import { uniqBy } from 'lodash'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import { useFm } from '@/components/FileManager/hooks.ts'
import { FileManagerTab } from '@/components/FileManager/types.ts'

export function FileUploaderItem({
  file,
  onRemove,
}: {
  file: File
  onRemove: () => void
}) {
  return (
    <div
      title={file.name}
      className="text-body hover:border-blue-500 cursor-pointer flex-c-2 p-2 border border-white/20 rounded-md"
    >
      <div className="flex-c-2 text-white truncate w-full">
        <Sym className="!text-[20px]">description</Sym>
        <span className="truncate">{file.name}</span>
      </div>
      <button
        className="flex-shrink-0 hover:text-red-500"
        role="button"
        onClick={onRemove}
      >
        <Sym>close</Sym>
      </button>
    </div>
  )
}

export default function FileManagerUploaderPanel() {
  const { files, setFiles, removeFile, isUploading, accept } = useFm()
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const uniq = uniqBy([...files, ...acceptedFiles], (item) => item.name)
      setFiles(uniq)
    },
    [setFiles, files],
  )
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept })

  return (
    <TabPanel className="py-4 px-0" value={FileManagerTab.Uploader}>
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-4 gap-4">
          {files.map((f, i) => {
            return (
              <FileUploaderItem
                key={i}
                file={f}
                onRemove={() => {
                  removeFile(f)
                }}
              />
            )
          })}
        </div>
        {!isUploading && (
          <div
            {...getRootProps({
              className:
                'w-full border border-dashed border-white/60 rounded-md p-4 text-center',
            })}
          >
            <input {...getInputProps()} />
            <div className="flex flex-col items-center justify-center gap-4 p-4">
              <div className="rounded-full bg-blue-500/20 p-4 text-white">
                <Sym className="!text-[48px]">cloud_upload</Sym>
              </div>
              <span className="text-white">
                Drag and drop some files here, or{' '}
                <span className="text-blue-500">click to select files</span>
              </span>
            </div>
          </div>
        )}
      </div>
    </TabPanel>
  )
}
