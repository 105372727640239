import { lazy } from 'react'

import {
  AdminChildrenPath,
  AdminCrudPath,
  AdminSubjectChildrenPath,
} from '@/constants/constants'

// eslint-disable-next-line react-refresh/only-export-components
const Page = lazy(() => import('@/pages/AdminPage/AdminSubjectsPage/index'))
// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(
  () => import('@/pages/AdminPage/AdminSubjectsPage/AdminSubjectsIndexPage'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Create = lazy(
  () => import('@/pages/AdminPage/AdminSubjectsPage/AdminSubjectsCreatePage'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Edit = lazy(
  () => import('@/pages/AdminPage/AdminSubjectsPage/AdminSubjectsEditPage'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Archive = lazy(
  () => import('@/pages/AdminPage/AdminSubjectsPage/AdminSubjectsArchivePage'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Legacy = lazy(
  () => import('@/pages/AdminPage/AdminSubjectsPage/AdminSubjectsLegacyPage'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Detail = lazy(
  () => import('@/pages/AdminPage/AdminSubjectsPage/AdminSubjectsDetailPage'),
)

export default {
  path: AdminChildrenPath.SUBJECTS,
  element: <Page />,
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: AdminCrudPath.CREATE,
      element: <Create />,
    },
    {
      path: `:id/${AdminCrudPath.EDIT}`,
      element: <Edit />,
    },
    {
      path: 'archives',
      element: <Archive />,
    },
    {
      path: AdminSubjectChildrenPath.LEGACY,
      element: <Legacy />,
    },
    {
      path: `:id/${AdminCrudPath.DETAIL}`,
      element: <Detail />,
    },
  ],
}
