import './AlertsDashboard.css'

import {
  DndContext,
  DragOverEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { useState } from 'react'

import Category from '@/components/AlertsDashboard/Category'

import type { Alert, AlertColumn, Alerts } from './types'

type AlertsProps = {
  items: Alerts
}

type UniqueIdentifier = string | number

const defaultAlertColumns: AlertColumn[] = [
  { id: 'new_alerts', label: 'new alerts', type: 'type' },
  { id: 'current_alerts', label: 'current alerts', type: '\u00A0' },
  { id: 'closed_alerts', label: 'closed alerts', type: 'type / chat' },
]

export default function Container({ items }: AlertsProps) {
  const [alerts, setAlerts] = useState<Alerts>(items)
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  )

  function moveAlert(
    alert: Alert,
    containerId: UniqueIdentifier,
    to: string | number,
  ) {
    const alertType = alert.type as keyof Alerts
    const selectedIndex = alerts[alertType]?.findIndex(
      (t: Alert) => t.id === containerId,
    )

    // move the alert to the target column
    alerts[to as keyof Alerts].push(alert)
    alerts[alertType].splice(selectedIndex, 1)

    // change the type of the selected alert
    alert.type = to as keyof Alerts

    setAlerts((alerts) => {
      return { ...alerts }
    })
  }

  function onDragOver(e: DragOverEvent) {
    const { active, over } = e
    const selectedAlert = active.data.current?.alert

    // moving to an empty container | allow only moving to the right column
    if (over === null) {
      const idx = defaultAlertColumns.findIndex(
        (c) => c.id === selectedAlert.type,
      )

      // @INFO: restrict going-back to previous column (only when target column is empty)
      if (idx >= defaultAlertColumns.length - 1) {
        return
      }

      return moveAlert(
        selectedAlert,
        active.id,
        defaultAlertColumns[idx + 1]?.id,
      )
    }

    const overAlert = over.data.current?.alert
    const selectedContainerId = active.data.current?.sortable?.containerId
    const overContainerId = over.data.current?.sortable?.containerId
    const selectedType = selectedAlert.type as keyof Alerts
    const overAlertType = overAlert.type as keyof Alerts

    // Sorting on the same column
    if (selectedContainerId === overContainerId) {
      setAlerts((alerts) => {
        const activeIndex = alerts[selectedType].findIndex(
          (a: Alert) => a.id === active.id,
        )
        const overIndex = alerts[overAlertType].findIndex(
          (a: Alert) => a.id === over.id,
        )

        // change the type of the selected alert
        selectedAlert.type = overAlert.type

        alerts[overAlertType] = arrayMove(
          alerts[overAlertType],
          activeIndex,
          overIndex,
        )

        return { ...alerts }
      })
    }

    if (selectedContainerId !== overContainerId) {
      // Moving from one column to another
      moveAlert(selectedAlert, active.id, overAlert.type)
    }
  }

  return (
    <DndContext sensors={sensors} onDragOver={onDragOver}>
      <div className="alerts-dashboard__container">
        {defaultAlertColumns.map((column) => (
          <Category
            key={column.id}
            label={column.label}
            type={column.type}
            items={alerts[column.id as keyof Alerts]}
          />
        ))}
      </div>
    </DndContext>
  )
}
