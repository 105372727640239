import {
  FileManagerTab,
  FileManagerTabFlag,
} from '@/components/FileManager/types.ts'

export function tabToFlag(tab: FileManagerTab): FileManagerTabFlag {
  switch (tab) {
    case FileManagerTab.Browser:
      return FileManagerTabFlag.Browser
    case FileManagerTab.Uploader:
      return FileManagerTabFlag.Uploader
    case FileManagerTab.AI:
      return FileManagerTabFlag.AI
  }
}
