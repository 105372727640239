import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

export default function AdminAlertsPage() {
  return (
    <div className="admin-alerts-page">
      <Suspense fallback={<></>}>
        <Outlet />
      </Suspense>
    </div>
  )
}
