import axios, { AxiosRequestConfig, HeadersDefaults } from 'axios'

import { IS_DEBUG } from '@/constants/constants.ts'

export type HttpError = Record<string, Record<string, unknown>>

//axios.defaults.withCredentials = !import.meta.env.DEV
//axios.defaults.withCredentials = true
export class Http {
  private axios
  private readonly baseUrl: string
  private readonly response: Record<string, never> | undefined = undefined
  private errorCb?: (e: HttpError) => void
  setErrorCb(cb: (e: HttpError) => void) {
    this.errorCb = cb
  }

  constructor(options: {
    baseUrl: null | string
    withCredentials?: boolean
    response?: Record<string, never>
    headers?: Record<string, string>
  }) {
    this.baseUrl = options.baseUrl || import.meta.env.VITE_API_URL
    this.axios = axios.create({
      baseURL: this.baseUrl,
      withCredentials: options.withCredentials ?? true,
      //withCredentials: !import.meta.env.DEV,
      headers: {
        ...options?.headers,
        Accept: 'application/json',
      },
    })
    this.response = options.response
  }

  setHeaders(headers: Record<string, string>) {
    this.axios.defaults.headers = {
      ...this.axios.defaults.headers,
      ...headers,
    }
  }

  setKeyedHeaders(key: keyof HeadersDefaults, headers: Record<string, string>) {
    this.axios.defaults.headers[key] = {
      ...this.axios.defaults.headers[key],
      ...headers,
    }
  }

  getResponse() {
    return this.response
  }

  async get(url: string, config: AxiosRequestConfig = {}) {
    try {
      this.log('GET', url)
      return await this.axios.get(url, config)
    } catch (error) {
      throw this.handleError(error as HttpError)
    }
  }

  async post(url: string, data = {}, config: AxiosRequestConfig = {}) {
    try {
      this.log('POST', url, { data, config })
      return await this.axios.post(url, data, config)
    } catch (error) {
      throw this.handleError(error as Record<string, Record<string, unknown>>)
    }
  }

  async put(url: string, data = {}, config: AxiosRequestConfig = {}) {
    try {
      this.log('PUT', url, { data, config })
      return await this.axios.put(url, data, config)
    } catch (error) {
      throw this.handleError(error as HttpError)
    }
  }

  async patch(url: string, data = {}, config: AxiosRequestConfig = {}) {
    try {
      this.log('PATCH', url, { data, config })
      return await this.axios.patch(url, data, config)
    } catch (error) {
      throw this.handleError(error as HttpError)
    }
  }

  async delete(url: string, config: AxiosRequestConfig = {}) {
    try {
      this.log('DELETE', url, { config })
      return await this.axios.delete(url, config)
    } catch (error) {
      throw this.handleError(error as HttpError)
    }
  }

  /**
   * uploading `input[type="file"]` within multipart form data
   * @param url
   * @param file
   * @param config
   */
  async upload(
    url: string,
    file: File | FileList | File[],
    config?: AxiosRequestConfig,
  ) {
    try {
      const formData = new FormData()
      if (file instanceof File) {
        formData.append('file', file)
      } else {
        for (let i = 0; i < file.length; i++) {
          formData.append('file', file[i])
        }
      }

      console.log('formData entries', Array.from(formData.entries()))
      this.log('UPLOAD', url, {
        config,
      })
      return await this.axios.put(url, formData, {
        ...config,
        headers: {
          ...config?.headers,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
    } catch (error) {
      throw this.handleError(error as HttpError)
    }
  }
  /**
   * log request body
   *
   * @param mode
   * @param url
   * @param data
   * @private
   */
  private log(mode: string, url: string, data?: unknown) {
    if (IS_DEBUG) {
      console.info(`[HTTP-LOG]: ${this.baseUrl} [${mode}] => ${url}`, data)
    }
  }

  handleError(error: HttpError) {
    if (error.response) {
      this.errorCb?.(error)
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data
    } else if (error.request) {
      // The request was made but no response was received
      return {
        message: 'No response received from the server.',
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      return {
        message: 'An error occurred while setting up the request.',
      }
    }
  }
}
