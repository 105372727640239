import {
  KeyboardSensor,
  MouseSensor,
  PointerActivationConstraint,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { Coordinates } from '@dnd-kit/utilities'
import { useState } from 'react'

export default function useDraggableCoordinate(
  defaultCoordinates: Coordinates = {
    x: 0,
    y: 0,
  },
  activationConstraint?: PointerActivationConstraint,
) {
  const [{ x, y }, setCoordinates] = useState<Coordinates>(defaultCoordinates)
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint,
  })
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint,
  })
  const keyboardSensor = useSensor(KeyboardSensor, {})
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor)

  return {
    x,
    y,
    setCoordinates,
    sensors,
  }
}
