import { addFlag, hasFlag } from '@edclass/fe-common'
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  Typography,
} from '@material-tailwind/react'
import { useState } from 'react'

import { getRecorderMedia } from '@/helpers/media.ts'
import { RecordMediaFlag } from '@/helpers/safeguarding.ts'

export default function EnableCameraModal({
  flags,
  onChange,
}: {
  // current saved flags
  flags: RecordMediaFlag
  onChange: (nextFlags: RecordMediaFlag) => void
}) {
  const [loading, setLoading] = useState(false)

  return !hasFlag(flags, RecordMediaFlag.Camera) ? (
    <Dialog
      size="sm"
      open
      handler={() => {
        //
      }}
    >
      <DialogHeader>Required Action</DialogHeader>
      <DialogBody divider>
        <div className="px-8 py-4 flex flex-col gap-8">
          <Typography className="text-center" variant="lead">
            In order to use the app we need access to your camera
          </Typography>
          <div className="flex justify-center">
            <Button
              loading={loading}
              onClick={() => {
                setLoading(true)
                getRecorderMedia('camera_and_mic').then(() => {
                  onChange(addFlag(flags, RecordMediaFlag.Camera))
                  setLoading(false)
                })
              }}
            >
              Enable Camera
            </Button>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  ) : null
}
