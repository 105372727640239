import { useEffect } from 'react'

import { AlertsDashboard } from '@/components/AlertsDashboard/index.tsx'
import useAppContext from '@/hooks/useAppProvider.ts'
import useMountStatus from '@/hooks/useMountStatus.ts'
import { DUMMY_ALERTS } from '@/pages/AdminPage/AdminAlertsPage/dummy'

export default function AdminAlertsIndexPage() {
  const { setTitle } = useAppContext()
  const mounted = useMountStatus(50)

  useEffect(() => {
    if (mounted) {
      setTitle('Alerts')
    }
  }, [setTitle, mounted])

  return (
    <div id="admin-alerts">
      <AlertsDashboard items={DUMMY_ALERTS} />
    </div>
  )
}
