import { AxiosResponse } from 'axios'

import { Http, HttpError } from '@/helpers/http.ts'

export abstract class BaseService {
  protected abstract _client: Http

  errorCb?: (e: HttpError) => void
  protected getError(e: unknown) {
    if (typeof e !== 'object' && e !== null) {
      return Promise.reject({
        message: e,
      })
    }

    return Promise.reject({
      message: (e as { message: string })?.message ?? '',
      errors: (e as { errors: unknown })?.errors,
    })
  }

  setErrorCb(cb: (e: HttpError) => void) {
    this._client.setErrorCb(cb)
  }

  protected async send<T>(promise: Promise<AxiosResponse<T>>) {
    try {
      const res = await promise

      if (res.status >= 200) {
        const maybeWrapped = res.data as {
          success: boolean
          data: T
          message: string
        }
        return (maybeWrapped.success !== undefined ||
          maybeWrapped.message !== undefined) &&
          maybeWrapped.data
          ? maybeWrapped.data
          : (res.data as T)
      } else {
        return this.getError(res.data)
      }
    } catch (e) {
      return this.getError(e)
    }
  }
}
