import { createContext, ReactNode, useState } from 'react'

import { RecordMediaFlag } from '@/helpers/safeguarding.ts'
import useContextOrThrow from '@/hooks/useContextOrThrow.ts'
import { TeacherPageContextValue } from '@/pages/TeacherPage/types.ts'

const Context = createContext<TeacherPageContextValue | null>(null)
Context.displayName = 'TeacherPageContext'

// eslint-disable-next-line react-refresh/only-export-components
export function useTeacherPage() {
  return useContextOrThrow(Context)
}

export function TeacherPageProvider({ children }: { children: ReactNode }) {
  const [recordingFlag, setRecordingFlag] = useState<RecordMediaFlag>(
    RecordMediaFlag.None,
  )
  return (
    <Context.Provider
      value={{
        recordingFlag,
        setRecordingFlag,
      }}
    >
      {children}
    </Context.Provider>
  )
}
