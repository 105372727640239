import { lazy } from 'react'

import { PathKind } from '@/helpers/path'
import alertsRoute from '@/pages/AdminPage/AdminAlertsPage/route'
import curriculumsRoute from '@/pages/AdminPage/AdminCurriculumsPage/route'
import elementBuilderRoute from '@/pages/AdminPage/AdminElementBuilderPage/route'
import exBoardRoute from '@/pages/AdminPage/AdminExBoardsPage/route'
import adminImportRoute from '@/pages/AdminPage/AdminImportPage/route'
import logsRoute from '@/pages/AdminPage/AdminLogsPage/route'
import pathwaysRoute from '@/pages/AdminPage/AdminPathwaysPage/route'
import schoolsRoute from '@/pages/AdminPage/AdminSchoolsPage/route'
import seatQuestionsRoute from '@/pages/AdminPage/AdminSeatQuestionsPage/route'
import skillsRoute from '@/pages/AdminPage/AdminSkillsPage/route'
import staffsRoute from '@/pages/AdminPage/AdminStaffsPage/route'
import storageRoute from '@/pages/AdminPage/AdminStoragePage/route'
import subjectsRoute from '@/pages/AdminPage/AdminSubjectsPage/route'
import topicsRoute from '@/pages/AdminPage/AdminTopicsPage/route'
import edSupportRoute from '@/pages/TeacherPage/TeacherEDTeachPage/route'

// eslint-disable-next-line react-refresh/only-export-components
const Page = lazy(() => import('@/pages/AdminPage/index'))
// eslint-disable-next-line react-refresh/only-export-components
const Dashboard = lazy(() => import('@/pages/AdminPage/AdminDashboardPage'))
export default {
  path: PathKind.Admin,
  element: <Page />,
  children: [
    {
      index: true,
      element: <Dashboard />,
    },
    curriculumsRoute,
    schoolsRoute,
    subjectsRoute,
    skillsRoute,
    exBoardRoute,
    topicsRoute,
    alertsRoute,
    pathwaysRoute,
    staffsRoute,
    edSupportRoute,
    logsRoute,
    elementBuilderRoute,
    storageRoute,
    adminImportRoute,
    seatQuestionsRoute,
  ],
}
