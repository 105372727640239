import { NavLink, NavLinkProps } from 'react-router-dom'

import useAppContext from '@/hooks/useAppProvider.ts'

//import useGlobalContext from '@/hooks/useGlobalContext.ts'

export default function LoggedNavLink(props: NavLinkProps) {
  //const { logger } = useGlobalContext()
  const { sendEventMessage } = useAppContext()
  return (
    <NavLink
      {...props}
      onClick={() => {
        sendEventMessage({
          kind: 'navigation',
          uri: props.to.toString(),
        })
        /*logger.current.logNavigation({
          uri: props.to.toString(),
        })*/
      }}
    />
  )
}
