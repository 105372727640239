import { getRandomInt } from '@edclass/fe-common'

const YEARS = Array.from(Array(10).keys()).map((i) => {
  return `Year 202${i}`
})

export const DUMMY_CURRICULUMS: (Curriculum & { years: string[] })[] =
  Array.from(Array(10).keys()).map((i) => {
    return {
      id: `${i + 1}`,
      name: `Curriculums ${i + 1}`,
      years: YEARS.slice(getRandomInt(0, YEARS.length)),
      createdAt: new Date(),
      updatedAt: new Date(),
    }
  })
