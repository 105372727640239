import './FileManagerBrowserPanel.css'

import { Sym } from '@edclass/fe-ui'
import { Spinner, TabPanel } from '@material-tailwind/react'

import FileList from '@/components/FileManager/FileList.tsx'
import { useFm } from '@/components/FileManager/hooks.ts'
import { FileManagerTab } from '@/components/FileManager/types.ts'
import { QueryKeys } from '@/constants/query.ts'
import useAudios from '@/hooks/query/useAudios.ts'
import useFiles from '@/hooks/query/useFiles.ts'
import useImages from '@/hooks/query/useImages.ts'
import useVideos from '@/hooks/query/useVideos.ts'
import useSearch from '@/hooks/useSearch.ts'

function ImageImpl() {
  const props = useImages()
  return <TabImpl {...props} />
}

function VideoImpl() {
  const props = useVideos()
  return <TabImpl {...props} />
}

function AudioImpl() {
  const props = useAudios()
  return <TabImpl {...props} />
}

function AllImpl() {
  const props = useFiles()
  return <TabImpl {...props} />
}

function TabImpl({
  data,
  loading,
  setParams,
  params,
}: ReturnType<typeof useImages>) {
  const { selected, toggleSelected } = useFm()
  const { search, setSearch } = useSearch()

  return (
    <TabPanel className="p-0" value={FileManagerTab.Browser}>
      <div className="flex items-center h-10 border rounded-md ps-2 mt-4 border-white/20">
        <Sym>search</Sym>
        <input
          type="text"
          value={search || ''}
          onChange={(e) => {
            const val = e.currentTarget.value
            setSearch(val)
            //setSearch(val)
          }}
          className="text-main text-white bg-transparent border-0 h-10 px-2 w-full min-w-0 focus:outline-none focus-visible:outline-none"
          placeholder="Search"
        />
      </div>
      <div className="py-4">
        {loading ? (
          <div className="flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <FileList
            active={selected}
            size="sm"
            disabled
            className="max-h-[50vh] overflow-y-auto overflow-x-hidden"
            items={data}
            setParams={setParams}
            onItemClick={toggleSelected}
            params={params}
            queryKey={QueryKeys.LsFile}
          />
        )}
      </div>
    </TabPanel>
  )
}

export default function FileManagerBrowserPanel() {
  const { contentType } = useFm()
  switch (contentType) {
    case 'image':
      return <ImageImpl />
    case 'audio':
      return <AudioImpl />
    case 'video':
      return <VideoImpl />
    default:
      return <AllImpl />
  }
}

/*
to important to throw away
this grid make things load like folder structure in explorer
<div
            ref={containerRef}
            style={{
              gridAutoFlow: 'column',
              gridTemplateRows: `repeat(${numItemsPerRow}, 1fr)`,
              gridTemplateColumns: `repeat(2, 1fr)`,
            }}
            //className="grid grid-flow-col grid-rows-[var(--fm-grid-rows)] grid-cols-[var(--fm-grid-cols)] gap-2 max-h-[600px] overflow-y-auto"
            className="grid gap-x-8 h-[50vh] overflow-y-hidden overflow-x-auto"
          >
          {data?.data.map((d) => {
              return (
                <FileManagerItem
                  params={params}
                  setParams={setParams}
                  bucket={bucket}
                  key={d.key}
                  item={d}
                />
              )
            })}
          </div>*/
