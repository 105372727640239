import {
  FloatingFocusManager,
  FloatingPortal,
  useMergeRefs,
} from '@floating-ui/react'
import { useTheme } from '@material-tailwind/react'
import { NewAnimatePresenceProps } from '@material-tailwind/react/types/generic'
import objectsToString from '@material-tailwind/react/utils/objectsToString'
import clsx from 'clsx'
import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion'
import { ComponentProps, CSSProperties, FC, forwardRef, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import usePopover from '@/components/Popover/hooks.ts'
export interface PopoverContentProps extends ComponentProps<'div'> {
  className?: string
  children: ReactNode
  arrowStyle?: CSSProperties
  color?: string
  tag?: 'ul' | 'div'
}

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  function PopoverContent(
    { children, color, tag, style, className, arrowStyle, ...rest },
    ref,
  ) {
    const { popover } = useTheme()
    const {
      defaultProps,
      styles: { base },
    } = popover
    const {
      open,
      strategy,
      x,
      y,
      context,
      refs,
      getFloatingProps,
      appliedAnimation,
      labelId,
      descriptionId,
      arrowRef,
      middlewareData,
    } = usePopover()

    // 2. set default props
    className = twMerge(defaultProps.className || '', className)

    // 3. set styles
    const popoverClasses = twMerge(clsx(objectsToString(base)), className)

    // 4. set refs
    const mergedRef = useMergeRefs([ref, refs.floating])

    // 5. Create an instance of AnimatePresence because of the types issue with the children
    const NewAnimatePresence: FC<NewAnimatePresenceProps> = AnimatePresence

    const TagEl = tag === 'ul' ? m.ul : m.div

    // 6. return
    return (
      <LazyMotion features={domAnimation}>
        <FloatingPortal root={document.getElementById('portal')}>
          <NewAnimatePresence>
            {open && (
              <FloatingFocusManager context={context} modal={true}>
                <TagEl
                  data-testid="popover-content"
                  {...getFloatingProps({
                    ...rest,
                    ref: mergedRef,
                    className: popoverClasses,
                    style: {
                      position: strategy,
                      top: y ?? '',
                      left: x ?? '',
                      ...style,
                    },
                    'aria-labelledby': labelId,
                    'aria-describedby': descriptionId,
                  })}
                  initial="unmount"
                  exit="unmount"
                  animate={open ? 'mount' : 'unmount'}
                  variants={appliedAnimation}
                >
                  {children}
                  {arrowRef && middlewareData?.arrow && (
                    <div
                      data-testid="popover-arrow"
                      ref={arrowRef as never}
                      className={clsx(
                        'w-3 h-3 absolute translate-y-[-0.5rem] rotate-45',
                        color,
                      )}
                      style={{
                        left:
                          middlewareData.arrow.x &&
                          `${middlewareData.arrow.x}px`,
                        top:
                          middlewareData.arrow.y &&
                          `${middlewareData.arrow.y}px`,
                        ...arrowStyle,
                      }}
                    ></div>
                  )}
                  {/*arrowRef && (
                    <FloatingArrow ref={arrowRef as never} context={context} />
                  )*/}
                </TagEl>
              </FloatingFocusManager>
            )}
          </NewAnimatePresence>
        </FloatingPortal>
      </LazyMotion>
    )
  },
)

export default PopoverContent
