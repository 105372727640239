import { lazy } from 'react'

import {
  AdminChildrenPath,
  AdminLogChildrenPath,
} from '@/constants/constants.ts'
import AdminLogsIndexPage from '@/pages/AdminPage/AdminLogsPage/AdminLogsIndexPage.tsx'
import AdminLogsPage from '@/pages/AdminPage/AdminLogsPage/index.tsx'

// eslint-disable-next-line react-refresh/only-export-components
const Reports = lazy(
  () => import('@/pages/AdminPage/AdminLogsPage/AdminReportsPage/index.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Stats = lazy(
  () => import('@/pages/AdminPage/AdminLogsPage/AdminStatsPage/index.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const SeatBreakdown = lazy(
  () =>
    import('@/pages/AdminPage/AdminLogsPage/AdminLogsSeatBreakdownPage.tsx'),
)

export default {
  path: AdminChildrenPath.LOGS,
  element: <AdminLogsPage />,
  children: [
    {
      index: true,
      element: <AdminLogsIndexPage />,
    },
    {
      path: AdminLogChildrenPath.STATS,
      element: <Stats />,
    },
    {
      path: AdminLogChildrenPath.REPORTS,
      element: <Reports />,
    },
    {
      path: AdminLogChildrenPath.SEAT_BREAKDOWN,
      element: <SeatBreakdown />,
    },
  ],
}
