import { useContext } from 'react'

import { RepeaterContext } from '@/components/Repeater/RepeaterContext.tsx'

export function useRepeater() {
  const context = useContext(RepeaterContext)
  if (context === null) {
    throw Error('must use within RepeaterContext.Provider')
  }

  return context
}
