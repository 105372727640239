import { Sym, SymProps } from '@edclass/fe-ui'

import { SubjectKind } from '@/constants/constants.ts'

export default function SubjectIcon({
  subject,
  iconProps,
}: {
  subject: SubjectKind
  iconProps?: Omit<SymProps, 'children'>
}) {
  /*
  let Comp
  switch (subject) {
    case 'geography':
      Comp = GlobeStandIcon
      break
    case 'science':
      Comp = FlaskIcon
      break
    case 'history':
      Comp = RectangleHistoryIcon
      break
    case 'math':
      Comp = CalculatorIcon
      break
    case 'i.t':
      Comp = LaptopCodeIcon
      break
    case 'sport':
      Comp = BasketballHoopIcon
      break
    case 'religion':
      Comp = HandsPrayingIcon
      break
    case 'english':
      Comp = BookIcon
      break
    case 'lunch':
      Comp = BurgerSodaIcon
      break
    default:
      return null
  }

  return <Comp {...iconProps} />
   */

  let icon
  switch (subject) {
    case 'lunch':
      icon = 'dinner_dining'
      break
    case 'geography':
      icon = 'globe'
      break
    case 'science':
      icon = 'experiment'
      break
    case 'english':
      icon = 'book_2'
      break
    case 'history':
      icon = 'history_edu'
      break
    case 'math':
      icon = 'calculate'
      break
    case 'sport':
      icon = 'sports_and_outdoors'
      break
    case 'i.t':
      icon = 'terminal'
      break
    case 'religion':
      icon = 'folded_hands'
      break
  }

  return <Sym {...iconProps}>{icon}</Sym>
}
