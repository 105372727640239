import { Sym } from '@edclass/fe-ui'
import { MenuItem, Spinner } from '@material-tailwind/react'
import clsx from 'clsx'
import { forwardRef, MouseEventHandler } from 'react'
import { twMerge } from 'tailwind-merge'

const MoreDropdownItem = forwardRef<
  HTMLButtonElement,
  {
    icon: string
    children?: string
    onClick?: MouseEventHandler
    className?: string
    loading?: boolean
  } & Omit<typeof MenuItem, '$$typeof'>
>(function MoreDropdownItem(
  { icon, children, loading, onClick, className, ...rest },
  ref,
) {
  return (
    <MenuItem
      ref={ref}
      className={clsx(
        twMerge('!text-body !flex-c-2 active:!bg-transparent', className),
      )}
      onClick={onClick}
      {...rest}
    >
      {loading ? (
        <div className="w-6 h-6 grid place-items-center">
          <Spinner className="w-4 h-4" />
        </div>
      ) : (
        <Sym className="!text-[20px]">{icon}</Sym>
      )}
      <span>{children}</span>
    </MenuItem>
  )
})

export default MoreDropdownItem
