import './HeaderMenu.css'

import { Sym } from '@edclass/fe-ui'
import { Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react'
import clsx from 'clsx'

import LoggedNavLink from '@/components/Link/LoggedNavLink.tsx'
import StackedAvatar from '@/components/Stacked/StackedAvatar.tsx'
import { EDIcon, MY_ACCOUNT_PATH } from '@/constants/constants.ts'
import useAppContext from '@/hooks/useAppProvider.ts'
import useAuthContext from '@/hooks/useAuthProvider.ts'
import useLogout from '@/hooks/useLogout.tsx'
import {
  NotificationProvider,
  useNotification,
} from '@/providers/NotificationProvider'

function NotificationMenu() {
  const { notifications, unreadNotifications, markRead } = useNotification()
  return (
    <Menu placement="bottom-start">
      <MenuHandler>
        <button className="cc relative w-[42px] h-[42px] hover:bg-blue-500 rounded-full transition-colors">
          {unreadNotifications > 0 && (
            <span className="absolute cc z-[3] top-[-0.1rem] min-w-[20px] right-[-0.25rem] bg-red-500 font-bold font-mono text-body py-0.25 px-1 rounded-full">
              {unreadNotifications}
            </span>
          )}
          <Sym className="!text-[32px]">{EDIcon.Notification}</Sym>
        </button>
      </MenuHandler>
      <MenuList className="w-[300px] max-h-[300px] overflow-y-auto">
        {notifications.length > 0 ? (
          notifications.map((n, i) => {
            return (
              <MenuItem
                className={clsx(
                  n.isRead && '!text-blue-gray-500 hover:bg-black/30',
                  'text-body',
                )}
                key={`${n.id}-${i}`}
                onClick={() => {
                  markRead(n)
                }}
              >
                {n.message}
              </MenuItem>
            )
          })
        ) : (
          <div className="p-2">No notifications found</div>
        )}
      </MenuList>
    </Menu>
  )
}

export default function HeaderMenu() {
  const logout = useLogout()
  const { user } = useAuthContext()
  const { getWebSocket } = useAppContext()
  return (
    <div className="flex-c gap-4">
      <NotificationProvider>
        <NotificationMenu />
      </NotificationProvider>
      {user && (
        <Menu>
          <MenuHandler>
            <button className="cc">
              <StackedAvatar
                noBorder
                fullSize
                size="42px"
                color="text-white/70 hover:bg-blue-500 hover:text-white "
                src={user?.picture}
              />
            </button>
          </MenuHandler>
          <MenuList>
            <MenuItem aria-label="Profile">
              <LoggedNavLink
                className="flex-c-2"
                to={`${MY_ACCOUNT_PATH}/profile`}
              >
                <Sym>account_box</Sym>
                <span>Profile</span>
              </LoggedNavLink>
            </MenuItem>
            <MenuItem
              className="!flex-c-2"
              onClick={() => alert('coming soon')}
              aria-label="Inbox"
            >
              <Sym>mail</Sym>
              <span>Inbox</span>
            </MenuItem>
            <MenuItem
              className="!flex-c-2"
              onClick={() => {
                const ws = getWebSocket()
                if (ws?.readyState === 1) {
                  console.log('close ws connection')
                  ws?.close()
                }
                logout().catch(console.error)
              }}
              aria-label="Logout"
            >
              <Sym>logout</Sym>
              <span>Logout</span>
            </MenuItem>
          </MenuList>
        </Menu>
      )}

      {/*
        <LoggedNavLink to={BASE_PATH ?? '/'}>
        <Sym>home</Sym>
      </LoggedNavLink>
      <LoggedNavLink to={`${BASE_PATH}/mail`}>
        <Sym>email</Sym>
      </LoggedNavLink>
      <LoggedNavLink to={`${BASE_PATH}/star`}>
        <Sym>star</Sym>
      </LoggedNavLink>
         */}
      {/*
      <button>
        <Sym>search</Sym>
      </button>*/}
    </div>
  )
}
