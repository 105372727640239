// @floating-ui
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset as fuiOffset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react'
import { useTheme } from '@material-tailwind/react'
import type {
  animate,
  dismiss,
  handler,
  interactive,
  offset,
  placement,
} from '@material-tailwind/react/types/components/popover'
import { NewAnimatePresenceProps } from '@material-tailwind/react/types/generic'
import objectsToString from '@material-tailwind/react/utils/objectsToString'
import clsx from 'clsx'
import merge from 'deepmerge'
import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion'
import { FC, forwardRef, ReactNode, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export interface TooltipProps {
  open?: boolean
  handler?: handler
  content?: ReactNode
  interactive?: interactive
  placement?: placement
  offset?: offset
  dismiss?: dismiss
  animate?: animate
  className?: string
  children: ReactNode
  floatingClassName?: string
}

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(function Tooltip(
  {
    open,
    handler,
    content,
    interactive,
    placement,
    offset,
    dismiss,
    animate,
    className,
    children,
    floatingClassName,
    ...rest
  },
  ref,
) {
  // 1. init
  const { tooltip } = useTheme()
  const {
    defaultProps,
    styles: { base },
  } = tooltip
  const [internalOpen, setInternalOpen] = useState(false)

  // 2. set default props
  open = open ?? internalOpen
  handler = handler ?? setInternalOpen
  interactive = interactive ?? defaultProps.interactive
  placement = placement ?? defaultProps.placement
  offset = offset ?? defaultProps.offset
  dismiss = dismiss ?? defaultProps.dismiss
  animate = animate ?? defaultProps.animate
  className = className ?? defaultProps.className

  // 3. set styles
  const tooltipClasses = twMerge(
    clsx(objectsToString(base), 'floating-tip'),
    className,
  )

  // 4. set animation
  const animation = {
    unmount: {
      opacity: 0,
    },
    mount: {
      opacity: 1,
    },
  }
  const appliedAnimation = merge(animation, animate ?? {})

  // 5. set @floating-ui
  const { x, y, strategy, refs, update, context } = useFloating({
    open,
    onOpenChange: handler,
    middleware: [fuiOffset(offset), flip(), shift()],
    placement,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, {
      enabled: interactive,
    }),
    useFocus(context),
    useHover(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context, dismiss),
  ])

  useEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update)
    }
  }, [open, update, refs.reference, refs.floating])

  const mergedRef = useMergeRefs([ref, refs.setFloating])
  const childMergedRef = useMergeRefs([ref, refs.setReference])

  // 6. Create an instance of AnimatePresence because of the types issue with the children
  const NewAnimatePresence: FC<NewAnimatePresenceProps> = AnimatePresence

  // 7. return
  return (
    <>
      {typeof children === 'string' ? (
        <span
          {...getReferenceProps({
            ref: childMergedRef,
            className: clsx('floating-ref', floatingClassName),
          })}
        >
          {children}
        </span>
      ) : (
        <div
          {...getReferenceProps({
            ref: childMergedRef,
            className: clsx('floating-ref', floatingClassName),
          })}
        >
          {children}
          {/*cloneElement(children, {
            ...getReferenceProps({
              ...children?.props,
              ref: childMergedRef,
            }),
          })*/}
        </div>
      )}
      <LazyMotion features={domAnimation}>
        <FloatingPortal root={document.getElementById('portal')}>
          <NewAnimatePresence>
            {open && (
              <m.div
                {...getFloatingProps({
                  ...rest,
                  ref: mergedRef,
                  className: tooltipClasses,
                  style: {
                    position: strategy,
                    top: y ?? '',
                    left: x ?? '',
                  },
                })}
                initial="unmount"
                exit="unmount"
                animate={open ? 'mount' : 'unmount'}
                variants={appliedAnimation}
              >
                {content}
              </m.div>
            )}
          </NewAnimatePresence>
        </FloatingPortal>
      </LazyMotion>
    </>
  )
})

export default Tooltip
