import { TeacherChildrenPath } from '@/constants/constants.ts'

export const EDSUPPORT_CHILDREN_PATH = {
  SUBJECT_OVERVIEW: 'subject-overview',
  DETAIL: 'detail',
}
export default {
  path: TeacherChildrenPath.EDTEACH,
  lazy: async () => {
    const { default: Component } = await import(
      '@/pages/TeacherPage/TeacherEDTeachPage/index.tsx'
    )
    return { Component }
  },
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: Component } = await import(
          '@/pages/TeacherPage/TeacherEDTeachPage/TeacherEDTeachIndexPage.tsx'
        )
        return { Component }
      },
    },
    {
      path: `${EDSUPPORT_CHILDREN_PATH.DETAIL}/:id`,
      lazy: async () => {
        const { default: Component } = await import(
          '@/pages/TeacherPage/TeacherEDTeachPage/TeacherEDTeachDetailPage.tsx'
        )
        return { Component }
      },
    },
  ],
}
