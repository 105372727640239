import './TimetableSheet.css'

import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Timeline } from '@edclass/fe-common'
import { Context, useEffect, useRef, useState } from 'react'

import TimetableSheetContainer from '@/components/TimetableSheet/TimetableSheetContainer'
import {
  TimetableSheetContext,
  TimetableSheetContextValue,
  TimetableSheetValue,
  WithUniqueId,
} from '@/components/TimetableSheet/TimetableSheetContext'
import TimetableSheetDnd from '@/components/TimetableSheet/TimetableSheetDnd'
import { TimetableSheetSortableItem } from '@/components/TimetableSheet/TimetableSheetSortableItem.tsx'

type TimetableSheetProps<T extends WithUniqueId> = Pick<
  TimetableSheetContextValue<T>,
  | 'items'
  | 'id'
  | 'onChange'
  | 'renderItem'
  | 'isDisabled'
  | 'itemClassName'
  | 'renderActions'
>
export function TimetableSheet<T extends WithUniqueId>({
  items,
  ...props
}: TimetableSheetProps<T>) {
  const TTContext: Context<TimetableSheetContextValue<T>> =
    TimetableSheetContext

  const [containers, setContainers] = useState<
    (keyof TimetableSheetValue<T>)[]
  >(Object.keys(items))
  const [activeId, setActiveId] = useState<string | null>(null)
  const lastOverId = useRef<string | null>(null)
  const recentlyMovedToNewContainer = useRef(false)
  const isSortingContainer = activeId ? containers.includes(activeId) : false

  const [clonedItems, setClonedItems] = useState<TimetableSheetValue<T> | null>(
    null,
  )

  const findContainer = (id: string) => {
    return Object.keys(items).find((key) => {
      const idx = items[key].findIndex((i) => i.id === id)
      return idx !== -1
    })
  }

  const getIndex = (id: string) => {
    const container = findContainer(id)
    if (!container) {
      return -1
    }
    return items[container].findIndex((i) => i.id === id)
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false
    })
  }, [items])

  return (
    <TTContext.Provider
      value={{
        ...props,
        items,
        containers,
        setContainers,
        activeId,
        setActiveId,
        lastOverId,
        recentlyMovedToNewContainer,
        isSortingContainer,
        clonedItems,
        setClonedItems,
        getIndex,
        findContainer,
      }}
    >
      <div className="tts">
        <div className="tts-time-container">
          <div className="tts-time-container-header"></div>
          {Object.values(Timeline).map((v) => {
            const split = v.split('-')
            const start = split[0]?.replace(/(AM|PM)/gi, ' $1')
            const end = split[1]?.replace(/(AM|PM)/gi, ' $1')
            return (
              <div key={v} className="tts-time">
                <div className="tts-time-start">{start}</div>
                <div className="tts-time-separator">-</div>
                <div className="tts-time-end">{end}</div>
              </div>
            )
          })}
        </div>
        <div className="tts-dnd">
          {containers.map((containerId) => (
            <TimetableSheetContainer key={containerId} label={containerId}>
              <TimetableSheetDnd>
                <SortableContext
                  items={items[containerId]}
                  strategy={verticalListSortingStrategy}
                >
                  {items[containerId].map((value, index) => {
                    return (
                      <TimetableSheetSortableItem
                        key={value.id}
                        item={value}
                        index={index}
                        containerId={containerId}
                      />
                    )
                  })}
                </SortableContext>
              </TimetableSheetDnd>
            </TimetableSheetContainer>
          ))}
        </div>
      </div>
    </TTContext.Provider>
  )
}
