// @floating-ui
import '../Dnd/Draggable.css'

import { useDraggable } from '@dnd-kit/core'
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
} from '@floating-ui/react'
import { useTheme } from '@material-tailwind/react'
import type {
  animate,
  dismiss,
  handler,
  size,
} from '@material-tailwind/react/types/components/dialog'
import type { NewAnimatePresenceProps } from '@material-tailwind/react/types/generic'
import findMatch from '@material-tailwind/react/utils/findMatch'
import objectsToString from '@material-tailwind/react/utils/objectsToString'
import clsx from 'clsx'
import merge from 'deepmerge'
// framer-motion
import {
  AnimatePresence,
  domAnimation,
  LazyMotion,
  m,
  MotionProps,
  Variants,
} from 'framer-motion'
import {
  ComponentPropsWithoutRef,
  CSSProperties,
  FC,
  forwardRef,
  ReactNode,
  useContext,
} from 'react'
import { twMerge } from 'tailwind-merge'

import DraggableDialogProvider, {
  DraggableDialogContext,
} from '@/components/DraggableDialog/DraggableDialogContext.tsx'

const DraggableDialogInner = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'> &
    MotionProps & {
      open: boolean
      appliedAnimation: Variants
      dragContainerProps?: ComponentPropsWithoutRef<'div'>
    }
>(function DraggableDialogInner(
  {
    style,
    open,
    appliedAnimation,
    children,
    dragContainerProps,
    className,
    ...rest
  },
  ref,
) {
  const { id, x, y, fullScreen } = useContext(DraggableDialogContext)
  const { attributes, isDragging, setNodeRef, transform } = useDraggable({
    id,
  })
  const refs = useMergeRefs([ref, setNodeRef])

  const { className: dragContainerClassName, ...dragContainerRest } =
    dragContainerProps ?? {}
  return (
    <m.div
      ref={refs}
      {...attributes}
      {...rest}
      data-testid="draggable-dialog"
      className={clsx('dnd-draggable', isDragging && 'dragging', className)}
      style={{
        ...style,
        top: fullScreen ? 0 : y,
        left: fullScreen ? 0 : x,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        '--translate-x': fullScreen ? '0px' : `${transform?.x ?? 0}px`,
        '--translate-y': fullScreen ? '0px' : `${transform?.y ?? 0}px`,
      }}
      initial="unmount"
      exit="unmount"
      animate={open ? 'mount' : 'unmount'}
      variants={appliedAnimation}
    >
      <div
        className={clsx('drag-container', dragContainerClassName)}
        {...dragContainerRest}
      >
        {children}
      </div>
    </m.div>
  )
})

export interface DialogProps extends ComponentPropsWithoutRef<'div'> {
  open: boolean
  handler: handler
  size?: size | 'unsized'
  dismiss?: dismiss
  animate?: animate
  className?: string
  children: ReactNode
  overlayStyle?: CSSProperties
  backdrop?: boolean
  backdropBlur?: boolean
  backdropClassName?: string
  dragContainerProps?: ComponentPropsWithoutRef<'div'>
  initialCoordinate?: {
    x: number
    y: number
  }
  lockScroll?: boolean
  fullScreen?: boolean
  onDialogDragEnd?: (x: number, y: number) => void
}

const DraggableDialog = forwardRef<HTMLDivElement, DialogProps>(
  function DraggableDialog(
    {
      overlayStyle,
      open,
      handler,
      size,
      dismiss,
      animate,
      className,
      backdrop,
      backdropBlur,
      children,
      initialCoordinate,
      dragContainerProps,
      backdropClassName,
      lockScroll,
      fullScreen,
      onDialogDragEnd,
      ...rest
    },
    ref,
  ) {
    // 1. init
    const { dialog } = useTheme()
    const {
      defaultProps,
      valid,
      styles: { base, sizes },
    } = dialog

    // 2. set default props
    handler = handler ?? undefined
    size = size ?? defaultProps.size
    dismiss = dismiss ?? defaultProps.dismiss
    animate = animate ?? defaultProps.animate
    className = twMerge(defaultProps.className || '', className)

    const {
      backgroundColor,
      backgroundOpacity,
      backdropFilter,
      ...noBackdropBg
    } = base.backdrop

    const bdFilter = backdropBlur ? backdropFilter : ''

    // 3. set styles
    const backdropClasses = twMerge(
      clsx(
        backdrop
          ? objectsToString({
              backgroundColor,
              backgroundOpacity,
              bdFilter,
              ...noBackdropBg,
            })
          : objectsToString(noBackdropBg),
      ),
      backdropClassName,
    )
    const dialogClasses = twMerge(
      clsx(
        //objectsToString(base.container),
        size === 'unsized'
          ? undefined
          : objectsToString(sizes[findMatch(valid.sizes, size, 'md')]),
      ),
      className,
    )

    // 4. set animation
    const animation = {
      unmount: {
        opacity: 0,
        y: -50,
        transition: {
          duration: 0.3,
        },
      },
      mount: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.3,
        },
      },
    }
    const backdropAnimation = {
      unmount: {
        opacity: 0,
        transition: {
          delay: 0.2,
        },
      },
      mount: {
        opacity: 1,
      },
    }
    const appliedAnimation = merge(animation, animate ?? {})

    // 5. set @floating-ui
    const { refs, context } = useFloating({
      open,
      onOpenChange: handler,
    })

    const id = useId()
    const labelId = `${id}-label`
    const descriptionId = `${id}-description`

    const { getFloatingProps } = useInteractions([
      useClick(context),
      useRole(context),
      useDismiss(context, dismiss),
    ])

    const mergedRef = useMergeRefs([ref, refs.floating])

    // 6. Create an instance of AnimatePresence because of the types issue with the children
    const NewAnimatePresence: FC<NewAnimatePresenceProps> = AnimatePresence

    // 7. return
    return (
      <LazyMotion features={domAnimation}>
        <FloatingPortal root={document.getElementById('portal')}>
          <NewAnimatePresence>
            {open && (
              <FloatingOverlay
                //className="fixed inset-0 w-screen h-screen"
                style={{
                  zIndex: 9998,
                  ...overlayStyle,
                }}
                lockScroll={lockScroll}
                data-testid="draggable-dialog-overlay"
                className="pointer-events-none"
              >
                <FloatingFocusManager modal={false} context={context}>
                  <m.div
                    className={clsx(size === 'xxl' ? '' : backdropClasses)}
                    initial="unmount"
                    exit="unmount"
                    animate={open ? 'mount' : 'unmount'}
                    variants={backdropAnimation}
                    transition={{ duration: 0.2 }}
                  >
                    <DraggableDialogProvider
                      handler={handler}
                      id={id}
                      initialCoordinate={initialCoordinate}
                      fullScreen={fullScreen}
                      onDragEnd={onDialogDragEnd}
                    >
                      <DraggableDialogInner
                        dragContainerProps={dragContainerProps}
                        open={open}
                        {...getFloatingProps({
                          ...rest,
                          ref: mergedRef,
                          className: clsx(dialogClasses),
                          'aria-labelledby': labelId,
                          'aria-describedby': descriptionId,
                        })}
                        appliedAnimation={appliedAnimation}
                      >
                        {children}
                      </DraggableDialogInner>
                    </DraggableDialogProvider>
                  </m.div>
                </FloatingFocusManager>
              </FloatingOverlay>
            )}
          </NewAnimatePresence>
        </FloatingPortal>
      </LazyMotion>
    )
  },
)

export default DraggableDialog
