import {
  closestCenter,
  CollisionDetection,
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  getFirstCollision,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  pointerWithin,
  rectIntersection,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { ReactNode, useCallback } from 'react'
import { createPortal } from 'react-dom'

import { multipleContainersCoordinateGetter } from '@/components/TimetableSheet/shared.ts'
import {
  useTimetableSheetContext,
  WithUniqueId,
} from '@/components/TimetableSheet/TimetableSheetContext.ts'
import TimetableSheetItem from '@/components/TimetableSheet/TimetableSheetItem.tsx'

export default function TimetableSheetDnd<T extends WithUniqueId>({
  children,
}: {
  children: ReactNode
}) {
  const {
    items,
    clonedItems,
    getIndex,
    onChange,
    lastOverId,
    recentlyMovedToNewContainer,
    activeId,
    setActiveId,
    setClonedItems,
    findContainer,
  } = useTimetableSheetContext<T>()

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: multipleContainersCoordinateGetter,
    }),
  )

  /*function getNextContainerId() {
    const containerIds = Object.keys(items)
    const lastContainerId = containerIds[containerIds.length - 1]

    return String.fromCharCode(lastContainerId.charCodeAt(0) + 1)
  }*/

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items,
          ),
        })
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args)
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args)
      let overId = getFirstCollision(intersections, 'id')

      if (overId != null) {
        /*if (overId === TIMETABLE_SHEET_TRASH_ID) {
          // If the intersecting droppable is the trash, return early
          // Remove this if you're not using trashable functionality in your app
          return intersections
        }*/

        if (overId in items) {
          const containerItems = items[overId]

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  // TODO: fix this type
                  containerItems.includes(container.id as unknown as T),
              ),
            })[0]?.id
          }
        }

        lastOverId.current = overId?.toString()

        return [{ id: overId }]
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : []
    },
    [activeId, items, lastOverId, recentlyMovedToNewContainer],
  )
  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      onChange(clonedItems)
    }

    setActiveId(null)
    setClonedItems(null)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setActiveId(`${active.id}`)
        setClonedItems(items)
      }}
      onDragEnd={({ active, over }) => {
        const activeContainer = findContainer(`${active.id}`)

        if (!activeContainer) {
          setActiveId(null)
          return
        }

        const overId = over?.id

        if (overId == null) {
          setActiveId(null)
          return
        }

        const overContainer = findContainer(`${overId}`)

        if (overContainer) {
          const activeIndex = items[activeContainer].findIndex(
            (i) => i.id === active.id,
          )
          const overIndex = items[overContainer].findIndex(
            (i) => i.id === overId,
          )

          if (activeIndex !== overIndex) {
            onChange((items) => {
              return {
                ...items,
                [overContainer]: arrayMove(
                  items[overContainer],
                  activeIndex,
                  overIndex,
                ),
              }
            })
          }
        }

        setActiveId(null)
      }}
      //cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      //modifiers={modifiers}
    >
      {children}
      {createPortal(
        <DragOverlay
          adjustScale
          dropAnimation={{
            sideEffects: defaultDropAnimationSideEffects({
              styles: {
                active: {
                  opacity: '0.5',
                },
              },
            }),
          }}
        >
          {activeId ? (
            <TimetableSheetItem
              index={getIndex(activeId)}
              containerId={activeId}
              item={
                items[findContainer(activeId) ?? '']?.find(
                  (i) => i.id === activeId,
                ) as unknown as T
              }
              dragOverlay
            />
          ) : null}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  )
}
