import { useContext } from 'react'

import { UploadContext } from '@/providers/UploadProvider/context.tsx'

export function useUpload() {
  const context = useContext(UploadContext)
  if (context === null) {
    throw Error('must use within UploadContext.Provider')
  }

  return context
}
