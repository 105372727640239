import './ErrorNotFound.css'

import { Sym } from '@edclass/fe-ui'
import { Button } from '@material-tailwind/react'
import { useNavigate } from 'react-router-dom'

import Logo from '@/assets/logo.svg?react'

export default function ErrorNotFound() {
  const navigate = useNavigate()
  return (
    <div id="not-found-page">
      <div id="bg" />
      <div id="overlay" />
      <div id="error-text">
        <div className="flex justify-center items-center">
          <Logo width={190} height={160} />
        </div>
        <div className="flex flex-col gap-4 text-center">
          <h1>Page Not Found</h1>
          <div className="box">
            The page you are looking for does not exists. Please check your URL
          </div>
          <div className="self-center mt-8">
            <Button
              className="flex items-center gap-4 justify-center"
              onClick={() => {
                navigate(-1)
              }}
            >
              <Sym>arrow_back</Sym>
              <span>Go Back</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
