import { createContext, ReactNode } from 'react'
import { To } from 'react-router-dom'

export interface AdminLayoutContextValue {
  title?: ReactNode
  icon?: ReactNode
  header?: ReactNode
  onBack?: To | 'parent' | (() => void)
}

export const AdminLayoutContext = createContext<AdminLayoutContextValue | null>(
  null,
)
