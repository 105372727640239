import { useTheme } from '@material-tailwind/react'
import type {
  children,
  className,
  disabled,
  index,
} from '@material-tailwind/react/types/components/select'
import objectsToString from '@material-tailwind/react/utils/objectsToString'
import clsx from 'clsx'
import { ComponentProps, KeyboardEvent, MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'

import { useSelectWithSearch } from './hooks.ts'

export interface SelectOptionProps<T>
  extends Omit<ComponentProps<'li'>, 'value'> {
  value?: T
  keywords?: string
  index?: index
  disabled?: disabled
  className?: className
  children: children
}

function SelectWithSearchOption<T>({
  value,
  index = 0,
  disabled = false,
  className = '',
  children,
  ...rest
}: SelectOptionProps<T>) {
  const { select } = useTheme()
  const { styles } = select
  const { base } = styles

  const {
    value: selectedValue,
    listRef,
    setOpen,
    onChange,
    getItemProps,
    dataRef,
    setTypeAhead,
  } = useSelectWithSearch()

  function handleSelect() {
    onChange(value)
    setOpen(false)
    setTypeAhead('')
  }

  function handleKeyDown(event: KeyboardEvent) {
    if (
      event.key === 'Enter' ||
      (event.key === ' ' && !dataRef.current.typing)
    ) {
      event.preventDefault()
      handleSelect()
    }
  }

  // 3. set styles
  const optionBaseClasses = objectsToString(base.option.initial)
  const optionActiveClasses = objectsToString(base.option.active)
  const optionDisabledClasses = objectsToString(base.option.disabled)
  const classes = twMerge(
    clsx(optionBaseClasses, {
      [optionActiveClasses]: selectedValue === value,
      [optionDisabledClasses]: disabled,
    }),
    className ?? '',
  )

  // 4. return
  return (
    <li
      {...rest}
      role="option"
      ref={(node) => (listRef.current[index] = node)}
      className={classes}
      disabled={disabled}
      data-selected={selectedValue === value}
      {...getItemProps({
        onClick: (e: MouseEvent<HTMLLIElement>) => {
          const onClick = rest?.onClick
          if (typeof onClick === 'function') {
            onClick(e)
            handleSelect()
          } else {
            handleSelect()
          }
        },
        onKeyDown: (e: KeyboardEvent<HTMLLIElement>) => {
          const onKeyDown = rest?.onKeyDown

          if (typeof onKeyDown === 'function') {
            onKeyDown(e)
            handleKeyDown(e)
          }

          handleKeyDown(e)
        },
      })}
    >
      {children}
    </li>
  )
}

SelectWithSearchOption.displayName = 'SelectWithSearchOption'

export default SelectWithSearchOption
