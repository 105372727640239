import { Sym } from '@edclass/fe-ui'
import clsx from 'clsx'

import SubjectIcon from '@/components/SubjectIcon.tsx'
import { TimetableSubject } from '@/helpers/types.ts'

export interface TimetableSubjectItemProps {
  item: TimetableSubject
}
export default function TimetableSubjectItem({
  item,
}: TimetableSubjectItemProps) {
  return (
    <div className={clsx('flex-c-2')}>
      <div
        className="flex-c-2 overflow-hidden"
        onClick={() => {
          //
        }}
      >
        {item.isLeave ? (
          <Sym className="!text-[20px]">near_me</Sym>
        ) : item.subject ? (
          <SubjectIcon
            subject={item.subject}
            iconProps={{
              className: 'text-[20px]',
            }}
          />
        ) : (
          <Sym className="!text-[20px]">add_circle</Sym>
        )}
        <div className="text-body tracking-wide truncate uppercase font-bold">
          {item.isLeave ? 'Leave' : item.subject || 'Allocate'}
        </div>
      </div>
    </div>
  )
}
