import { createContext } from 'react'

import { AdminImportPageTab } from '@/pages/AdminPage/AdminImportPage/shared.ts'

export interface AdminImportPageContextValue {
  tab: AdminImportPageTab
  setTab: SetState<AdminImportPageTab>
}

export const AdminImportPageContext =
  createContext<AdminImportPageContextValue | null>(null)
