import { ApiEndpoint, FsEndpoint } from '@edclass/fe-common'
import { AxiosRequestConfig } from 'axios'

import { Http } from '@/helpers/http.ts'
import { BaseService } from '@/services/base.ts'
import { Params } from '@/services/params.ts'

const BASE_URL: string = import.meta.env.VITE_FS_API_URL
const SCHOOL_BUCKET_NAME: string = import.meta.env.VITE_SCHOOL_BUCKET_NAME
const APP_BUCKET_NAME: string = import.meta.env.VITE_APP_BUCKET_NAME

interface FsQueryOption {
  //isSchool?: boolean
  folder?: string
  //uploadedBy?: MoreSimpleUser & WithId
  tags?: FsTagReqBody
}

const DEFAULT_FS_QUERY_OPTION = {}

class FsService extends BaseService {
  protected _client: Http
  private static instance: FsService

  static getInstance(): FsService {
    if (!FsService.instance) {
      FsService.instance = new FsService()
    }
    return FsService.instance
  }

  constructor() {
    super()
    this._client = new Http({
      baseUrl: `${BASE_URL}`,
      //headers: {
      //  ['x-asis']: 'kleper',
      //},
    })
  }

  protected getBuketName(isSchool = false) {
    return isSchool ? SCHOOL_BUCKET_NAME : APP_BUCKET_NAME
  }

  async createFolder(folderName: string, isSchool?: boolean) {
    return this.send(
      this._client.post(FsEndpoint.FolderCreate, {
        folderName,
        bucketName: this.getBuketName(isSchool),
      }),
    )
  }

  async listFiles(
    params?: Params,
    filters?: {
      tags: FsTagReqBody
      or: boolean
    },
  ) {
    const paramStr = params ? `?${params.toString()}` : ''
    return await this.send<PaginatedItems<FsItem>>(
      this._client.post(`file/list${paramStr}`, filters),
    )
  }
  async listFilesOther(
    params?: Params,
    filters?: {
      tags: FsTagReqBody
      or: boolean
    },
  ) {
    const paramStr = params ? `?${params.toString()}` : ''
    return await this.send<PaginatedItems<FsItem>>(
      this._client.post(`file/list/other${paramStr}`, filters),
    )
  }
  async listImage(
    params?: Params,
    filters?: {
      tags: FsTagReqBody
      or: boolean
    },
  ) {
    const paramStr = params ? `?${params.toString()}` : ''
    return await this.send<PaginatedItems<FsItem>>(
      this._client.post(`file/list/image${paramStr}`, filters),
    )
  }

  async listVideo(
    params?: Params,
    filters?: {
      tags: FsTagReqBody
      or: boolean
    },
  ) {
    const paramStr = params ? `?${params.toString()}` : ''
    return await this.send<PaginatedItems<FsItem>>(
      this._client.post(`file/list/video${paramStr}`, filters),
    )
  }
  async listAudio(
    params?: Params,
    filters?: {
      tags: FsTagReqBody
      or: boolean
    },
  ) {
    const paramStr = params ? `?${params.toString()}` : ''
    return await this.send<PaginatedItems<FsItem>>(
      this._client.post(`file/list/audio${paramStr}`, filters),
    )
  }

  async addTags(key: string, tags: FsTagReqBody) {
    return await this.send<FsTag>(
      this._client.post(`file/${encodeURIComponent(key)}/tags`, tags),
    )
  }

  async updateCustomTags(key: string, tags: string[]) {
    return await this.send<void>(
      this._client.put(`file/${encodeURIComponent(key)}/tags/custom`, tags),
    )
  }
  /*async removeTag(key: string, tags: FsTagReqBody) {
    return await this.send<FsTag>(
      this._client.post(`file/${key}/tags/delete`, tags),
    )
  }*/

  async reTag(key: string) {
    return await this.send<FsTag>(
      this._client.put(`file/${encodeURIComponent(key)}/tags`),
    )
  }

  async reTagUpdateLesson(
    from: { key: string; tags: FsTagReqBody },
    to: {
      key: string
      tags: FsTagReqBody
    },
  ) {
    return await Promise.all([
      this.send<FsTag>(this._client.patch(`file/${to.key}/tags`, to.tags)),
      this.send<FsTag>(
        this._client.post(`file/${from.key}/tags/remove`, from.tags),
      ),
    ]).then(([res]) => {
      return res
    })
  }

  async getFile(filePath: string) {
    return this.send<FsItem>(this._client.get(`${filePath}`))
  }

  async addFile(filePath: string) {
    return this.send<FsItem>(this._client.get(`${filePath}`))
  }
  async deleteFile(id: string) {
    return this.send<void>(this._client.delete(`${ApiEndpoint.Fs}/${id}`))
  }

  async uploadGame(file: File, config?: AxiosRequestConfig) {
    const formData = new FormData()
    /*formData.set('bucketName', this.getBuketName(false))
    formData.set('folderName', 'games/')
    formData.set(
      'uploadedBy',
      JSON.stringify({
        id: uploadedBy.id,
        username: uploadedBy.username,
        email: uploadedBy.email,
        firstName: uploadedBy.firstName,
        middleName: uploadedBy.middleName,
        lastName: uploadedBy.lastName,
      }),
    )
    formData.set(
      'meta',
      JSON.stringify({
        type: file.type,
        size: file.size,
      }),
    )*/
    formData.append('files', file)

    const cfg = config || {}
    if (cfg.headers) {
      cfg.headers['Content-Type'] = 'multipart/form-data'
    } else {
      cfg.headers = {
        'Content-Type': 'multipart/form-data',
      }
    }

    cfg.headers['x-folder'] = 'games/'

    return this.send<FsItem[]>(
      this._client.post(`${FsEndpoint.FileUpload}?type=game`, formData, cfg),
    )
  }
  async upload(
    files: File | File[],
    options: FsQueryOption = DEFAULT_FS_QUERY_OPTION,
    config?: AxiosRequestConfig,
  ) {
    const formData = new FormData()
    /*const { isSchool, folderName, uploadedBy, meta } = options
    formData.set('bucketName', this.getBuketName(isSchool))

    if (folderName !== undefined) {
      formData.set('folderName', folderName)
    }

    if (!uploadedBy) {
      return Promise.reject('no uploaded by info')
    }

    formData.set(
      'uploadedBy',
      JSON.stringify({
        id: uploadedBy.id,
        username: uploadedBy.username,
        email: uploadedBy.email,
        firstName: uploadedBy.firstName,
        middleName: uploadedBy.middleName,
        lastName: uploadedBy.lastName,
      }),
    )

    if (meta) {
      formData.set('meta', JSON.stringify(meta))
    }*/

    if (Array.isArray(files)) {
      for (const f of files) {
        formData.append('files', f)
      }
    } else {
      formData.append('files', files)
    }

    const cfg = config || {}

    if (cfg.headers) {
      cfg.headers['Content-Type'] = 'multipart/form-data'
    } else {
      cfg.headers = {
        'Content-Type': 'multipart/form-data',
      }
    }

    if (options.folder) {
      cfg.headers['x-folder'] = options.folder
    }

    if (options.tags) {
      cfg.headers['x-tags'] = JSON.stringify(options.tags)
    }

    return this.send<FsItem[]>(
      this._client.post(FsEndpoint.FileUpload, formData, config),
    )
  }

  async migrate() {
    return this.send<void>(this._client.post('file/migrate'))
  }
}

export function getFsService() {
  return FsService.getInstance()
}

export function getFsUrl(
  path?: string,
  bucket = import.meta.env.VITE_APP_BUCKET_NAME,
) {
  if (path?.startsWith('http')) {
    return path
  }
  const withSlash = path?.startsWith('/') ?? true
  const withStream = path?.startsWith('/file/stream')
  if (path) {
    return `${BASE_URL}${!withStream ? `/file/stream/${bucket ? `${bucket}/` : ''}` : !withSlash ? '/' : ''}${encodeURIComponent(path)}?xasis=kleper`
  }
  return undefined
}

export function getFsName(path?: string) {
  const split = path?.split('/') ?? []

  if (path?.endsWith('/')) {
    return split[split.length - 2]
  }
  return split[split.length - 1]
}
