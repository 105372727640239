import './LoginPage.css'

import { addFlag, hasFlag, removeFlag } from '@edclass/fe-common'
import { Sym } from '@edclass/fe-ui'
import { Alert, Button, Input } from '@material-tailwind/react'
import { FormEvent, useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Logo from '@/assets/logo.svg?react'
import { USER_NEW_PASSWORD_REQUIRED } from '@/constants/auth.ts'
import { BASE_PATH } from '@/constants/constants.ts'
import useAuthContext from '@/hooks/useAuthProvider.ts'
import useFormEnter from '@/hooks/useFormEnter.ts'
import useLoggedNavigate from '@/hooks/useLoggedNavigate.ts'
enum LoginFlag {
  None = 0,
  Forgot = 1,
  ForgotSend = 2,
  NewAccountVerify = 4,
}

export default function LoginPage() {
  const { login, resetPass, confirmResetPass, verifyPass } = useAuthContext()
  const [user, setUser] = useState('')
  const [pwd, setPwd] = useState('')
  const [showPwd, setShowPwd] = useState(false)
  const [error, setError] = useState<string[] | null>(null)
  const [loading, setLoading] = useState(false)
  const navigate = useLoggedNavigate()
  const [code, setCode] = useState<string | null>(null)
  const [flag, setFlag] = useState<LoginFlag>(LoginFlag.None)
  const location = useLocation()

  const handleSubmit = useCallback(
    async (e: FormEvent | KeyboardEvent) => {
      e.preventDefault()

      setError(null)
      setLoading(true)
      try {
        if (code && hasFlag(flag, LoginFlag.Forgot | LoginFlag.ForgotSend)) {
          await confirmResetPass(user, pwd, code)
          navigate(`${BASE_PATH || '/'}`, {
            replace: true,
          })
        } else if (hasFlag(flag, LoginFlag.Forgot)) {
          await resetPass(user)
          setFlag((prev) => addFlag(prev, LoginFlag.ForgotSend))
        } else if (hasFlag(flag, LoginFlag.NewAccountVerify)) {
          await verifyPass(user, pwd)
          setFlag((prev) => removeFlag(prev, LoginFlag.NewAccountVerify))
          navigate(`${BASE_PATH || '/'}`, {
            replace: true,
          })
        } else {
          await login(user, pwd)
          const redir = location.state?.from ? location.state.from : undefined
          navigate(redir ? redir : `${BASE_PATH || '/'}`, {
            replace: redir ? true : false,
          })
        }
      } catch (e) {
        const err = e as {
          message: string
          errors?: Record<string, string>
        }

        if ((e as Error).message === USER_NEW_PASSWORD_REQUIRED) {
          setPwd('')
          setFlag((prev) => addFlag(prev, LoginFlag.NewAccountVerify))
        } else {
          const errMsg: string[] = []
          errMsg.push(err.message)
          if (err.errors) {
            errMsg.push(
              ...Object.entries(err.errors).map(([, v]) => {
                return v
              }),
            )
            setError(errMsg)
          }
        }
      } finally {
        setLoading(false)
      }
    },
    [
      code,
      user,
      pwd,
      flag,
      navigate,
      confirmResetPass,
      verifyPass,
      login,
      resetPass,
      location,
    ],
  )

  useFormEnter('login-form', async (e) => {
    await handleSubmit(e)
  })

  const codeEl = useMemo(() => {
    return (
      <Input
        label="Confirmation Code"
        value={code ?? ''}
        onChange={(e) => {
          setCode(e.currentTarget.value.trim())
        }}
      />
    )
  }, [code])

  const userEl = useMemo(() => {
    return (
      <Input
        error={error !== null}
        label="Username or Email"
        autoComplete="on"
        value={user}
        onChange={(e) => {
          setUser(e.currentTarget.value.trim())
        }}
      />
    )
  }, [error, user])

  const pwdEl = useMemo(() => {
    return (
      <div className="relative flex w-full">
        <Input
          error={error !== null}
          label="Password"
          value={pwd}
          type={showPwd ? 'text' : 'password'}
          onChange={(e) => {
            setPwd(e.currentTarget.value)
          }}
        />
        <button
          type="button"
          className="!absolute right-2 top-0 rounded text-white w-6 h-full flex items-center justify-center"
          onClick={() => {
            setShowPwd((prev) => !prev)
          }}
        >
          {!showPwd ? <Sym>visibility_off</Sym> : <Sym>visibility</Sym>}
        </button>
      </div>
    )
  }, [showPwd, pwd, error])

  return (
    <div id="login-page">
      <form id="login-form" className="login-form" onSubmit={handleSubmit}>
        <div className="flex justify-center items-center">
          <Logo width={190} height={160} />
        </div>
        <div className="flex flex-col gap-4">
          {hasFlag(flag, LoginFlag.Forgot) && (
            <div className="text-center mb-4">
              <div className="font-bold text-[24px] mb-4">
                Forgot your password?
              </div>
              <div className="text-base text-blue-100">
                We will email you to securely reset your password
              </div>
            </div>
          )}
          {hasFlag(flag, LoginFlag.NewAccountVerify) && (
            <Alert open={true} color="blue" variant="outlined">
              <div className="text-body">
                Please change your temporary password
              </div>
            </Alert>
          )}
          {error !== null &&
            error.map((e, i) => {
              return (
                <Alert
                  key={i}
                  open={true}
                  color="red"
                  variant="outlined"
                  onClose={() => setError(error?.filter((ee) => ee !== e))}
                >
                  <div className="text-body">{e}</div>
                </Alert>
              )
            })}
        </div>
        {!hasFlag(flag, LoginFlag.NewAccountVerify) && (
          <>
            {!hasFlag(flag, LoginFlag.ForgotSend) && userEl}
            {hasFlag(flag, LoginFlag.ForgotSend | LoginFlag.Forgot) && codeEl}
            {(!hasFlag(flag, LoginFlag.Forgot) ||
              hasFlag(flag, LoginFlag.ForgotSend)) &&
              pwdEl}
          </>
        )}
        {hasFlag(flag, LoginFlag.NewAccountVerify) && pwdEl}
        <Button
          color={hasFlag(flag, LoginFlag.Forgot) ? 'deep-orange' : 'blue'}
          disabled={loading}
          loading={loading}
          type="submit"
        >
          {loading
            ? 'Loading'
            : hasFlag(flag, LoginFlag.Forgot)
              ? 'Reset'
              : 'Login'}
        </Button>
        <div className="mt-4 w-full text-main text-center">
          <button
            className="text-ed-blue underline hover:text-ed-light-blue"
            type="button"
            onClick={() => {
              if (hasFlag(flag, LoginFlag.Forgot)) {
                setFlag(LoginFlag.None)
              } else {
                setFlag(LoginFlag.Forgot)
              }
              setError(null)
              setUser('')
              setPwd('')
              setCode(null)
            }}
          >
            {!hasFlag(flag, LoginFlag.Forgot) ? (
              'Forgot Password?'
            ) : (
              <span className="flex items-center flex-shrink-0 w-full">
                &larr; Go Back
              </span>
            )}
          </button>
          <div className="text-[10px] my-4">version {__APP_VERSION__}</div>
        </div>
        {/*
          <Button
          
          onClick={async () => {
            const jwt = await ApiTestService.getInstance().getJwt()
            console.log('jwt', jwt)
          }}
        >
          Test JWT
        </Button>
        <Button
          
          onClick={async () => {
            const foods = await ApiTestService.getInstance().getFoods()
            console.log('foods', foods)
          }}
        >
          Test Get Foods
        </Button>
        <Button
          
          onClick={async () => {
            ApiTestService.getInstance().clearCSRFToken()
            const foods = await ApiTestService.getInstance().postFood()
            console.log('foods', foods.data)
          }}
        >
          Test Post Food without CSRF
        </Button>
        <Button
          
          onClick={async () => {
            await ApiTestService.getInstance().getCSRFToken()
            const foods = await ApiTestService.getInstance().postFood()
            console.log('foods', foods.data)
          }}
        >
          Test Post Food with CSRF
        </Button>

        <Button
          
          onClick={() => {
            getApiService().ping().then(console.log).catch(console.error)
          }}
        >
          Ping
        </Button>
        */}
      </form>
    </div>
  )
}
