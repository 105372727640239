import { PropsWithChildren, useState } from 'react'

import { SideBarContext } from './context'

export default function SideBarProvider({ children }: PropsWithChildren) {
  const [sideBarItems, setSideBarItems] = useState<string[]>([])

  return (
    <SideBarContext.Provider value={{ sideBarItems, setSideBarItems }}>
      {children}
    </SideBarContext.Provider>
  )
}
