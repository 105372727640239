import { clamp } from '@edclass/fe-common'

export class Params {
  protected _params: URLSearchParams

  constructor(params?: Params, empty?: boolean) {
    if (params) {
      this._params = new URLSearchParams(params._params)
    } else {
      this._params = new URLSearchParams()
    }
    if (empty !== true) {
      this._params.set('page', '1')
      this._params.set('limit', '30')
    }
  }

  static fromObject(params: ReqParams, searchToQuery?: boolean) {
    const p = new Params()
    for (const [k, v] of Object.entries(params)) {
      if (v !== undefined && v !== null && v !== '') {
        if (k === 'search' && searchToQuery) {
          p.set('query', v?.toString())
        } else if (Array.isArray(v)) {
          p.set(k, v.join(','))
        } else {
          p.set(k, v?.toString())
        }
      }
    }
    return p
  }
  static empty() {
    return new Params(undefined, true)
  }
  setPage(page: number) {
    const pg = page < 1 ? 1 : page
    this._params.set('page', `${pg}`)
  }

  setLimit(limit: number) {
    this._params.set('limit', `${clamp(limit, 100, 5)}`)
  }

  setSearch(query: string, field?: string) {
    this._params.set('query', query)
    if (field) {
      this._params.set('field', field)
    }
  }

  set(key: string, value: string) {
    this._params.set(key, value)
  }
  removeSearch() {
    this._params.delete('query')
    this._params.delete('field')
  }

  has(key: string) {
    return this._params.has(key)
  }
  hasSearch() {
    return this._params.has('search')
  }
  get(key: string) {
    return this._params.get(key)
  }
  remove(key: string) {
    return this._params.delete(key)
  }
  replace(key: string, toKey: string) {
    if (this._params.has(key)) {
      this._params.set(toKey, this._params.get(key) as string)
      this._params.delete(key)
    }
  }
  toString() {
    return this._params.toString()
  }
}
