export function defaultSearch(
  opt: Record<string, string> | string,
  keywords: string,
) {
  const obj =
    typeof opt !== 'object'
      ? {
          value: opt,
        }
      : opt

  const matchValue = obj.value
    ? `${obj.value}`.toLowerCase().includes(keywords) ||
      keywords.includes(`${obj.value}`.toLowerCase())
    : false
  const matchLabel = obj.label
    ? obj.label.toLowerCase().includes(keywords) ||
      keywords.includes(obj.label.toLowerCase())
    : false
  const matchKeywords = obj.keywords ? obj.keywords.includes(keywords) : false

  return matchLabel || matchValue || matchKeywords
}
