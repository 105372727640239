import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  Cell,
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  Row,
  RowSelectionState,
  Table,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table'
import clsx from 'clsx'
import {
  Context,
  createContext,
  CSSProperties,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react'

import useContextOrThrow from '@/hooks/useContextOrThrow.ts'
import useSortableProps from '@/hooks/useSortableProps.ts'

interface GridContextValue<T> {
  table: Table<T>
  rows: T[]
  setRows: Dispatch<SetStateAction<T[]>>
  columns: ColumnDef<T>[]
  sortable?: boolean
  className?: {
    body?: string
    head?: string
    th?: string
    td?: string
  }
  onSort?: (next: T[]) => void
  spannedRows: {
    [col: string]: {
      [v: string]: {
        index: number
        span: number
      }
    }
  }
  fixed?: boolean
  expanded: ExpandedState
  setExpanded: SetState<ExpandedState>
  excludeRow?: (row: T) => boolean
  //displayedCell: MutableRefObject<Record<string, number>>
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GridContext = createContext<GridContextValue<any> | null>(null)
GridContext.displayName = 'GridContext'

// eslint-disable-next-line react-refresh/only-export-components
export function useGrid() {
  return useContextOrThrow(GridContext)
}

function DndGridBody() {
  const { rows, onSort } = useGrid()
  const sortableProps = useSortableProps()
  const handleDragEnd = useCallback(
    ({ over, active }: DragEndEvent) => {
      if (over && active.id !== over?.id) {
        const activeIndex = (rows as Record<string, string>[]).findIndex(
          ({ id }) => id === active.id,
        )
        const overIndex = (rows as Record<string, string>[]).findIndex(
          ({ id }) => id === over.id,
        )
        onSort?.(arrayMove(rows, activeIndex, overIndex))
      }
    },
    [rows, onSort],
  )

  return (
    <DndContext
      accessibility={{
        container: document.body,
      }}
      {...sortableProps}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={rows} strategy={verticalListSortingStrategy}>
        <GridBody />
      </SortableContext>
    </DndContext>
  )
}

function SortableRow<T>({ row }: { row: Row<T> }) {
  const { isDragging, setNodeRef, transition, attributes, transform } =
    useSortable({
      id: (row.original as Record<string, string>).id,
    })

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  }

  return (
    <tr ref={setNodeRef} style={style} {...attributes}>
      <GridRow row={row} />
    </tr>
  )
}

function GridCell<T>({ row, cell }: { row: Row<T>; cell: Cell<T, unknown> }) {
  const { className, spannedRows } = useGrid()
  const colId = useMemo(() => cell.column.id, [cell])
  const meta = useMemo(
    () => cell.column.columnDef.meta as Record<string, unknown>,
    [cell],
  )
  const isRowSpanned = meta?.enableRowSpan === true
  const value = (row.getValue(colId) as string) || ''

  return (
    <td
      data-cell-id={cell.column.id}
      className={clsx(
        'px-4 h-12 border-b border-e last:border-e-0 border-white/30',
        className?.td,
        isRowSpanned
          ? spannedRows[colId]?.[value] !== undefined &&
            row.index === spannedRows[colId]?.[value].index
            ? ''
            : 'hidden'
          : null,
      )}
      data-index={row.index}
      data-displayed-index={spannedRows[colId]?.[value].index}
      rowSpan={spannedRows[colId]?.[value].span}
      key={cell.id}
      style={{ width: cell.column.getSize() }}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  )
}

function GridRow<T>({ row }: { row: Row<T> }) {
  return row.getVisibleCells().map((cell: Cell<T, unknown>) => {
    return <GridCell key={cell.id} row={row} cell={cell} />
  })
}

function GridBody() {
  const { table, sortable, columns } = useGrid()
  const rows = table.getRowModel().rows

  {
    /* If the row is expanded, render the expanded UI as a separate row with a single cell that spans the width of the table */
  }
  {
    /*row.getIsExpanded() && (
            <tr>
              <td colSpan={row.getAllCells().length}>asd</td>
            </tr>
          )*/
  }

  return rows.length > 0 ? (
    rows.map((row) => {
      return sortable ? (
        <SortableRow key={row.id} row={row} />
      ) : (
        <tr key={row.id}>
          <GridRow row={row} />
        </tr>
      )
    })
  ) : (
    <tr>
      <td
        colSpan={columns.length}
        className="px-4 h-12 border-b border-e last:border-e-0 border-white/30"
      >
        No record found
      </td>
    </tr>
  )
}

interface GridProviderProps<T>
  extends Pick<
    GridContextValue<T>,
    'columns' | 'className' | 'sortable' | 'onSort'
  > {
  rows: T[]
  getRowId?: (row: T) => string
  pagination?: {
    perPage: number
    total: number
    totalPages: number
    current: number
  }
  fixed?: boolean
  getSubRows?: TableOptions<T>['getSubRows']
  getRowCanExpand?: TableOptions<T>['getRowCanExpand']
  onRowSelectionChange?: TableOptions<T>['onRowSelectionChange']
  state?: TableOptions<T>['state']
  defaultExpanded?: ExpandedState
  children?: ReactNode
  getFilteredRowModel?: TableOptions<T>['getFilteredRowModel']
}

export default function GridProvider<T>({
  columns,
  rows,
  className,
  sortable,
  onSort,
  getRowId,
  fixed,
  getSubRows,
  getRowCanExpand,
  defaultExpanded,
  children,
  onRowSelectionChange,
  state,
  getFilteredRowModel,
}: GridProviderProps<T>) {
  /**const [rows, setRows] = useState<T[]>(initialData)*/

  /*
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  })*/

  const [expanded, setExpanded] = useState<ExpandedState>(defaultExpanded ?? {})
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  /*
  useEffect(() => {
    if (onRowSelectionChange) {
      onRowSelectionChange(rowSelection)
    }
  }, [rowSelection, onRowSelectionChange])*/

  const table = useReactTable<T>({
    columns,
    data: rows,
    getCoreRowModel: getCoreRowModel(),
    getSubRows,
    getRowCanExpand,
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    defaultColumn: {
      size: 50, //starting column size
      //minSize: 50, //enforced during column resizing
      //maxSize: 500, //enforced during column resizing
    },
    getRowId,
    getFilteredRowModel,
    onPaginationChange: () => undefined,
    //enableExpanding: true,
    state: {
      expanded,
      rowSelection,
      ...state,
    },
    onRowSelectionChange: onRowSelectionChange
      ? onRowSelectionChange
      : setRowSelection,
    onExpandedChange: (v) => {
      if (typeof v === 'function') {
        setExpanded(v)
      } else {
        if (Object.values(v).length > 0) {
          setExpanded(v)
        } else {
          setExpanded(defaultExpanded ?? {})
        }
      }
    },
    /*getPaginationRowModel: getPaginationRowModel(),
    state: pagination
      ? {
          pagination: {
            pageSize: pagination.perPage,
            pageIndex: pagination.current - 1,
          },
        }
      : undefined,*/
  })

  const setRows = () => {
    //
  }
  const GContext: Context<GridContextValue<T> | null> = GridContext

  const spannedRows = useMemo(() => {
    const rows = table.getRowModel().rows
    const tempSpannedRows: Record<
      string,
      Record<
        string,
        {
          index: number
          span: number
        }
      >
    > = {}

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]
      const cells = row.getVisibleCells()
      for (let j = 0; j < cells.length; j++) {
        const cell = cells[j]
        const colId = cell.column.id
        const meta = cell.column.columnDef.meta as Record<string, unknown>
        const isRowSpanned = meta?.enableRowSpan === true

        if (isRowSpanned) {
          const value = row.getValue(colId) as string
          if (tempSpannedRows[colId]?.[value]) {
            tempSpannedRows[colId][value].span += 1
          } else {
            tempSpannedRows[colId] = {
              ...tempSpannedRows[colId],
              [value]: {
                span: 1,
                index: row.index,
              },
            }
          }
        }
      }
    }
    return tempSpannedRows
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, table])

  //const displayedCell = useRef({})

  return (
    <GContext.Provider
      value={{
        table,
        columns,
        rows,
        setRows,
        className,
        sortable,
        onSort,
        spannedRows,
        fixed,
        expanded,
        setExpanded,
        //displayedCell,
      }}
    >
      <table
        data-testid="grid-table"
        className={clsx(
          'w-full text-start',
          fixed ? 'table-fixed' : 'table-auto',
        )}
      >
        <thead
          data-testid="grid-thead"
          className={clsx('bg-ed-blue border border-ed-blue', className?.head)}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className={clsx(
                    'uppercase text-body tracking-wide h-12 text-start px-4',
                    className?.th,
                  )}
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{ width: header.getSize() }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                  {/*header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )*/}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          data-testid="grid-tbody"
          className={clsx('border-x border-white/30', className?.body)}
        >
          {sortable ? <DndGridBody /> : <GridBody />}
        </tbody>
        {/*
          <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
           */}
      </table>
      {children}
    </GContext.Provider>
  )
}
