import { Sym } from '@edclass/fe-ui'
import { Button, IconButton } from '@material-tailwind/react'
import { colors } from '@material-tailwind/react/types/generic'
import clsx from 'clsx'
import { useCallback, useMemo } from 'react'

/*
function getValidPage(pg: number, ic: number, num: number) {
  const totalPages = Math.ceil(ic / num)
  if (pg >= totalPages) {
    return totalPages
  } else if (pg <= 1) {
    return 1
  } else {
    return pg
  }
}*/

enum PaginatorDisplay {
  LeftVisible,
  MiddleSpread,
  RightVisible,
  NoDots,
}

function display(
  totalPages: number,
  currentPage: number,
  maxLinks: number,
  numTrailingLinks: number,
): PaginatorDisplay {
  if (totalPages <= maxLinks) {
    return PaginatorDisplay.NoDots
  }
  // assuming maxLinks = 7
  // 1 2 3 4 5 ... 42
  //       ^
  // if page is at most second to last while in left side state
  if (currentPage <= maxLinks - numTrailingLinks - 1) {
    return PaginatorDisplay.LeftVisible
  }
  // 1 ... 4 5 6 ... 42
  //         ^
  if (
    currentPage >= maxLinks - numTrailingLinks &&
    currentPage + (maxLinks - numTrailingLinks * 2) < totalPages
  ) {
    return PaginatorDisplay.MiddleSpread
  }
  // 1 ... 38 39 40 41 42
  //          ^
  return PaginatorDisplay.RightVisible
}

export default function Pagination({
  current,
  total,
  onChange,
  className,
  color = 'blue',
  maxLinks = 7,
  numTrailingLinks = 2,
}: {
  current: number
  total: number
  onChange: (next: number) => void
  className?: string
  color?: colors
  maxLinks?: number
  numTrailingLinks?: number
}) {
  const dsp = useMemo(() => {
    return display(total, current, maxLinks, numTrailingLinks)
  }, [total, current, maxLinks, numTrailingLinks])

  const formatLink = useCallback(
    (link: number | string) => {
      return {
        label: link,
        active: link === current,
        disabled: typeof link === 'string',
      }
    },
    [current],
  )

  const links = useMemo(() => {
    const l = []
    switch (dsp) {
      case PaginatorDisplay.NoDots:
        for (let i = 1; i <= total; i++) {
          l.push(formatLink(i))
        }
        break
      case PaginatorDisplay.LeftVisible:
        for (let i = 1; i <= maxLinks - numTrailingLinks; i++) {
          l.push(formatLink(i))
        }
        l.push(formatLink('...'))
        l.push(formatLink(total))
        break
      case PaginatorDisplay.MiddleSpread:
        {
          l.push(formatLink(1))
          l.push(formatLink('...'))
          const numIterations = maxLinks - numTrailingLinks * 2
          const spot = Math.ceil(numIterations / 2)
          for (
            let i = current - spot + 1;
            i < current + numIterations - spot + 1;
            i++
          ) {
            l.push(formatLink(i))
          }
          l.push(formatLink('...'))
          l.push(formatLink(total))
        }
        break
      case PaginatorDisplay.RightVisible:
        l.push(formatLink(1))
        l.push(formatLink('...'))
        for (let i = total - (maxLinks - 3); i <= total; i++) {
          l.push(formatLink(i))
        }
        break
      default:
        break
    }
    return l
  }, [dsp, total, maxLinks, numTrailingLinks, current, formatLink])

  return (
    <div className={clsx('flex flex-wrap items-center gap-4', className)}>
      <Button
        variant="text"
        color={color}
        className="flex-c-2"
        onClick={() => {
          const next = current - 1
          onChange(Math.max(next, 1))
        }}
        disabled={current === 1}
      >
        <Sym>arrow_left_alt</Sym>
        Previous
      </Button>
      <div className="flex items-center flex-wrap gap-2">
        {links.map((link) => {
          return (
            <IconButton
              key={link.label}
              color={color}
              disabled={
                typeof link.label !== 'string' ? link.disabled : undefined
              }
              variant={link.active ? 'filled' : 'text'}
              onClick={() => {
                if (typeof link.label !== 'string') {
                  //setPreviousPageNum(page)
                  onChange(link.label)
                }
              }}
            >
              {typeof link.label === 'string' ? (
                <span className="text-gray-500 text-base font-base">
                  {link.label}
                </span>
              ) : (
                link.label
              )}
            </IconButton>
          )
        })}
      </div>
      <Button
        variant="text"
        color={color}
        className="flex-c-2"
        onClick={() => {
          const next = current + 1
          onChange(Math.min(next, total))
        }}
        disabled={current === total}
      >
        Next
        <Sym>arrow_right_alt</Sym>
      </Button>
    </div>
  )
}
