import { Sym } from '@edclass/fe-ui'
import {
  Button,
  Progress,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from '@material-tailwind/react'
import clsx from 'clsx'
import { useCallback, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { AdminImportPageContext } from '@/pages/AdminPage/AdminImportPage/AdminImportPageContext.tsx'
import { useAdminImportPage } from '@/pages/AdminPage/AdminImportPage/hooks.ts'
import { AdminImportPageTab } from '@/pages/AdminPage/AdminImportPage/shared.ts'
import { getApiService } from '@/services/api.ts'

function ImportInput() {
  const { tab } = useAdminImportPage()
  const [file, setFile] = useState<File | null>(null)
  const [uploading, setUploading] = useState<null | number>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    disabled: uploading !== null,
    onDrop,
    accept: {
      'application/json': ['*.json'],
    },
  })

  return (
    <div className="mt-8 h-[calc(100vh-180px)] cc px-32">
      <div className="w-full">
        <input
          className="sr-only"
          ref={inputRef}
          type="file"
          accept="application/json"
          onChange={(e) => {
            const file = e.currentTarget.files?.[0]
            if (file) {
              setFile(file)
            }
          }}
        />
        <div className="flex flex-col gap-8 w-full">
          <div
            {...getRootProps({
              className:
                'w-full border border-dashed border-white/60 min-h-[400px] rounded-md cc text-center',
            })}
          >
            <div>
              <input {...getInputProps()} />
              <div className="flex flex-col items-center justify-center gap-8 p-4">
                {file ? (
                  <>
                    <div className="rounded-full bg-blue-500/20 p-4 text-white">
                      <Sym
                        className={clsx(
                          'text-[48px]',
                          uploading !== null && 'animate-beat',
                        )}
                      >
                        cloud_upload
                      </Sym>
                    </div>
                    <div className="flex-c-2">
                      <div className="text-white flex-c-2 w-full">
                        <Sym>description</Sym>
                        <span>{file.name}</span>
                      </div>
                      <div className="font-mono text-blue-gray-500">
                        {(file.size / 1024 / 1024).toFixed(2)}MB
                      </div>
                      {uploading === null && (
                        <button
                          className="text-blue-gray-700 hover:text-red-500"
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            setFile(null)
                          }}
                        >
                          <Sym>cancel</Sym>
                        </button>
                      )}
                    </div>
                    {uploading !== null && (
                      <div className="ps-4 flex-c-2 h-[10px] w-[250px]">
                        <Progress color="blue" value={Math.floor(uploading)} />
                        <span className="flex-shrink-0 font-mono text-white">
                          {Math.floor(uploading)}%
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="rounded-full bg-blue-500/20 p-4 text-white">
                      <Sym className="!text-[48px]">cloud_upload</Sym>
                    </div>
                    <span className="text-white">
                      Drag and drop file here, or{' '}
                      <span className="text-blue-500">
                        click to select file
                      </span>
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <Button
            disabled={file === null || uploading !== null}
            loading={uploading !== null}
            className="justify-center"
            onClick={async () => {
              if (file) {
                const ctrl = new AbortController()
                const ep = (() => {
                  switch (tab) {
                    case AdminImportPageTab.Subject:
                      return 'subject'
                    case AdminImportPageTab.Lesson:
                      return 'lesson'
                    case AdminImportPageTab.MCQ:
                      return 'question'
                  }
                })()

                setUploading(0)
                try {
                  await getApiService().uploadImport(ep, file, {
                    signal: ctrl.signal,
                    onUploadProgress: async (e) => {
                      const percentCompleted = Math.round(
                        (e.loaded * 100) / (e.total || 1),
                      )
                      setUploading(percentCompleted)
                    },
                  })
                } finally {
                  setUploading(null)
                }
              }
            }}
          >
            Start Upload
          </Button>
        </div>
      </div>
    </div>
  )
}

function AdminImportIndexPageInner() {
  const { tab, setTab } = useAdminImportPage()
  return (
    <Tabs value={tab}>
      <TabsHeader>
        {Object.values(AdminImportPageTab).map((v) => {
          return (
            <Tab key={v} value={v} onClick={() => setTab(v)}>
              Import {v}
            </Tab>
          )
        })}
      </TabsHeader>
      <TabsBody>
        {Object.values(AdminImportPageTab).map((v) => {
          return (
            <TabPanel key={v} className="p-0" value={v}>
              <ImportInput />
            </TabPanel>
          )
        })}
      </TabsBody>
    </Tabs>
  )
}

export default function AdminImportIndexPage() {
  const [tab, setTab] = useState(AdminImportPageTab.Subject)
  return (
    <AdminImportPageContext.Provider
      value={{
        tab,
        setTab,
      }}
    >
      <AdminImportIndexPageInner />
    </AdminImportPageContext.Provider>
  )
}
