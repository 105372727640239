import { FloatingFocusManager, FloatingOverlay } from '@floating-ui/react'
import { SelectOptionProps, useTheme } from '@material-tailwind/react'
import type {
  animate,
  arrow,
  className,
  color,
  containerProps,
  disabled,
  dismiss,
  error,
  label,
  labelProps,
  lockScroll,
  menuProps,
  name,
  offset,
  selected,
  size,
  success,
  variant,
} from '@material-tailwind/react/types/components/select'
import { domAnimation, LazyMotion, m } from 'framer-motion'
import { HTMLDivElement } from 'happy-dom'
import {
  ComponentProps,
  ForwardedRef,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { fixClick } from '@/components/MultiSelect/hooks.ts'
import {
  SelectWithSearchContextProvider,
  SelectWithSearchContextValue,
} from '@/components/SelectWithSearch/SelectWithSearchContext.tsx'
import SelectWithSearchOption from '@/components/SelectWithSearch/SelectWithSearchOption.tsx'
import { defaultSearch } from '@/helpers/search.ts'
import useDropdownTheme from '@/hooks/useDropdownTheme.tsx'

export interface SelectWithSearchProps<T>
  extends Omit<ComponentProps<'div'>, 'value' | 'onChange'> {
  variant?: variant
  color?: color
  size?: size | 'xl'
  label?: label
  error?: error
  success?: success
  arrow?: arrow
  value?: T
  onChange: (value: T) => void
  selected?: selected
  offset?: offset
  dismiss?: dismiss
  animate?: animate
  lockScroll?: lockScroll
  labelProps?: labelProps
  menuProps?: menuProps
  className?: className
  disabled?: disabled
  name?: name
  containerProps?: containerProps
  options: T[]
  renderOption?: (opt: T) => ReactNode
  onSearch?: (opt: T, keywords: string) => boolean
  noSearch?: boolean
  menuItemProps?: Omit<SelectOptionProps, 'children' | 'ref'>
  required?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SelectWithSearch = forwardRef<HTMLDivElement, SelectWithSearchProps<any>>(
  function SelectWithSearch<T>(
    {
      variant,
      color: baseColor,
      size,
      label,
      error,
      success,
      arrow,
      value,
      onChange,
      selected,
      offset,
      dismiss,
      animate,
      lockScroll,
      labelProps,
      menuProps,
      className,
      disabled,
      name,
      containerProps,
      options,
      onSearch,
      renderOption,
      noSearch = false,
      menuItemProps,
      required,
      ...rest
    }: SelectWithSearchProps<T>,
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    const { select } = useTheme()
    const { defaultProps } = select

    value = value ?? defaultProps.value ?? undefined
    onChange = onChange ?? defaultProps.onChange
    selected = selected ?? defaultProps.selected
    offset = offset ?? defaultProps.offset

    const listItemsRef = useRef<Array<HTMLLIElement | null>>([])
    const inputRef = useRef<HTMLInputElement>(null)
    const [typeAhead, setTypeAhead] = useState('')

    /*
    const selectedOption = useMemo(() => {
      return options.find((o) => o.value === value)
    }, [options, value])*/

    // 1. init
    const {
      open,
      setOpen,
      arrowEl,
      labelEl,
      context,
      NewAnimatePresence,
      state,
      setState,
      refProps,
      contextValue,
      floatingProps,
      appliedAnimation,
      containerClasses,
    } = useDropdownTheme<SelectWithSearchContextValue<T>>(
      'selectWithSearch',
      {
        variant,
        color: baseColor,
        size,
        label,
        arrow,
        success,
        error,
        dismiss,
        animate,
        labelProps,
        menuProps,
        className,
        disabled,
        name,
        containerProps,
        offset,
        required,
      },
      rest,
      {
        listRef: listItemsRef,
        inputRef,
        typeAhead,
        setTypeAhead,
        value,
        options,
        onChange: onChange || (() => {}),
      },
    )

    useEffect(() => {
      if (open) {
        setState('open')
      } else if (
        (!open && typeof value !== 'undefined' && value !== null) ||
        (!open && typeof value === 'string' && value.length > 0) ||
        (!open && value)
      ) {
        setState('withValue')
      } else {
        setState('close')
      }
    }, [open, typeAhead, value, selected, setState])

    useEffect(() => {
      if (state === 'open') {
        inputRef.current?.focus()
      }
    }, [state, inputRef])

    const onSearchCb = useCallback(
      (opt: T, keywords: string) => {
        return onSearch
          ? onSearch(opt, keywords)
          : defaultSearch(opt as Record<string, string>, keywords)
      },
      [onSearch],
    )

    const selectMenuItem = options.map((opt, i) => {
      const trimmed = typeAhead.toLowerCase().trim()
      if (trimmed.length > 0) {
        if (value !== opt && onSearchCb(opt, trimmed)) {
          return (
            <SelectWithSearchOption
              {...menuItemProps}
              key={`select-${i}`}
              index={i + 1}
              value={opt}
            >
              {renderOption
                ? renderOption(opt)
                : ((opt as { label: string })?.label ??
                  (opt as { value: string })?.value ??
                  '')}
            </SelectWithSearchOption>
          )
        } else {
          return null
        }
      } else {
        return (
          <SelectWithSearchOption
            {...menuItemProps}
            key={`select-${i}`}
            index={i + 1}
            value={opt}
          >
            {renderOption ? renderOption(opt) : `${opt}`}
          </SelectWithSearchOption>
        )
      }
    })

    // 8. select menu
    const selectMenu = (
      <FloatingFocusManager context={context} modal={false}>
        <m.ul
          {...floatingProps}
          initial="unmount"
          exit="unmount"
          animate={open ? 'mount' : 'unmount'}
          variants={appliedAnimation}
        >
          {!noSearch && (
            <li>
              <div data-value={typeAhead} className="w-full">
                <input
                  value={typeAhead}
                  ref={inputRef}
                  type="text"
                  className="bg-transparent rounded-sm py-1 px-2 border border-gray-200 min-w-0 w-full text-main"
                  onChange={(e) => {
                    if (!open) {
                      setOpen(true)
                    }
                    setTypeAhead(e.target.value)
                  }}
                />
              </div>
            </li>
          )}
          {typeAhead.trim().length > 0 ? (
            selectMenuItem?.length === 0 ? (
              <SelectWithSearchOption {...menuItemProps} disabled>
                No Option Match
              </SelectWithSearchOption>
            ) : (
              selectMenuItem
            )
          ) : (
            selectMenuItem
          )}
        </m.ul>
      </FloatingFocusManager>
    )

    fixClick(refProps, inputRef)

    /*
    peer w-full h-full min-h-11 bg-transparent
    font-sans font-normal text-left outline outline-0 focus:outline-0
    disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed
    transition-all border text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200
    border-t-transparent text-white

    peer w-full h-full min-h-11 bg-transparent
    font-sans font-normal text-left outline outline-0 focus:outline-0
    disabled:bg-blue-gray-50 disabled:border-0 disabled:cursor-not-allowed
    transition-all border text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 text-white
     */

    // 9. return
    return (
      <SelectWithSearchContextProvider value={contextValue}>
        <div
          {...containerProps}
          ref={ref as never}
          className={containerClasses}
        >
          <div {...refProps}>
            {value !== undefined && value !== null
              ? renderOption
                ? renderOption(value)
                : `${value}`
              : ''}
            {arrowEl}
          </div>
          {labelEl}
          <LazyMotion features={domAnimation}>
            <NewAnimatePresence>
              {open && (
                <>
                  {lockScroll ? (
                    <FloatingOverlay lockScroll>{selectMenu}</FloatingOverlay>
                  ) : (
                    selectMenu
                  )}
                </>
              )}
            </NewAnimatePresence>
          </LazyMotion>
        </div>
      </SelectWithSearchContextProvider>
    )
  },
)

SelectWithSearch.displayName = 'SelectWithSearch'

export default SelectWithSearch
