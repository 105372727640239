import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import useParamsWithPrev from '@/hooks/useParamsWithPrev.ts'
import useSearch from '@/hooks/useSearch.ts'

export function useSearchAndParams(initialParam?: ReqParams): {
  params: ReqParams
  setParams: SetState<ReqParams>
  previousParams?: ReqParams
  areParamsEqual: () => boolean
} {
  const { params, setParams, ...restParams } = useParamsWithPrev(initialParam)
  const { debouncedSearch, debounced, setSearch } = useSearch()
  const [, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (debouncedSearch && params.search !== debouncedSearch) {
      setParams((prev) => {
        return {
          ...prev,
          page: 1,
          search: debouncedSearch,
        }
      })
      setSearchParams((prev) => {
        return {
          ...prev,
          page: 1,
          search: debouncedSearch,
        }
      })
    } else {
      if (params.search && params.search !== debouncedSearch) {
        const copy = { ...params, page: 1 }
        delete copy.search
        setParams(copy)
        setSearchParams((prev) => {
          prev.delete('search')
          return new URLSearchParams(prev)
        })
      }
    }
  }, [debouncedSearch, setSearchParams, params, setParams])

  useEffect(() => {
    const cb = (e: PopStateEvent) => {
      const tgt = e.target as Window
      const sp = new URLSearchParams(tgt.location.search)
      const s = sp.get('search')
      const entries = sp.entries()
      const p: ReqParams = {}
      for (const [k, v] of entries) {
        if (k === 'search') {
          continue
        }
        p[k] = v
      }
      setParams(p)
      if (s) {
        debounced(s)
        setSearch(s)
      } else {
        debounced('')
        setSearch('')
      }
      debounced.flush()
    }
    window.addEventListener('popstate', cb)
    return () => {
      window.removeEventListener('popstate', cb)
    }
  }, [debounced, setParams, setSearch])

  return {
    params,
    setParams,
    ...restParams,
  }
}
