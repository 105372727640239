export const BASIC_COLORS = [
  '#d0021b',
  '#f5a623',
  '#f8e71c',
  '#8b572a',
  '#7ed321',
  '#417505',
  '#bd10e0',
  '#9013fe',
  '#4a90e2',
  '#50e3c2',
  '#b8e986',
  '#000000',
  '#4a4a4a',
  '#9b9b9b',
  '#ffffff',
]

export const ED_BG = '#12142F'
export const ED_DARK_BLUE = '#202442'
export const ED_TEXT = '#0C2444'
export const ED_BLUE = '#009FE3'
export const ED_LIGHT_BLUE = '#00E1FF'
export const ED_ORANGE = '#E15A24'
export const ED_YELLOW = '#ECDE21'
export const ED_PINK = '#F27FB2'
export const ED_LIME = '#80B63F'
export const ED_PURPLE = '#6850A1'
export const ED_RED = '#B1272D'
export const ED_RED2 = '#BE1E2D'
export const ED_BEIGE = '#C49A6C'
export const ED_GREEN = '#006837'
export const ED_BROWN = '#B7A289'
