import { useContext } from 'react'

import { AppContext } from '@/providers/AppProvider.tsx'

export default function useAppContext() {
  const ctx = useContext(AppContext)
  if (ctx === null) {
    throw Error('must use within AppContext')
  }
  return ctx
}
