import { AdminChildrenPath } from '@/constants/constants.ts'
import AdminAlertsPage from '@/pages/AdminPage/AdminAlertsPage'
import AdminAlertsIndexPage from '@/pages/AdminPage/AdminAlertsPage/AdminAlertsIndexPage'

export default {
  path: AdminChildrenPath.ALERTS,
  element: <AdminAlertsPage />,
  children: [
    {
      index: true,
      element: <AdminAlertsIndexPage />,
    },
  ],
}
