// eslint-disable-next-line react-refresh/only-export-components
import { lazy } from 'react'

import { AdminChildrenPath, AdminCrudPath } from '@/constants/constants.ts'
import keySkillRoute from '@/pages/AdminPage/AdminSkillsPage/AdminKeySkillsPage/route'
import skillTagRoute from '@/pages/AdminPage/AdminSkillsPage/AdminSkillTagsPage/route'
import subjectSkillRoute from '@/pages/AdminPage/AdminSkillsPage/AdminSubjectSpecificSkillPage/route'

// eslint-disable-next-line react-refresh/only-export-components
const Page = lazy(() => import('@/pages/AdminPage/AdminSkillsPage/index.tsx'))

// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(
  () => import('@/pages/AdminPage/AdminSkillsPage/AdminSkillsIndexPage.tsx'),
)

// eslint-disable-next-line react-refresh/only-export-components
const Create = lazy(
  () => import('@/pages/AdminPage/AdminSkillsPage/AdminSkillsCreatePage.tsx'),
)

// eslint-disable-next-line react-refresh/only-export-components
const Edit = lazy(
  () => import('@/pages/AdminPage/AdminSkillsPage/AdminSkillsEditPage.tsx'),
)

// eslint-disable-next-line react-refresh/only-export-components
const Detail = lazy(
  () => import('@/pages/AdminPage/AdminSkillsPage/AdminSkillsDetailPage.tsx'),
)
// eslint-disable-next-line react-refresh/only-export-components
const Archive = lazy(
  () => import('@/pages/AdminPage/AdminSkillsPage/AdminSkillsArchivePage'),
)
export default {
  path: AdminChildrenPath.SKILL,
  element: <Page />,
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: AdminCrudPath.CREATE,
      element: <Create />,
    },
    {
      path: `:skillId/${AdminCrudPath.EDIT}`,
      element: <Edit />,
    },
    {
      path: `:skillId/${AdminCrudPath.DETAIL}`,
      element: <Detail />,
    },
    {
      path: `archives`,
      element: <Archive />,
    },
    skillTagRoute,
    keySkillRoute,
    subjectSkillRoute,
  ],
}
