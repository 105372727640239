import { useMemo } from 'react'

export default function useIsQueryLoading(
  {
    isPending,
    isPlaceholderData,
  }: {
    isPending: boolean
    isPlaceholderData: boolean
  },
  {
    areParamsEqual,
  }: {
    areParamsEqual: () => boolean
  },
) {
  return useMemo(() => {
    return isPending || (!areParamsEqual() && isPlaceholderData)
  }, [areParamsEqual, isPending, isPlaceholderData])
}
