import { ReactNode, useCallback, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'

import useFiles from '@/hooks/query/useFiles.ts'
import useSearch from '@/hooks/useSearch.ts'
import { FileStorageContext } from '@/providers/FileStorageProvider/context.tsx'
import {
  FILE_STORAGE_VIEW_KEY,
  FileStorageView,
} from '@/providers/FileStorageProvider/types.ts'
import SearchProvider from '@/providers/SearchProvider.tsx'

export function InnerProvider({ children }: { children: ReactNode }) {
  const [checked, setChecked] = useState<FsItem[]>([])
  const filesContext = useFiles()

  const [view, setView] = useLocalStorageState<FileStorageView>(
    FILE_STORAGE_VIEW_KEY,
    {
      defaultValue: FileStorageView.Grid,
    },
  )

  const handleCheckAdd = useCallback(
    (file: FsItem) => {
      const copy = [...checked]
      copy.push(file)
      setChecked(copy)
    },
    [checked],
  )
  const handleCheckRemove = useCallback(
    (file: FsItem) => {
      setChecked(checked.filter((f) => f.id !== file.id))
    },
    [checked],
  )
  const handleCheckToggle = useCallback(
    (file: FsItem | boolean) => {
      if (typeof file === 'boolean') {
        if (file) {
          setChecked((filesContext.data?.data as unknown as FsItem[]) || [])
        } else {
          setChecked([])
        }
      } else {
        const isChecked = checked.findIndex((c) => c.id === file.id) !== -1
        if (isChecked) {
          handleCheckRemove(file)
        } else {
          handleCheckAdd(file)
        }
      }
    },
    [checked, filesContext.data?.data, handleCheckAdd, handleCheckRemove],
  )

  const searchContext = useSearch()

  return (
    <FileStorageContext.Provider
      value={{
        checked,
        setChecked,
        handleCheckAdd,
        handleCheckRemove,
        handleCheckToggle,
        ///
        view,
        setView,
        ...filesContext,
        ...searchContext,
      }}
    >
      {children}
    </FileStorageContext.Provider>
  )
}

export default function FileStorageProvider({
  children,
}: {
  children: ReactNode
}) {
  return (
    <SearchProvider>
      <InnerProvider>{children}</InnerProvider>
    </SearchProvider>
  )
}
