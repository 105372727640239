import { Sym } from '@edclass/fe-ui'
import { NavigateOptions, To, useLocation } from 'react-router-dom'

import useLoggedWsNavigate from '@/hooks/useLoggedWsNavigate.ts'

export default function BackButton({
  onBack,
  navOptions,
}: {
  onBack: To | number | (() => void)
  navOptions?: NavigateOptions
}) {
  const navigate = useLoggedWsNavigate()
  const location = useLocation()

  return (
    <button
      className="cc"
      onClick={() => {
        if (typeof onBack === 'function') {
          onBack()
        } else if (onBack === 'parent') {
          const split = location.pathname.split('/')
          const endWithSlash = split[split.length - 1] === ''
          navigate(split.slice(0, endWithSlash ? -2 : -1).join('/'), navOptions)
        } else {
          navigate(onBack as To, navOptions)
        }
      }}
    >
      <Sym>arrow_back</Sym>
    </button>
  )
}
