import { useEffect } from 'react'

export default function useFormEnter(
  formId: string,
  action: (e: KeyboardEvent) => void,
) {
  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      const target = e.target as HTMLDivElement
      const form = target.closest(`#${formId}`)
      if (e.key === 'Enter' && form) {
        e.preventDefault()
        action(e)
      }
    }

    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [action, formId])
}
