// eslint-disable-next-line react-refresh/only-export-components
import { lazy } from 'react'

import { AdminCrudPath, AdminSkillChildrenPath } from '@/constants/constants.ts'

// eslint-disable-next-line react-refresh/only-export-components
const Page = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSkillsPage/AdminSubjectSpecificSkillPage/index.tsx'
    ),
)

// eslint-disable-next-line react-refresh/only-export-components
const Index = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSkillsPage/AdminSubjectSpecificSkillPage/IndexPage.tsx'
    ),
)

// eslint-disable-next-line react-refresh/only-export-components
const Create = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSkillsPage/AdminSubjectSpecificSkillPage/CreatePage.tsx'
    ),
)

// eslint-disable-next-line react-refresh/only-export-components
const Edit = lazy(
  () =>
    import(
      '@/pages/AdminPage/AdminSkillsPage/AdminSubjectSpecificSkillPage/EditPage.tsx'
    ),
)

export default {
  path: AdminSkillChildrenPath.SUBJECT_SPECIFIC_SKILL,
  element: <Page />,
  children: [
    {
      index: true,
      element: <Index />,
    },
    {
      path: AdminCrudPath.CREATE,
      element: <Create />,
    },
    {
      path: `:subjectSkillId/${AdminCrudPath.EDIT}`,
      element: <Edit />,
    },
  ],
}
