import './MultiSelect.css'

import { Sym } from '@edclass/fe-ui'
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
} from '@floating-ui/react'
import { Chip, useTheme } from '@material-tailwind/react'
import type {
  animate,
  arrow,
  className,
  color,
  containerProps,
  disabled,
  dismiss,
  error,
  label,
  labelProps,
  lockScroll,
  menuProps,
  name,
  offset,
  selected,
  size,
  success,
  variant,
} from '@material-tailwind/react/types/components/select'
import { domAnimation, LazyMotion, m } from 'framer-motion'
import {
  ComponentProps,
  forwardRef,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { fixClick } from '@/components/MultiSelect/hooks.ts'
import {
  MultiSelectContextProvider,
  MultiSelectContextValue,
} from '@/components/MultiSelect/MultiSelectContext'
import MultiSelectOption, {
  MultiSelectOptionProps,
} from '@/components/MultiSelect/MultiSelectOption.tsx'
import { defaultSearch } from '@/helpers/search.ts'
import useDropdownTheme from '@/hooks/useDropdownTheme.tsx'

export interface MultiSelectProps<T>
  extends Omit<ComponentProps<'div'>, 'value' | 'onChange'> {
  variant?: variant
  color?: color
  size?: size
  label?: label
  error?: error
  success?: success
  arrow?: arrow
  value?: T[]
  onChange: (value: T[]) => void
  selected?: selected
  offset?: offset
  dismiss?: dismiss
  animate?: animate
  lockScroll?: lockScroll
  labelProps?: labelProps
  menuProps?: menuProps
  className?: className
  disabled?: disabled
  name?: name
  containerProps?: containerProps
  options: T[]
  onSearch?: (opt: T, keywords: string) => boolean
  renderOption?: (opt: T) => ReactNode
  renderSelectedOption?: (opt: T, onClose: () => void) => ReactNode
  menuItemProps?: Omit<MultiSelectOptionProps<T>, 'children' | 'ref'>
  overlayClassName?: string
  required?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MultiSelect = forwardRef<HTMLDivElement, MultiSelectProps<any>>(
  function MultiSelect<T>(
    {
      variant,
      color: baseColor,
      size,
      label,
      error,
      success,
      arrow,
      value = [],
      onChange,
      selected,
      offset,
      dismiss,
      animate,
      lockScroll,
      labelProps,
      menuProps,
      className,
      disabled,
      name,
      containerProps,
      options,
      renderOption,
      onSearch,
      menuItemProps,
      renderSelectedOption,
      overlayClassName,
      required,
      ...rest
    }: MultiSelectProps<T>,
    ref: Ref<HTMLDivElement>,
  ) {
    const { multiSelect } = useTheme()
    const { defaultProps } = multiSelect

    value = value ?? defaultProps.value ?? []
    onChange = onChange ?? defaultProps.onChange
    selected = selected ?? defaultProps.selected
    offset = offset ?? defaultProps.offset

    const listItemsRef = useRef<Array<HTMLLIElement | null>>([])
    const inputRef = useRef<HTMLInputElement>(null)
    const [typeAhead, setTypeAhead] = useState('')

    // 1. init
    const {
      open,
      setOpen,
      arrowEl,
      labelEl,
      context,
      NewAnimatePresence,
      state,
      setState,
      color,
      refProps,
      removeClasses,
      contextValue,
      floatingProps,
      appliedAnimation,
      containerClasses,
    } = useDropdownTheme<MultiSelectContextValue<T>>(
      'multiSelect',
      {
        variant,
        color: baseColor,
        size,
        label,
        arrow,
        success,
        error,
        dismiss,
        animate,
        labelProps,
        menuProps,
        className,
        disabled,
        name,
        containerProps,
        offset,
        required,
      },
      rest,
      {
        listRef: listItemsRef,
        inputRef,
        typeAhead,
        setTypeAhead,
        value,
        options,
        onChange: onChange || (() => {}),
      },
    )

    useEffect(() => {
      if (open) {
        setState('open')
      } else if ((!open && value && value.length > 0) || (!open && value)) {
        setState('withValue')
      } else {
        setState('close')
      }
    }, [open, typeAhead, value, selected, setState])

    useEffect(() => {
      if (state === 'open') {
        inputRef.current?.focus()
      }
    }, [state, inputRef])

    const onSearchCb = useCallback(
      (opt: T, keywords: string) => {
        return onSearch
          ? onSearch(opt, keywords)
          : defaultSearch(opt as Record<string, string>, keywords)
      },
      [onSearch],
    )

    const selectMenuItem = options.map((opt, i) => {
      const trimmed = typeAhead.toLowerCase().trim()
      if (trimmed.length > 0) {
        if (onSearchCb(opt, trimmed)) {
          return (
            <MultiSelectOption
              {...menuItemProps}
              key={`select-${i}`}
              index={i + 1}
              value={opt}
            >
              {renderOption
                ? renderOption(opt)
                : ((opt as { label: string })?.label ??
                  (opt as { value: string })?.value ??
                  '')}
            </MultiSelectOption>
          )
        } else {
          return null
        }
      } else {
        return (
          <MultiSelectOption
            {...menuItemProps}
            key={`select-${i}`}
            index={i + 1}
            value={opt}
          >
            {renderOption ? renderOption(opt) : `${opt}`}
          </MultiSelectOption>
        )
      }
    })

    // 8. select menu
    const selectMenu = (
      <FloatingFocusManager context={context} modal={false}>
        <m.ul
          {...floatingProps}
          initial="unmount"
          exit="unmount"
          animate={open ? 'mount' : 'unmount'}
          variants={appliedAnimation}
        >
          {typeAhead.trim().length > 0 ? (
            selectMenuItem?.length === 0 ? (
              <MultiSelectOption value={null} disabled>
                No Option Match
              </MultiSelectOption>
            ) : (
              selectMenuItem
            )
          ) : (
            selectMenuItem
          )}
        </m.ul>
      </FloatingFocusManager>
    )

    fixClick(refProps, inputRef)

    // 9. return
    return (
      <MultiSelectContextProvider value={contextValue}>
        <div {...containerProps} ref={ref} className={containerClasses}>
          <div {...refProps}>
            <div className="flex-c-2 flex-1 flex-wrap me-12 overflow-hidden">
              {(value || []).map((i, idx) => {
                const onClose = () => {
                  const copy = [...(value || [])]
                  copy.splice(idx, 1)
                  onChange(copy)
                }
                return renderSelectedOption ? (
                  renderSelectedOption(i, onClose)
                ) : (
                  <Chip
                    key={idx}
                    size="sm"
                    color={color}
                    className="!py-0.5 px-1"
                    value={renderOption ? renderOption(i) : `${i}`}
                    onClose={onClose}
                  />
                )
              })}
              <div data-value={typeAhead} className="container-style">
                <input
                  value={typeAhead}
                  ref={inputRef}
                  type="text"
                  className="bg-[0px center] text-main"
                  onChange={(e) => {
                    if (!open) {
                      setOpen(true)
                    }
                    setTypeAhead(e.target.value)
                  }}
                  style={{
                    background: '0px center',
                    opacity: 1,
                    width: '100%',
                    gridArea: '1 / 2',
                    minWidth: '2px',
                    border: '0',
                    margin: '0',
                    outline: '0',
                    padding: '0',
                  }}
                />
              </div>
            </div>
            {value && value.length > 0 && (
              <button
                title="Clear"
                className={removeClasses}
                onClick={() => {
                  //setSelectedIndex([])
                  setTypeAhead('')
                  onChange?.([])
                }}
              >
                <Sym className="!text-[20px]">cancel</Sym>
              </button>
            )}
            {arrowEl}
          </div>
          {labelEl}
          <FloatingPortal root={document.getElementById('portal')}>
            <LazyMotion features={domAnimation}>
              <NewAnimatePresence>
                {open && (
                  <>
                    {lockScroll ? (
                      <FloatingOverlay className={overlayClassName} lockScroll>
                        {selectMenu}
                      </FloatingOverlay>
                    ) : (
                      selectMenu
                    )}
                  </>
                )}
              </NewAnimatePresence>
            </LazyMotion>
          </FloatingPortal>
        </div>
      </MultiSelectContextProvider>
    )
  },
)

export default MultiSelect
