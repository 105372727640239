import { QueryClient } from '@tanstack/react-query'

export function addQueryData<T>(
  res: T,
  queryClient: QueryClient,
  queryKey: unknown[],
) {
  queryClient.setQueryData(queryKey, (old: PaginatedItems<T>) => {
    const next = [res, ...(old?.data ?? [])]
    if (old?.page?.perPage && next.length > old?.page?.perPage) {
      next.pop()
    }
    return {
      ...old,
      data: next,
    }
  })
  queryClient.invalidateQueries({
    queryKey: queryKey,
    exact: false,
  })
}

export function updateQueryData<T extends WithId>(
  res: T,
  queryClient: QueryClient,
  queryKey: unknown[],
) {
  queryClient.setQueryData(queryKey, (old: PaginatedItems<T>) => {
    const copy = [...(old?.data ?? [])]
    const idx = copy.findIndex((c) => c.id === res.id)
    copy[idx] = res
    return {
      ...old,
      data: copy,
    }
  })
  queryClient.invalidateQueries({
    queryKey: queryKey,
    exact: false,
  })
}

export function removeQueryData<T extends WithId>(
  res: T,
  queryClient: QueryClient,
  queryKey: unknown[],
) {
  queryClient.setQueryData(queryKey, (old: PaginatedItems<T>) => {
    const copy = [...(old?.data ?? [])].filter((c) => c.id !== res.id)
    return {
      ...old,
      data: copy,
    }
  })
  queryClient.invalidateQueries({
    queryKey: queryKey,
    exact: false,
  })
}

export function updateQueryDataPartial<T extends WithId>(
  res: Partial<T>,
  queryClient: QueryClient,
  queryKey: unknown[],
) {
  queryClient.setQueryData(queryKey, (old: PaginatedItems<T>) => {
    const copy = [...(old?.data ?? [])]
    const idx = copy.findIndex((c) => c.id === res.id)
    copy[idx] = {
      ...copy[idx],
      ...res,
    }
    return {
      ...old,
      data: copy,
    }
  })
  queryClient.invalidateQueries({
    queryKey: queryKey,
    exact: false,
  })
}
