import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
} from 'react'

export interface WithUniqueId {
  id: string
}

export type TimetableSheetValue<T extends WithUniqueId> = Record<string, T[]>
export interface TimetableSheetContextValue<T extends WithUniqueId> {
  id: string
  onChange: Dispatch<SetStateAction<TimetableSheetValue<T>>>
  items: TimetableSheetValue<T>
  containers: (keyof TimetableSheetValue<T>)[]
  setContainers: Dispatch<SetStateAction<(keyof TimetableSheetValue<T>)[]>>
  activeId: string | null
  setActiveId: Dispatch<SetStateAction<string | null>>
  lastOverId: MutableRefObject<string | null>
  recentlyMovedToNewContainer: MutableRefObject<boolean>
  isSortingContainer: boolean
  clonedItems: TimetableSheetValue<T> | null
  setClonedItems: Dispatch<SetStateAction<TimetableSheetValue<T> | null>>
  getIndex: (id: string) => number
  findContainer: (id: string) => string | undefined
  renderItem: (item: T, index: number) => ReactNode
  renderActions?: (item: T, index: number) => ReactNode
  isDisabled?: (item: T, index: number) => boolean
  itemClassName?: string | ((item: T) => string)
}

export const TimetableSheetContext = createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TimetableSheetContextValue<any>
>({
  id: '',
  onChange: () => null,
  items: {},
  containers: [],
  setContainers: () => null,
  activeId: null,
  setActiveId: () => null,
  lastOverId: {
    current: null,
  },
  recentlyMovedToNewContainer: {
    current: false,
  },
  isSortingContainer: false,
  clonedItems: null,
  setClonedItems: () => null,
  getIndex: () => 0,
  findContainer: () => undefined,
  renderItem: () => {
    return undefined as unknown as ReactElement
  },
  renderActions: undefined,
  itemClassName: undefined,
  isDisabled: () => false,
})

export function useTimetableSheetContext<
  T extends WithUniqueId,
>(): TimetableSheetContextValue<T> {
  return useContext(TimetableSheetContext)
}
