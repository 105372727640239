import './Header.css'

import HeaderMenu from '@/components/Header/HeaderMenu.tsx'
import useAppContext from '@/hooks/useAppProvider.ts'

export default function Header() {
  const { headerTitle, headerAction } = useAppContext()
  return (
    <div id="header">
      <div id="header-title">
        <headerTitle.Out />
      </div>
      <div id="header-actions">
        <headerAction.Out />
      </div>
      <HeaderMenu />
    </div>
  )
}
