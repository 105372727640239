import type { ContextData } from '@floating-ui/react'
import type { children } from '@material-tailwind/react/types/components/select'
import {
  createContext,
  Dispatch,
  HTMLProps,
  MutableRefObject,
  RefObject,
  SetStateAction,
} from 'react'

export type MultiSelectContextValue<T> = {
  value: T[]
  listRef: MutableRefObject<Array<HTMLLIElement | null>>
  options: T[]
  inputRef: RefObject<HTMLInputElement>
  setOpen: (open: boolean) => void
  onChange: (value: T[]) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItemProps: (userProps?: HTMLProps<HTMLElement>) => any
  dataRef: ContextData
  typeAhead: string
  setTypeAhead: Dispatch<SetStateAction<string>>
}

export interface MultiSelectContextProviderProps<T> {
  value: MultiSelectContextValue<T>
  children: children
}

export const MultiSelectContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<MultiSelectContextValue<any> | null>(null)

export function MultiSelectContextProvider<T>({
  value,
  children,
}: MultiSelectContextProviderProps<T>) {
  return (
    <MultiSelectContext.Provider value={value}>
      {children}
    </MultiSelectContext.Provider>
  )
}
